import { PageTitle } from 'common/components/page-title/PageTitle';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetWorkspaceModule from 'features/user/mapping/hooks/useGetWorkspaceModule';
import { Button, Card, Col, Row, Stack } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';

export const ReportTypes = () => {
  const { workspaceModuleId } = useParams();

  const [isLoading, , data] = useGetWorkspaceModule(workspaceModuleId ? workspaceModuleId : '');

  const navigate = useNavigate();
  return (
    <>
      {isLoading && <LoadingSpinner isLoading={isLoading} />}
      {data && (
        <>
          <PageTitle title={data.systemName} backTo="/report" />
          <br></br>
          <h4>Mine rapporttyper</h4>
          <Card className="mb-3">
            <Row className="py-4 px-3">
              <Col xs={8} className="d-flex flex-column">
                <h4>Kartlagt rådata</h4>
                <span>Se rådata av kartlagt data</span>
              </Col>
              <Col className="d-flex align-items-center justify-content-end">
                <Button
                  variant="secondary"
                  onClick={() => navigate(`/report/${workspaceModuleId}/report-types/mapping-data`)}
                >
                  Lag rapport
                </Button>
              </Col>
            </Row>
          </Card>
          <Card className="mb-3">
            <Row className="py-4 px-3">
              <Col xs={8} className="d-flex flex-column">
                <h4>Resultater på bygg-nivå</h4>
                <span>Se kartlegging i tabellformat</span>
              </Col>
              <Col className="d-flex align-items-center justify-content-end">
                <Button
                  variant="secondary"
                  onClick={() => navigate(`/report/${workspaceModuleId}/report-types/table-report`)}
                >
                  Lag rapport
                </Button>
              </Col>
            </Row>
          </Card>
          <Card>
            <Row className="py-4 px-3">
              <Col xs={8} className="d-flex flex-column">
                <h4>Basisrapport</h4>
                <span>Se kartlegging i visualisert data</span>
              </Col>
              <Col className="d-flex align-items-center justify-content-end">
                <Button
                  variant="secondary"
                  onClick={() => navigate(`/report/${workspaceModuleId}/report-types/basis-report`)}
                >
                  Lag rapport
                </Button>
              </Col>
            </Row>
          </Card>
        </>
      )}
      <br></br>
    </>
  );
};
