import { SearchOutlined } from "@mui/icons-material";
import { reportsApi } from "common/api/MultimapClients";
import { ConditionDegreeIcon } from "common/components/ConditionDegreeIcon/ConditionDegreeIcon";
import { PageTitle } from "common/components/page-title/PageTitle";
import { TableContainer } from "common/components/TableContainer/TableContainer";
import { LoadingSpinner } from "features/admin/components/loading-spinner/LoadingSpinner";
import useGetLastClosedMappingPeriod from "features/user/maintenance-plan/hooks/useGetLastClosedPeriod";
import { useState } from "react";
import { Button, Col, Form, InputGroup, Row, Stack, Table } from "react-bootstrap";
import { useParams } from "react-router";
import { downloadFile } from 'common/helpers/http-header.helper';
import { notify } from 'common/helpers/toast-notification-helper';

export const TableReport = () => {
  const { workspaceModuleId } = useParams();

  const [isLoading, , data] = useGetLastClosedMappingPeriod(workspaceModuleId ?? '');

  const downloadTableReportExport = async () => {
    try {
      const response = await reportsApi.reportsWorkspaceModuleIdTableReportExportGet(workspaceModuleId ?? '', {
        responseType: 'blob',
      });

      downloadFile(response);

      notify('success', 'Eksport fil lagret');
    } catch (e) {
      console.log(e);
    }
  };

  const [filter, setFilter] = useState<string>('');

  return (<>
    {data ? (
      <>
        <PageTitle
          title={`Resultater på ${data.objectTypeName.toLowerCase()}-nivå`}
          backTo={`/report/${workspaceModuleId}/report-types`}
        ></PageTitle>
        {data.mappingObjects && data.mappingObjects.length > 0 ? (
          <TableContainer>
            <Stack direction="horizontal" className="table-filter">
              <InputGroup>
                <InputGroup.Text>
                  <SearchOutlined fontSize="small" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  id="filter"
                  placeholder={'Søk'}
                  aria-describedby={'Søk'}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </InputGroup>
              <Button variant="tertiary" className="ms-2" onClick={() => downloadTableReportExport()}>Eksporter</Button>
            </Stack>
            <Table hover responsive>
              <thead>
                <tr>
                  <th scope="col">Objekt</th>
                  <th scope="col">Samlet</th>
                  <th scope="col">Areal</th>
                  <th scope="col">År</th>
                  {data.mappingObjects[0].valueGroups &&
                    data.mappingObjects[0].valueGroups.map((row, i) => {
                      return (
                        <>
                          <th scope="col">{row.mappingGroupName}</th>
                        </>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {data.mappingObjects
                  .filter((x) => x.objectName.toLowerCase().includes(filter.toLowerCase()))
                  .map((row, i) => {
                    return (
                      <tr>
                        <td>
                          <Stack direction="vertical">
                            <span>{row.objectName}</span>
                            <small>{row.objectParentName}</small>
                          </Stack>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <ConditionDegreeIcon conditionDegree={row.averageConditionDegree} />
                            <span className="ms-2">{(row.averageConditionDegree != null) ? row.averageConditionDegree.toFixed(1) : "N/A"}</span>
                          </div>
                        </td>
                        <td>{row.areal}</td>
                        <td>{row.year}</td>
                        {row.valueGroups &&
                          row.valueGroups.map((group, index) => {
                            return (
                              <>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <ConditionDegreeIcon conditionDegree={group.averageConditionDegree} />
                                    <span className="ms-2">{(group.averageConditionDegree != null) ? group.averageConditionDegree.toFixed(1) : "N/A"}</span>
                                  </div>
                                </td>
                              </>
                            );
                          })}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </TableContainer>
        ) : (
          <>
            <Row className="g-0 rounded pt-3 mb-5" style={{ border: '1px dashed #ABBED1' }}>
              <>
                <Col xs={8} className="p-3">
                  <h4>Ingen av objektene er kartlagt på denne perioden</h4>
                </Col>
              </>
            </Row>
          </>
        )}
      </>
    ) : (
      <>
        <PageTitle
          title={`Tabellrapport`}
          backTo={`/report/${workspaceModuleId}/report-types`}
        ></PageTitle>

        <LoadingSpinner isLoading={isLoading} />
        <Row className="g-0 rounded pt-3 mb-5" style={{ border: '1px dashed #ABBED1' }}>
          <>
            <Col xs={8} className="p-3">
              <h4>Du har ingen avsluttede kartlegginger</h4>
            </Col>
          </>
        </Row>
      </>
    )}
  </>)
}