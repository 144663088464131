/* tslint:disable */
/* eslint-disable */
/**
 * Multimap.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AccessControl = {
    AdminDashboardView: 'AdminDashboard_View',
    CustomerAccessRequestListRead: 'CustomerAccessRequestList_Read',
    CustomerAccessRequestRead: 'CustomerAccessRequest_Read',
    CustomerAccessRequestEdit: 'CustomerAccessRequest_Edit',
    OrganizationListRead: 'OrganizationList_Read',
    OrganizationRead: 'Organization_Read',
    OrganizationUserListRead: 'OrganizationUserList_Read',
    OrganizationInvitationListRead: 'OrganizationInvitationList_Read',
    UserListRead: 'UserList_Read',
    UserRead: 'User_Read',
    UserEdit: 'User_Edit'
} as const;

export type AccessControl = typeof AccessControl[keyof typeof AccessControl];


/**
 * 
 * @export
 * @interface AddedOrRemovedObjectResponse
 */
export interface AddedOrRemovedObjectResponse {
    /**
     * 
     * @type {string}
     * @memberof AddedOrRemovedObjectResponse
     */
    'objectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddedOrRemovedObjectResponse
     */
    'systemName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddedOrRemovedObjectResponse
     */
    'removed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddedOrRemovedObjectResponse
     */
    'added'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddedOrRemovedObjectResponse
     */
    'objectTypeName'?: string | null;
}
/**
 * 
 * @export
 * @interface AvailableModuleResponse
 */
export interface AvailableModuleResponse {
    /**
     * 
     * @type {string}
     * @memberof AvailableModuleResponse
     */
    'moduleId': string;
    /**
     * 
     * @type {string}
     * @memberof AvailableModuleResponse
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof AvailableModuleResponse
     */
    'systemDescription': string;
    /**
     * 
     * @type {boolean}
     * @memberof AvailableModuleResponse
     */
    'activeBefore': boolean;
    /**
     * 
     * @type {string}
     * @memberof AvailableModuleResponse
     */
    'icon': string;
    /**
     * 
     * @type {string}
     * @memberof AvailableModuleResponse
     */
    'mappingSetId': string;
    /**
     * 
     * @type {number}
     * @memberof AvailableModuleResponse
     */
    'modulePrice': number;
}
/**
 * 
 * @export
 * @interface BasicInformationResponse
 */
export interface BasicInformationResponse {
    /**
     * 
     * @type {string}
     * @memberof BasicInformationResponse
     */
    'organizationNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformationResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformationResponse
     */
    'form'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformationResponse
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformationResponse
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformationResponse
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformationResponse
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformationResponse
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformationResponse
     */
    'postBoxAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformationResponse
     */
    'postBoxZipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformationResponse
     */
    'postBoxCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformationResponse
     */
    'postBoxCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInformationResponse
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface ColumnTitleResponse
 */
export interface ColumnTitleResponse {
    /**
     * 
     * @type {string}
     * @memberof ColumnTitleResponse
     */
    'subTitle': string;
    /**
     * 
     * @type {string}
     * @memberof ColumnTitleResponse
     */
    'mainTitle': string;
}
/**
 * 
 * @export
 * @interface CreateCustomerAccessRequest
 */
export interface CreateCustomerAccessRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAccessRequest
     */
    'organizationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAccessRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAccessRequest
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAccessRequest
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAccessRequest
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAccessRequest
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAccessRequest
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAccessRequest
     */
    'phone'?: string | null;
    /**
     * 
     * @type {OrganizationType}
     * @memberof CreateCustomerAccessRequest
     */
    'organizationType'?: OrganizationType;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAccessRequest
     */
    'creditScore'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAccessRequest
     */
    'creditScoreInterpretationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAccessRequest
     */
    'creditLimit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAccessRequest
     */
    'riskClass'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerAccessRequest
     */
    'riskClassDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateLearningRequest
 */
export interface CreateLearningRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateLearningRequest
     */
    'mappingSetId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLearningRequest
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateLearningRequest
     */
    'videoPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateLearningRequest
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateMaintenancePlanMeasureRequest
 */
export interface CreateMaintenancePlanMeasureRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateMaintenancePlanMeasureRequest
     */
    'startYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateMaintenancePlanMeasureRequest
     */
    'budgetYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateMaintenancePlanMeasureRequest
     */
    'priceEstimate'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateMaintenancePlanMeasureRequest
     */
    'priceCalculate'?: number;
}
/**
 * 
 * @export
 * @interface CreateMaintenancePlanParameterValueBaseRequest
 */
export interface CreateMaintenancePlanParameterValueBaseRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateMaintenancePlanParameterValueBaseRequest
     */
    'maintenancePlanId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMaintenancePlanParameterValueBaseRequest
     */
    'mappingParameterValueId'?: string;
}
/**
 * 
 * @export
 * @interface CreateMaintenancePlanRequest
 */
export interface CreateMaintenancePlanRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateMaintenancePlanRequest
     */
    'mappingObjectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMaintenancePlanRequest
     */
    'maintenancePlanName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateMaintenancePlanRequest
     */
    'maintenancePlanNumber'?: number;
    /**
     * 
     * @type {MaintenancePlanType}
     * @memberof CreateMaintenancePlanRequest
     */
    'maintenancePlanTypeId'?: MaintenancePlanType;
    /**
     * 
     * @type {number}
     * @memberof CreateMaintenancePlanRequest
     */
    'consequenceDegree'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateMaintenancePlanRequest
     */
    'priority'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateMaintenancePlanRequest
     */
    'maintenancePlanDescription'?: string | null;
    /**
     * 
     * @type {Array<CreateMaintenancePlanMeasureRequest>}
     * @memberof CreateMaintenancePlanRequest
     */
    'maintenancePlanMeasures'?: Array<CreateMaintenancePlanMeasureRequest> | null;
    /**
     * 
     * @type {Array<CreateMaintenancePlanParameterValueBaseRequest>}
     * @memberof CreateMaintenancePlanRequest
     */
    'maintenancePlanParameterValueBases'?: Array<CreateMaintenancePlanParameterValueBaseRequest> | null;
}
/**
 * 
 * @export
 * @interface CreateMappingGroupRequest
 */
export interface CreateMappingGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateMappingGroupRequest
     */
    'mappingGroupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingGroupRequest
     */
    'mappingSetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingGroupRequest
     */
    'systemName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateMappingGroupRequest
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {Array<CreateMappingParameterRequest>}
     * @memberof CreateMappingGroupRequest
     */
    'mappingParameters'?: Array<CreateMappingParameterRequest> | null;
}
/**
 * 
 * @export
 * @interface CreateMappingObjectStateRequest
 */
export interface CreateMappingObjectStateRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateMappingObjectStateRequest
     */
    'mappingObjectId'?: string;
    /**
     * 
     * @type {MappingState}
     * @memberof CreateMappingObjectStateRequest
     */
    'status'?: MappingState;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingObjectStateRequest
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingObjectStateRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {Array<MappingObjectGroupRequest>}
     * @memberof CreateMappingObjectStateRequest
     */
    'valueGroups'?: Array<MappingObjectGroupRequest> | null;
}
/**
 * 
 * @export
 * @interface CreateMappingParameterRequest
 */
export interface CreateMappingParameterRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateMappingParameterRequest
     */
    'mappingParameterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingParameterRequest
     */
    'mappingGroupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingParameterRequest
     */
    'systemName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateMappingParameterRequest
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateMappingParameterRequest
     */
    'lowerLevelDeviation'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateMappingPeriodDescriptionAndStateRequest
 */
export interface CreateMappingPeriodDescriptionAndStateRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateMappingPeriodDescriptionAndStateRequest
     */
    'mappingPeriodId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingPeriodDescriptionAndStateRequest
     */
    'yearAndMonth'?: string;
    /**
     * 
     * @type {MappingState}
     * @memberof CreateMappingPeriodDescriptionAndStateRequest
     */
    'stateId'?: MappingState;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingPeriodDescriptionAndStateRequest
     */
    'moduleWeightingVersionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingPeriodDescriptionAndStateRequest
     */
    'periodName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingPeriodDescriptionAndStateRequest
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateMappingPeriodRequest
 */
export interface CreateMappingPeriodRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateMappingPeriodRequest
     */
    'workspaceModuleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingPeriodRequest
     */
    'yearAndMonth'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingPeriodRequest
     */
    'moduleWeightingVersionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingPeriodRequest
     */
    'periodName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingPeriodRequest
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateMappingSetRequest
 */
export interface CreateMappingSetRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateMappingSetRequest
     */
    'mappingSetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingSetRequest
     */
    'systemName'?: string | null;
    /**
     * 
     * @type {Array<CreateMappingGroupRequest>}
     * @memberof CreateMappingSetRequest
     */
    'mappingGroups'?: Array<CreateMappingGroupRequest> | null;
}
/**
 * 
 * @export
 * @interface CreateModuleRequest
 */
export interface CreateModuleRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateModuleRequest
     */
    'objectTypeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateModuleRequest
     */
    'mappingSetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateModuleRequest
     */
    'objectPropertyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateModuleRequest
     */
    'systemName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateModuleRequest
     */
    'systemDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateModuleRequest
     */
    'maconomyId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateModuleRequest
     */
    'modulePrice'?: number;
    /**
     * 
     * @type {MappingModel}
     * @memberof CreateModuleRequest
     */
    'mappingModelId'?: MappingModel;
    /**
     * 
     * @type {number}
     * @memberof CreateModuleRequest
     */
    'mapLowValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateModuleRequest
     */
    'mapHighValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateModuleRequest
     */
    'usePercentValues'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateModuleRequest
     */
    'useObjectPropertyOptionSet'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateModuleRequest
     */
    'icon'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateModuleRequest
     */
    'customValues'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateModuleWeightingVersionRequest
 */
export interface CreateModuleWeightingVersionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateModuleWeightingVersionRequest
     */
    'moduleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateModuleWeightingVersionRequest
     */
    'versionName'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateObjectPropertyOptionRequest
 */
export interface CreateObjectPropertyOptionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateObjectPropertyOptionRequest
     */
    'systemName'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateObjectPropertyRequest
 */
export interface CreateObjectPropertyRequest {
    /**
     * 
     * @type {ObjectPropertyDatatype}
     * @memberof CreateObjectPropertyRequest
     */
    'datatypeId'?: ObjectPropertyDatatype;
    /**
     * 
     * @type {boolean}
     * @memberof CreateObjectPropertyRequest
     */
    'required'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateObjectPropertyRequest
     */
    'systemName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateObjectPropertyRequest
     */
    'systemDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateObjectPropertyRequest
     */
    'isTotal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateObjectPropertyRequest
     */
    'isAverage'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateObjectPropertyRequest
     */
    'weightedAveragePropertyId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateObjectPropertyRequest
     */
    'minValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateObjectPropertyRequest
     */
    'maxValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateObjectPropertyRequest
     */
    'suffix'?: string | null;
    /**
     * 
     * @type {Array<CreateObjectPropertyOptionRequest>}
     * @memberof CreateObjectPropertyRequest
     */
    'options'?: Array<CreateObjectPropertyOptionRequest> | null;
}
/**
 * 
 * @export
 * @interface CreateOrganizationModuleRequest
 */
export interface CreateOrganizationModuleRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationModuleRequest
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationModuleRequest
     */
    'moduleId'?: string;
}
/**
 * 
 * @export
 * @interface CreateOrganizationReportRequest
 */
export interface CreateOrganizationReportRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationReportRequest
     */
    'organizationReportId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationReportRequest
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationReportRequest
     */
    'reportId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationReportRequest
     */
    'link'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrganizationReportRequest
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationReportRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationReportRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationReportRequest
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateOrganizationServiceTypeRequest
 */
export interface CreateOrganizationServiceTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationServiceTypeRequest
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {ServiceType}
     * @memberof CreateOrganizationServiceTypeRequest
     */
    'serviceType'?: ServiceType;
}
/**
 * 
 * @export
 * @interface CreateOrganizationWorkspaceRequest
 */
export interface CreateOrganizationWorkspaceRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationWorkspaceRequest
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationWorkspaceRequest
     */
    'workspaceId'?: string;
}
/**
 * 
 * @export
 * @interface CreateReportRequest
 */
export interface CreateReportRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateReportRequest
     */
    'systemName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateReportRequest
     */
    'systemDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateReportRequest
     */
    'readMore'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateReportRequest
     */
    'orderText'?: string | null;
    /**
     * 
     * @type {ReportType}
     * @memberof CreateReportRequest
     */
    'reportTypeId'?: ReportType;
    /**
     * 
     * @type {string}
     * @memberof CreateReportRequest
     */
    'icon'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateReportRequest
     */
    'reportPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateReportRequest
     */
    'objectTypeId'?: string;
}
/**
 * 
 * @export
 * @interface CreateUserInvitationRequest
 */
export interface CreateUserInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserInvitationRequest
     */
    'userEmail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserInvitationRequest
     */
    'assignOrgAdmin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserInvitationRequest
     */
    'assignSysAdmin'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUserInvitationRequest
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserInvitationRequest
     */
    'urlLink'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateWorkspaceModuleRequest
 */
export interface CreateWorkspaceModuleRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceModuleRequest
     */
    'workspaceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceModuleRequest
     */
    'moduleId'?: string;
}
/**
 * 
 * @export
 * @interface CreditScoreResponse
 */
export interface CreditScoreResponse {
    /**
     * 
     * @type {string}
     * @memberof CreditScoreResponse
     */
    'riskClass'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditScoreResponse
     */
    'riskClassText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditScoreResponse
     */
    'creditLimit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditScoreResponse
     */
    'creditScoreInterpretationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditScoreResponse
     */
    'score'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditScoreResponse
     */
    'scoreCardModel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditScoreResponse
     */
    'probabilityOfDefault'?: string | null;
    /**
     * 
     * @type {Array<CreditScoreTrendResponse>}
     * @memberof CreditScoreResponse
     */
    'creditScoreTrend'?: Array<CreditScoreTrendResponse> | null;
}
/**
 * 
 * @export
 * @interface CreditScoreTrendResponse
 */
export interface CreditScoreTrendResponse {
    /**
     * 
     * @type {string}
     * @memberof CreditScoreTrendResponse
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditScoreTrendResponse
     */
    'score'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditScoreTrendResponse
     */
    'riskClass'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreditScoreTrendResponse
     */
    'riskClassText'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerAccessRequestDetailResponse
 */
export interface CustomerAccessRequestDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestDetailResponse
     */
    'customerAccessRequestId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestDetailResponse
     */
    'createdByUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestDetailResponse
     */
    'createdByUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestDetailResponse
     */
    'processedByUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestDetailResponse
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestDetailResponse
     */
    'processedOn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestDetailResponse
     */
    'customerOrganizationId'?: string | null;
    /**
     * 
     * @type {CustomerAccessRequestStatus}
     * @memberof CustomerAccessRequestDetailResponse
     */
    'status'?: CustomerAccessRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestDetailResponse
     */
    'organizationNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestDetailResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestDetailResponse
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestDetailResponse
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestDetailResponse
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestDetailResponse
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestDetailResponse
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestDetailResponse
     */
    'phone'?: string | null;
    /**
     * 
     * @type {OrganizationCreditInfoResponse}
     * @memberof CustomerAccessRequestDetailResponse
     */
    'creditInfo'?: OrganizationCreditInfoResponse;
    /**
     * 
     * @type {OrganizationType}
     * @memberof CustomerAccessRequestDetailResponse
     */
    'organizationType'?: OrganizationType;
}
/**
 * 
 * @export
 * @interface CustomerAccessRequestResponse
 */
export interface CustomerAccessRequestResponse {
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestResponse
     */
    'customerAccessRequestId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestResponse
     */
    'createdByUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestResponse
     */
    'createdByUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestResponse
     */
    'processedByUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestResponse
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestResponse
     */
    'processedOn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestResponse
     */
    'customerOrganizationId'?: string | null;
    /**
     * 
     * @type {CustomerAccessRequestStatus}
     * @memberof CustomerAccessRequestResponse
     */
    'status'?: CustomerAccessRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestResponse
     */
    'organizationNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestResponse
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestResponse
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestResponse
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestResponse
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestResponse
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAccessRequestResponse
     */
    'phone'?: string | null;
    /**
     * 
     * @type {OrganizationType}
     * @memberof CustomerAccessRequestResponse
     */
    'organizationType'?: OrganizationType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerAccessRequestStatus = {
    Unknown: 'Unknown',
    Pending: 'Pending',
    Approved: 'Approved',
    Denied: 'Denied'
} as const;

export type CustomerAccessRequestStatus = typeof CustomerAccessRequestStatus[keyof typeof CustomerAccessRequestStatus];


/**
 * 
 * @export
 * @interface IndustryCodeResponse
 */
export interface IndustryCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof IndustryCodeResponse
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IndustryCodeResponse
     */
    'text'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Language = {
    Norwegian: 'Norwegian',
    English: 'English'
} as const;

export type Language = typeof Language[keyof typeof Language];


/**
 * 
 * @export
 * @interface LearningResponse
 */
export interface LearningResponse {
    /**
     * 
     * @type {string}
     * @memberof LearningResponse
     */
    'learningId': string;
    /**
     * 
     * @type {string}
     * @memberof LearningResponse
     */
    'mappingSetId': string;
    /**
     * 
     * @type {string}
     * @memberof LearningResponse
     */
    'mappingSetName': string;
    /**
     * 
     * @type {boolean}
     * @memberof LearningResponse
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof LearningResponse
     */
    'videoPath': string;
    /**
     * 
     * @type {string}
     * @memberof LearningResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof LearningResponse
     */
    'createdOn': string;
    /**
     * 
     * @type {string}
     * @memberof LearningResponse
     */
    'modifiedOn': string;
}
/**
 * 
 * @export
 * @interface MaintenancePlanMeasureResponse
 */
export interface MaintenancePlanMeasureResponse {
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanMeasureResponse
     */
    'maintenancePlanMeasureId': string;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanMeasureResponse
     */
    'startYear': number;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanMeasureResponse
     */
    'budgetYear': number;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanMeasureResponse
     */
    'priceEstimate': number;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanMeasureResponse
     */
    'priceCalculate': number;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanMeasureResponse
     */
    'finishYear': number;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanMeasureResponse
     */
    'priceFinish': number;
}
/**
 * 
 * @export
 * @interface MaintenancePlanParameterDetailsResponse
 */
export interface MaintenancePlanParameterDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'maintenancePlanParameterValueBaseId': string;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'maintenancePlanId': string;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'parameterValueId': string;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'maintenancePlanName': string;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'maintenancePlanNumber': number;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'maintenancePlanObjectId': string;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'maintenancePlanObjectName': string;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'maintenancePlanParentObjectId': string;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'maintenancePlanParentObjectName': string;
    /**
     * 
     * @type {MaintenancePlanType}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'maintenancePlanTypeId': MaintenancePlanType;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'parameterName': string;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'conditionDegree': number;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'consequenceDegree': number;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'riskDegree': number;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'startYear': number;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'budgetYear': number;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'finishYear': number;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'parentObjectTypeName': string;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanParameterDetailsResponse
     */
    'objectTypeName': string;
}
/**
 * 
 * @export
 * @interface MaintenancePlanParameterValueBaseResponse
 */
export interface MaintenancePlanParameterValueBaseResponse {
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanParameterValueBaseResponse
     */
    'maintenancePlanParameterValueBaseId': string;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanParameterValueBaseResponse
     */
    'maintenancePlanId': string;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanParameterValueBaseResponse
     */
    'mappingParameterValueId': string;
}
/**
 * 
 * @export
 * @interface MaintenancePlanResponse
 */
export interface MaintenancePlanResponse {
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanResponse
     */
    'maintenancePlanId': string;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanResponse
     */
    'maintenancePlanName': string;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanResponse
     */
    'maintenancePlanNumber': number;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanResponse
     */
    'maintenancePlanDescription': string;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanResponse
     */
    'objectTypeName': string;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanResponse
     */
    'objectName': string;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanResponse
     */
    'objectTypeParentName': string;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanResponse
     */
    'objectParentName': string;
    /**
     * 
     * @type {MaintenancePlanType}
     * @memberof MaintenancePlanResponse
     */
    'maintenancePlanTypeId': MaintenancePlanType;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanResponse
     */
    'consequenceDegree': number;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanResponse
     */
    'priority': number;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanResponse
     */
    'totalPriceCalculate': number;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanResponse
     */
    'totalPriceEstimate': number;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenancePlanResponse
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanResponse
     */
    'mappingObjectId': string;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanResponse
     */
    'lastUpdated': string;
    /**
     * 
     * @type {Array<MaintenancePlanMeasureResponse>}
     * @memberof MaintenancePlanResponse
     */
    'maintenancePlanMeasures'?: Array<MaintenancePlanMeasureResponse> | null;
    /**
     * 
     * @type {Array<MaintenancePlanParameterValueBaseResponse>}
     * @memberof MaintenancePlanResponse
     */
    'maintenancePlanParameterValueBases'?: Array<MaintenancePlanParameterValueBaseResponse> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MaintenancePlanType = {
    Sikkerhet: 'Sikkerhet',
    Konomi: 'Økonomi',
    Estetikk: 'Estetikk',
    Milj: 'Miljø'
} as const;

export type MaintenancePlanType = typeof MaintenancePlanType[keyof typeof MaintenancePlanType];


/**
 * 
 * @export
 * @interface MappingDataReportObjectRowResponse
 */
export interface MappingDataReportObjectRowResponse {
    /**
     * 
     * @type {string}
     * @memberof MappingDataReportObjectRowResponse
     */
    'mappingObjectId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingDataReportObjectRowResponse
     */
    'objectId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingDataReportObjectRowResponse
     */
    'objectName': string;
    /**
     * 
     * @type {string}
     * @memberof MappingDataReportObjectRowResponse
     */
    'parentObjectName': string;
    /**
     * 
     * @type {number}
     * @memberof MappingDataReportObjectRowResponse
     */
    'year'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MappingDataReportObjectRowResponse
     */
    'areal'?: number | null;
    /**
     * 
     * @type {Array<ParameterValue>}
     * @memberof MappingDataReportObjectRowResponse
     */
    'parameterValues'?: Array<ParameterValue> | null;
    /**
     * 
     * @type {Array<MappingDataReportObjectRowResponse>}
     * @memberof MappingDataReportObjectRowResponse
     */
    'children'?: Array<MappingDataReportObjectRowResponse> | null;
}
/**
 * 
 * @export
 * @interface MappingDataReportResponse
 */
export interface MappingDataReportResponse {
    /**
     * 
     * @type {string}
     * @memberof MappingDataReportResponse
     */
    'periodId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingDataReportResponse
     */
    'objectTypeTitle': string;
    /**
     * 
     * @type {Array<ColumnTitleResponse>}
     * @memberof MappingDataReportResponse
     */
    'columnTitles'?: Array<ColumnTitleResponse> | null;
    /**
     * 
     * @type {Array<MappingDataReportObjectRowResponse>}
     * @memberof MappingDataReportResponse
     */
    'reportObjectRows'?: Array<MappingDataReportObjectRowResponse> | null;
}
/**
 * 
 * @export
 * @interface MappingGroupResponse
 */
export interface MappingGroupResponse {
    /**
     * 
     * @type {string}
     * @memberof MappingGroupResponse
     */
    'mappingGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingGroupResponse
     */
    'mappingSetId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingGroupResponse
     */
    'systemName': string;
    /**
     * 
     * @type {number}
     * @memberof MappingGroupResponse
     */
    'sortOrder': number;
    /**
     * 
     * @type {string}
     * @memberof MappingGroupResponse
     */
    'createdOn': string;
    /**
     * 
     * @type {Array<MappingParameterResponse>}
     * @memberof MappingGroupResponse
     */
    'mappingParameters'?: Array<MappingParameterResponse> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MappingModel = {
    MapToObject: 'MapToObject',
    MapToObjectChildren: 'MapToObjectChildren'
} as const;

export type MappingModel = typeof MappingModel[keyof typeof MappingModel];


/**
 * 
 * @export
 * @interface MappingObjectGroupRequest
 */
export interface MappingObjectGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof MappingObjectGroupRequest
     */
    'mappingGroupId'?: string;
    /**
     * 
     * @type {Array<MappingParameterConditionDegreeValueRequest>}
     * @memberof MappingObjectGroupRequest
     */
    'cdValues'?: Array<MappingParameterConditionDegreeValueRequest> | null;
    /**
     * 
     * @type {Array<MappingParameterPercentValuesRequest>}
     * @memberof MappingObjectGroupRequest
     */
    'percentValues'?: Array<MappingParameterPercentValuesRequest> | null;
    /**
     * 
     * @type {Array<MappingParameterCustomValueRequest>}
     * @memberof MappingObjectGroupRequest
     */
    'customValues'?: Array<MappingParameterCustomValueRequest> | null;
}
/**
 * 
 * @export
 * @interface MappingObjectGroupResponse
 */
export interface MappingObjectGroupResponse {
    /**
     * 
     * @type {string}
     * @memberof MappingObjectGroupResponse
     */
    'mappingGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingObjectGroupResponse
     */
    'mappingGroupName': string;
    /**
     * 
     * @type {number}
     * @memberof MappingObjectGroupResponse
     */
    'sortOrder': number;
    /**
     * 
     * @type {number}
     * @memberof MappingObjectGroupResponse
     */
    'averageConditionDegree': number;
    /**
     * 
     * @type {Array<MappingParameterConditionDegreeValueResponse>}
     * @memberof MappingObjectGroupResponse
     */
    'cdValues'?: Array<MappingParameterConditionDegreeValueResponse> | null;
    /**
     * 
     * @type {Array<MappingParameterPercentValuesResponse>}
     * @memberof MappingObjectGroupResponse
     */
    'percentValues'?: Array<MappingParameterPercentValuesResponse> | null;
    /**
     * 
     * @type {Array<MappingParameterCustomValueResponse>}
     * @memberof MappingObjectGroupResponse
     */
    'customValues'?: Array<MappingParameterCustomValueResponse> | null;
}
/**
 * 
 * @export
 * @interface MappingObjectResponse
 */
export interface MappingObjectResponse {
    /**
     * 
     * @type {string}
     * @memberof MappingObjectResponse
     */
    'mappingObjectId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingObjectResponse
     */
    'mappingPeriodId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingObjectResponse
     */
    'objectToMapId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingObjectResponse
     */
    'moduleId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingObjectResponse
     */
    'objectParentId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingObjectResponse
     */
    'objectParentName': string;
    /**
     * 
     * @type {string}
     * @memberof MappingObjectResponse
     */
    'objectName': string;
    /**
     * 
     * @type {MappingState}
     * @memberof MappingObjectResponse
     */
    'status': MappingState;
    /**
     * 
     * @type {string}
     * @memberof MappingObjectResponse
     */
    'mappedByUserName': string;
    /**
     * 
     * @type {string}
     * @memberof MappingObjectResponse
     */
    'lastEdited': string;
    /**
     * 
     * @type {string}
     * @memberof MappingObjectResponse
     */
    'comment': string;
    /**
     * 
     * @type {boolean}
     * @memberof MappingObjectResponse
     */
    'useCustomValues': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MappingObjectResponse
     */
    'usePercentValues': boolean;
    /**
     * 
     * @type {number}
     * @memberof MappingObjectResponse
     */
    'mapLowValue': number;
    /**
     * 
     * @type {number}
     * @memberof MappingObjectResponse
     */
    'mapHighValue': number;
    /**
     * 
     * @type {number}
     * @memberof MappingObjectResponse
     */
    'areal': number;
    /**
     * 
     * @type {number}
     * @memberof MappingObjectResponse
     */
    'year': number;
    /**
     * 
     * @type {MappingModel}
     * @memberof MappingObjectResponse
     */
    'mappingModelId': MappingModel;
    /**
     * 
     * @type {number}
     * @memberof MappingObjectResponse
     */
    'averageConditionDegree': number;
    /**
     * 
     * @type {Array<MappingObjectGroupResponse>}
     * @memberof MappingObjectResponse
     */
    'valueGroups'?: Array<MappingObjectGroupResponse> | null;
}
/**
 * 
 * @export
 * @interface MappingParameterConditionDegreeValueRequest
 */
export interface MappingParameterConditionDegreeValueRequest {
    /**
     * 
     * @type {string}
     * @memberof MappingParameterConditionDegreeValueRequest
     */
    'mappingParameterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterConditionDegreeValueRequest
     */
    'mappingObjectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterConditionDegreeValueRequest
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterConditionDegreeValueRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof MappingParameterConditionDegreeValueRequest
     */
    'value'?: number | null;
    /**
     * 
     * @type {Array<MappingParameterConditionDegreeValueRequest>}
     * @memberof MappingParameterConditionDegreeValueRequest
     */
    'childValues'?: Array<MappingParameterConditionDegreeValueRequest> | null;
}
/**
 * 
 * @export
 * @interface MappingParameterConditionDegreeValueResponse
 */
export interface MappingParameterConditionDegreeValueResponse {
    /**
     * 
     * @type {string}
     * @memberof MappingParameterConditionDegreeValueResponse
     */
    'mappingParameterValueId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterConditionDegreeValueResponse
     */
    'mappingParameterId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterConditionDegreeValueResponse
     */
    'mappingParameterName': string;
    /**
     * 
     * @type {number}
     * @memberof MappingParameterConditionDegreeValueResponse
     */
    'sortOrder': number;
    /**
     * 
     * @type {boolean}
     * @memberof MappingParameterConditionDegreeValueResponse
     */
    'lowerLevelDeviation': boolean;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterConditionDegreeValueResponse
     */
    'mappingObjectId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterConditionDegreeValueResponse
     */
    'comment': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterConditionDegreeValueResponse
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterConditionDegreeValueResponse
     */
    'commenter': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterConditionDegreeValueResponse
     */
    'createdOn': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterConditionDegreeValueResponse
     */
    'value'?: string | null;
    /**
     * 
     * @type {Array<MappingParameterConditionDegreeValueResponse>}
     * @memberof MappingParameterConditionDegreeValueResponse
     */
    'childValues'?: Array<MappingParameterConditionDegreeValueResponse> | null;
}
/**
 * 
 * @export
 * @interface MappingParameterCustomValueRequest
 */
export interface MappingParameterCustomValueRequest {
    /**
     * 
     * @type {string}
     * @memberof MappingParameterCustomValueRequest
     */
    'mappingParameterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterCustomValueRequest
     */
    'mappingObjectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterCustomValueRequest
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterCustomValueRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof MappingParameterCustomValueRequest
     */
    'wholeValues'?: number | null;
    /**
     * 
     * @type {Array<MappingParameterCustomValueRequest>}
     * @memberof MappingParameterCustomValueRequest
     */
    'childValues'?: Array<MappingParameterCustomValueRequest> | null;
}
/**
 * 
 * @export
 * @interface MappingParameterCustomValueResponse
 */
export interface MappingParameterCustomValueResponse {
    /**
     * 
     * @type {string}
     * @memberof MappingParameterCustomValueResponse
     */
    'mappingParameterValueId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterCustomValueResponse
     */
    'mappingParameterId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterCustomValueResponse
     */
    'mappingParameterName': string;
    /**
     * 
     * @type {number}
     * @memberof MappingParameterCustomValueResponse
     */
    'sortOrder': number;
    /**
     * 
     * @type {boolean}
     * @memberof MappingParameterCustomValueResponse
     */
    'lowerLevelDeviation': boolean;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterCustomValueResponse
     */
    'mappingObjectId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterCustomValueResponse
     */
    'comment': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterCustomValueResponse
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterCustomValueResponse
     */
    'commenter': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterCustomValueResponse
     */
    'createdOn': string;
    /**
     * 
     * @type {number}
     * @memberof MappingParameterCustomValueResponse
     */
    'value'?: number | null;
    /**
     * 
     * @type {Array<MappingParameterCustomValueResponse>}
     * @memberof MappingParameterCustomValueResponse
     */
    'childValues'?: Array<MappingParameterCustomValueResponse> | null;
}
/**
 * 
 * @export
 * @interface MappingParameterPercentValuesRequest
 */
export interface MappingParameterPercentValuesRequest {
    /**
     * 
     * @type {string}
     * @memberof MappingParameterPercentValuesRequest
     */
    'mappingParameterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterPercentValuesRequest
     */
    'mappingObjectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterPercentValuesRequest
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterPercentValuesRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterPercentValuesRequest
     */
    'percentValues'?: string | null;
    /**
     * 
     * @type {Array<MappingParameterPercentValuesRequest>}
     * @memberof MappingParameterPercentValuesRequest
     */
    'childValues'?: Array<MappingParameterPercentValuesRequest> | null;
}
/**
 * 
 * @export
 * @interface MappingParameterPercentValuesResponse
 */
export interface MappingParameterPercentValuesResponse {
    /**
     * 
     * @type {string}
     * @memberof MappingParameterPercentValuesResponse
     */
    'mappingParameterValueId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterPercentValuesResponse
     */
    'mappingParameterId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterPercentValuesResponse
     */
    'mappingParameterName': string;
    /**
     * 
     * @type {number}
     * @memberof MappingParameterPercentValuesResponse
     */
    'sortOrder': number;
    /**
     * 
     * @type {boolean}
     * @memberof MappingParameterPercentValuesResponse
     */
    'lowerLevelDeviation': boolean;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterPercentValuesResponse
     */
    'mappingObjectId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterPercentValuesResponse
     */
    'comment': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterPercentValuesResponse
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterPercentValuesResponse
     */
    'commenter': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterPercentValuesResponse
     */
    'createdOn': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof MappingParameterPercentValuesResponse
     */
    'percentValues'?: Array<number> | null;
    /**
     * 
     * @type {Array<MappingParameterPercentValuesResponse>}
     * @memberof MappingParameterPercentValuesResponse
     */
    'childValues'?: Array<MappingParameterPercentValuesResponse> | null;
}
/**
 * 
 * @export
 * @interface MappingParameterResponse
 */
export interface MappingParameterResponse {
    /**
     * 
     * @type {string}
     * @memberof MappingParameterResponse
     */
    'mappingParameterId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterResponse
     */
    'mappingGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterResponse
     */
    'systemName': string;
    /**
     * 
     * @type {number}
     * @memberof MappingParameterResponse
     */
    'sortOrder': number;
    /**
     * 
     * @type {boolean}
     * @memberof MappingParameterResponse
     */
    'lowerLevelDeviation': boolean;
    /**
     * 
     * @type {string}
     * @memberof MappingParameterResponse
     */
    'createdOn': string;
}
/**
 * 
 * @export
 * @interface MappingPeriodResponse
 */
export interface MappingPeriodResponse {
    /**
     * 
     * @type {string}
     * @memberof MappingPeriodResponse
     */
    'mappingPeriodId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingPeriodResponse
     */
    'workspaceModuleId': string;
    /**
     * 
     * @type {MappingState}
     * @memberof MappingPeriodResponse
     */
    'mappingStateId': MappingState;
    /**
     * 
     * @type {string}
     * @memberof MappingPeriodResponse
     */
    'mappingPeriodName': string;
    /**
     * 
     * @type {string}
     * @memberof MappingPeriodResponse
     */
    'mappingPeriodDescription': string;
    /**
     * 
     * @type {string}
     * @memberof MappingPeriodResponse
     */
    'formattedActiveMappingPeriod': string;
    /**
     * 
     * @type {string}
     * @memberof MappingPeriodResponse
     */
    'activeMappingPeriod': string;
    /**
     * 
     * @type {number}
     * @memberof MappingPeriodResponse
     */
    'mappingObjectsAmountNotStarted': number;
    /**
     * 
     * @type {number}
     * @memberof MappingPeriodResponse
     */
    'mappingObjectsAmountStarted': number;
    /**
     * 
     * @type {number}
     * @memberof MappingPeriodResponse
     */
    'mappingObjectsAmountDone': number;
    /**
     * 
     * @type {string}
     * @memberof MappingPeriodResponse
     */
    'objectTypeName': string;
    /**
     * 
     * @type {string}
     * @memberof MappingPeriodResponse
     */
    'objectTypeParentName': string;
    /**
     * 
     * @type {string}
     * @memberof MappingPeriodResponse
     */
    'moduleWeightingVersionId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingPeriodResponse
     */
    'lastEdited': string;
    /**
     * 
     * @type {Array<MappingObjectResponse>}
     * @memberof MappingPeriodResponse
     */
    'mappingObjects'?: Array<MappingObjectResponse> | null;
}
/**
 * 
 * @export
 * @interface MappingSetDetailResponse
 */
export interface MappingSetDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof MappingSetDetailResponse
     */
    'mappingSetId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingSetDetailResponse
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof MappingSetDetailResponse
     */
    'createdOn': string;
    /**
     * 
     * @type {Array<MappingGroupResponse>}
     * @memberof MappingSetDetailResponse
     */
    'mappingGroups'?: Array<MappingGroupResponse> | null;
}
/**
 * 
 * @export
 * @interface MappingSetResponse
 */
export interface MappingSetResponse {
    /**
     * 
     * @type {string}
     * @memberof MappingSetResponse
     */
    'mappingSetId': string;
    /**
     * 
     * @type {string}
     * @memberof MappingSetResponse
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof MappingSetResponse
     */
    'createdOn': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MappingState = {
    Open: 'Open',
    InProgress: 'InProgress',
    Closed: 'Closed',
    InActive: 'InActive'
} as const;

export type MappingState = typeof MappingState[keyof typeof MappingState];


/**
 * 
 * @export
 * @interface ModuleConditionDegreeDescriptionDto
 */
export interface ModuleConditionDegreeDescriptionDto {
    /**
     * 
     * @type {string}
     * @memberof ModuleConditionDegreeDescriptionDto
     */
    'moduleConditionDegreeDescriptionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModuleConditionDegreeDescriptionDto
     */
    'conditionDegree'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleConditionDegreeDescriptionDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ModuleConditionDegreeDescriptionResponse
 */
export interface ModuleConditionDegreeDescriptionResponse {
    /**
     * 
     * @type {string}
     * @memberof ModuleConditionDegreeDescriptionResponse
     */
    'moduleConditionDegreeDescriptionId': string;
    /**
     * 
     * @type {number}
     * @memberof ModuleConditionDegreeDescriptionResponse
     */
    'conditionDegree': number;
    /**
     * 
     * @type {string}
     * @memberof ModuleConditionDegreeDescriptionResponse
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface ModuleMappingGroupDescriptionDto
 */
export interface ModuleMappingGroupDescriptionDto {
    /**
     * 
     * @type {string}
     * @memberof ModuleMappingGroupDescriptionDto
     */
    'mappingGroupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModuleMappingGroupDescriptionDto
     */
    'mappingGroupName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModuleMappingGroupDescriptionDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {Array<ModuleParameterDescriptionDto>}
     * @memberof ModuleMappingGroupDescriptionDto
     */
    'parameters'?: Array<ModuleParameterDescriptionDto> | null;
}
/**
 * 
 * @export
 * @interface ModuleMappingGroupDescriptionResponse
 */
export interface ModuleMappingGroupDescriptionResponse {
    /**
     * 
     * @type {string}
     * @memberof ModuleMappingGroupDescriptionResponse
     */
    'mappingGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleMappingGroupDescriptionResponse
     */
    'mappingGroupName': string;
    /**
     * 
     * @type {number}
     * @memberof ModuleMappingGroupDescriptionResponse
     */
    'sortOrder': number;
    /**
     * 
     * @type {Array<ModuleParameterDescriptionResponse>}
     * @memberof ModuleMappingGroupDescriptionResponse
     */
    'parameters': Array<ModuleParameterDescriptionResponse>;
}
/**
 * 
 * @export
 * @interface ModuleObjectTypeResponse
 */
export interface ModuleObjectTypeResponse {
    /**
     * 
     * @type {string}
     * @memberof ModuleObjectTypeResponse
     */
    'objectTypeId': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleObjectTypeResponse
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleObjectTypeResponse
     */
    'systemDescription': string;
    /**
     * 
     * @type {ModuleObjectTypeRootResponse}
     * @memberof ModuleObjectTypeResponse
     */
    'root': ModuleObjectTypeRootResponse;
}
/**
 * 
 * @export
 * @interface ModuleObjectTypeRootResponse
 */
export interface ModuleObjectTypeRootResponse {
    /**
     * 
     * @type {string}
     * @memberof ModuleObjectTypeRootResponse
     */
    'objectTypeId': string;
    /**
     * 
     * @type {ObjectTypeGroup}
     * @memberof ModuleObjectTypeRootResponse
     */
    'groupId'?: ObjectTypeGroup;
    /**
     * 
     * @type {string}
     * @memberof ModuleObjectTypeRootResponse
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleObjectTypeRootResponse
     */
    'systemDescription': string;
}
/**
 * 
 * @export
 * @interface ModuleParameterDescriptionDto
 */
export interface ModuleParameterDescriptionDto {
    /**
     * 
     * @type {string}
     * @memberof ModuleParameterDescriptionDto
     */
    'moduleParameterDescriptionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModuleParameterDescriptionDto
     */
    'parameterName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModuleParameterDescriptionDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleParameterDescriptionDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<ModuleConditionDegreeDescriptionDto>}
     * @memberof ModuleParameterDescriptionDto
     */
    'conditionDegrees'?: Array<ModuleConditionDegreeDescriptionDto> | null;
}
/**
 * 
 * @export
 * @interface ModuleParameterDescriptionResponse
 */
export interface ModuleParameterDescriptionResponse {
    /**
     * 
     * @type {string}
     * @memberof ModuleParameterDescriptionResponse
     */
    'moduleParameterDescriptionId': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleParameterDescriptionResponse
     */
    'parameterName': string;
    /**
     * 
     * @type {number}
     * @memberof ModuleParameterDescriptionResponse
     */
    'sortOrder': number;
    /**
     * 
     * @type {string}
     * @memberof ModuleParameterDescriptionResponse
     */
    'description': string;
    /**
     * 
     * @type {Array<ModuleConditionDegreeDescriptionResponse>}
     * @memberof ModuleParameterDescriptionResponse
     */
    'conditionDegrees'?: Array<ModuleConditionDegreeDescriptionResponse> | null;
}
/**
 * 
 * @export
 * @interface ModuleResponse
 */
export interface ModuleResponse {
    /**
     * 
     * @type {string}
     * @memberof ModuleResponse
     */
    'moduleId': string;
    /**
     * 
     * @type {ModuleObjectTypeResponse}
     * @memberof ModuleResponse
     */
    'objectType': ModuleObjectTypeResponse;
    /**
     * 
     * @type {MappingSetResponse}
     * @memberof ModuleResponse
     */
    'mappingSet': MappingSetResponse;
    /**
     * 
     * @type {ObjectPropertyResponse}
     * @memberof ModuleResponse
     */
    'objectProperty'?: ObjectPropertyResponse;
    /**
     * 
     * @type {string}
     * @memberof ModuleResponse
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleResponse
     */
    'systemDescription': string;
    /**
     * 
     * @type {MappingModel}
     * @memberof ModuleResponse
     */
    'mappingModelId': MappingModel;
    /**
     * 
     * @type {number}
     * @memberof ModuleResponse
     */
    'mapLowValue': number;
    /**
     * 
     * @type {number}
     * @memberof ModuleResponse
     */
    'mapHighValue': number;
    /**
     * 
     * @type {boolean}
     * @memberof ModuleResponse
     */
    'usePercentValues': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModuleResponse
     */
    'useObjectPropertyOptionSet': boolean;
    /**
     * 
     * @type {string}
     * @memberof ModuleResponse
     */
    'icon': string;
    /**
     * 
     * @type {boolean}
     * @memberof ModuleResponse
     */
    'customValues': boolean;
    /**
     * 
     * @type {string}
     * @memberof ModuleResponse
     */
    'createdOn': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleResponse
     */
    'maconomyId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ModuleResponse
     */
    'active': boolean;
    /**
     * 
     * @type {number}
     * @memberof ModuleResponse
     */
    'modulePrice': number;
}
/**
 * 
 * @export
 * @interface ModuleWeightingFactorResponse
 */
export interface ModuleWeightingFactorResponse {
    /**
     * 
     * @type {string}
     * @memberof ModuleWeightingFactorResponse
     */
    'moduleWeightingFactorId': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleWeightingFactorResponse
     */
    'parameterName': string;
    /**
     * 
     * @type {number}
     * @memberof ModuleWeightingFactorResponse
     */
    'sortOrder': number;
    /**
     * 
     * @type {number}
     * @memberof ModuleWeightingFactorResponse
     */
    'weighting': number;
}
/**
 * 
 * @export
 * @interface ModuleWeightingGroupResponse
 */
export interface ModuleWeightingGroupResponse {
    /**
     * 
     * @type {string}
     * @memberof ModuleWeightingGroupResponse
     */
    'mappingGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleWeightingGroupResponse
     */
    'mappingGroupName': string;
    /**
     * 
     * @type {number}
     * @memberof ModuleWeightingGroupResponse
     */
    'totalWeighting': number;
    /**
     * 
     * @type {number}
     * @memberof ModuleWeightingGroupResponse
     */
    'sortOrder': number;
    /**
     * 
     * @type {Array<ModuleWeightingFactorResponse>}
     * @memberof ModuleWeightingGroupResponse
     */
    'factors': Array<ModuleWeightingFactorResponse>;
}
/**
 * 
 * @export
 * @interface ModuleWeightingOptionResponse
 */
export interface ModuleWeightingOptionResponse {
    /**
     * 
     * @type {string}
     * @memberof ModuleWeightingOptionResponse
     */
    'objectPropertyOptionId': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleWeightingOptionResponse
     */
    'optionName': string;
    /**
     * 
     * @type {Array<ModuleWeightingGroupResponse>}
     * @memberof ModuleWeightingOptionResponse
     */
    'groups': Array<ModuleWeightingGroupResponse>;
}
/**
 * 
 * @export
 * @interface ModuleWeightingVersionDetailResponse
 */
export interface ModuleWeightingVersionDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof ModuleWeightingVersionDetailResponse
     */
    'moduleWeightingVersionId': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleWeightingVersionDetailResponse
     */
    'versionName': string;
    /**
     * 
     * @type {boolean}
     * @memberof ModuleWeightingVersionDetailResponse
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof ModuleWeightingVersionDetailResponse
     */
    'createdOn': string;
    /**
     * 
     * @type {Array<ModuleWeightingOptionResponse>}
     * @memberof ModuleWeightingVersionDetailResponse
     */
    'options': Array<ModuleWeightingOptionResponse>;
}
/**
 * 
 * @export
 * @interface ModuleWeightingVersionResponse
 */
export interface ModuleWeightingVersionResponse {
    /**
     * 
     * @type {string}
     * @memberof ModuleWeightingVersionResponse
     */
    'moduleWeightingVersionId': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleWeightingVersionResponse
     */
    'versionName': string;
    /**
     * 
     * @type {boolean}
     * @memberof ModuleWeightingVersionResponse
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof ModuleWeightingVersionResponse
     */
    'createdOn': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ObjectPropertyDatatype = {
    Unknown: 'Unknown',
    Integer: 'Integer',
    String: 'String',
    Float: 'Float',
    Address: 'Address',
    OptionSet: 'OptionSet'
} as const;

export type ObjectPropertyDatatype = typeof ObjectPropertyDatatype[keyof typeof ObjectPropertyDatatype];


/**
 * 
 * @export
 * @interface ObjectPropertyOptionResponse
 */
export interface ObjectPropertyOptionResponse {
    /**
     * 
     * @type {string}
     * @memberof ObjectPropertyOptionResponse
     */
    'objectPropertyOptionId': string;
    /**
     * 
     * @type {string}
     * @memberof ObjectPropertyOptionResponse
     */
    'objectPropertyId': string;
    /**
     * 
     * @type {string}
     * @memberof ObjectPropertyOptionResponse
     */
    'systemName': string;
}
/**
 * 
 * @export
 * @interface ObjectPropertyResponse
 */
export interface ObjectPropertyResponse {
    /**
     * 
     * @type {string}
     * @memberof ObjectPropertyResponse
     */
    'objectPropertyId': string;
    /**
     * 
     * @type {ObjectPropertyDatatype}
     * @memberof ObjectPropertyResponse
     */
    'datatypeId': ObjectPropertyDatatype;
    /**
     * 
     * @type {boolean}
     * @memberof ObjectPropertyResponse
     */
    'required': boolean;
    /**
     * 
     * @type {string}
     * @memberof ObjectPropertyResponse
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof ObjectPropertyResponse
     */
    'systemDescription': string;
    /**
     * 
     * @type {number}
     * @memberof ObjectPropertyResponse
     */
    'usedBy': number;
    /**
     * 
     * @type {boolean}
     * @memberof ObjectPropertyResponse
     */
    'isTotal': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ObjectPropertyResponse
     */
    'isAverage': boolean;
    /**
     * 
     * @type {string}
     * @memberof ObjectPropertyResponse
     */
    'weightedAveragePropertyId'?: string | null;
    /**
     * 
     * @type {WeightedPropertyResponse}
     * @memberof ObjectPropertyResponse
     */
    'weightedAverageProperty'?: WeightedPropertyResponse;
    /**
     * 
     * @type {number}
     * @memberof ObjectPropertyResponse
     */
    'minValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ObjectPropertyResponse
     */
    'maxValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectPropertyResponse
     */
    'suffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectPropertyResponse
     */
    'createdOn': string;
    /**
     * 
     * @type {Array<ObjectPropertyOptionResponse>}
     * @memberof ObjectPropertyResponse
     */
    'options': Array<ObjectPropertyOptionResponse>;
}
/**
 * 
 * @export
 * @interface ObjectTypeCreateRequest
 */
export interface ObjectTypeCreateRequest {
    /**
     * 
     * @type {ObjectTypeGroup}
     * @memberof ObjectTypeCreateRequest
     */
    'group': ObjectTypeGroup;
    /**
     * 
     * @type {Array<TextLanguageRequest>}
     * @memberof ObjectTypeCreateRequest
     */
    'name': Array<TextLanguageRequest>;
    /**
     * 
     * @type {Array<TextLanguageRequest>}
     * @memberof ObjectTypeCreateRequest
     */
    'description': Array<TextLanguageRequest>;
    /**
     * 
     * @type {Array<ObjectTypeLevelCreateRequest>}
     * @memberof ObjectTypeCreateRequest
     */
    'levels': Array<ObjectTypeLevelCreateRequest>;
}
/**
 * 
 * @export
 * @interface ObjectTypeDetailResponse
 */
export interface ObjectTypeDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof ObjectTypeDetailResponse
     */
    'objectTypeId': string;
    /**
     * 
     * @type {ObjectTypeGroup}
     * @memberof ObjectTypeDetailResponse
     */
    'group': ObjectTypeGroup;
    /**
     * 
     * @type {string}
     * @memberof ObjectTypeDetailResponse
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof ObjectTypeDetailResponse
     */
    'systemDescription': string;
    /**
     * 
     * @type {Array<TextLanguageResponse>}
     * @memberof ObjectTypeDetailResponse
     */
    'name': Array<TextLanguageResponse>;
    /**
     * 
     * @type {Array<TextLanguageResponse>}
     * @memberof ObjectTypeDetailResponse
     */
    'description': Array<TextLanguageResponse>;
    /**
     * 
     * @type {Array<ObjectTypeLevelResponse>}
     * @memberof ObjectTypeDetailResponse
     */
    'levels': Array<ObjectTypeLevelResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ObjectTypeGroup = {
    Unknown: 'Unknown',
    Eiendom: 'Eiendom',
    Park: 'Park',
    Vei: 'Vei',
    Annet: 'Annet',
    Internal: 'Internal'
} as const;

export type ObjectTypeGroup = typeof ObjectTypeGroup[keyof typeof ObjectTypeGroup];


/**
 * 
 * @export
 * @interface ObjectTypeLevelCreateRequest
 */
export interface ObjectTypeLevelCreateRequest {
    /**
     * 
     * @type {Array<TextLanguageRequest>}
     * @memberof ObjectTypeLevelCreateRequest
     */
    'name': Array<TextLanguageRequest>;
    /**
     * 
     * @type {Array<TextLanguageRequest>}
     * @memberof ObjectTypeLevelCreateRequest
     */
    'description': Array<TextLanguageRequest>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObjectTypeLevelCreateRequest
     */
    'properties': Array<string>;
}
/**
 * 
 * @export
 * @interface ObjectTypeLevelResponse
 */
export interface ObjectTypeLevelResponse {
    /**
     * 
     * @type {string}
     * @memberof ObjectTypeLevelResponse
     */
    'objectTypeId': string;
    /**
     * 
     * @type {number}
     * @memberof ObjectTypeLevelResponse
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof ObjectTypeLevelResponse
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof ObjectTypeLevelResponse
     */
    'systemDescription': string;
    /**
     * 
     * @type {Array<TextLanguageResponse>}
     * @memberof ObjectTypeLevelResponse
     */
    'name': Array<TextLanguageResponse>;
    /**
     * 
     * @type {Array<TextLanguageResponse>}
     * @memberof ObjectTypeLevelResponse
     */
    'description': Array<TextLanguageResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObjectTypeLevelResponse
     */
    'properties': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ObjectTypeLevelResponse
     */
    'usedBy': number;
}
/**
 * 
 * @export
 * @interface ObjectTypeLevelUpdateRequest
 */
export interface ObjectTypeLevelUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ObjectTypeLevelUpdateRequest
     */
    'objectTypeId': string;
    /**
     * 
     * @type {Array<TextLanguageRequest>}
     * @memberof ObjectTypeLevelUpdateRequest
     */
    'name': Array<TextLanguageRequest>;
    /**
     * 
     * @type {Array<TextLanguageRequest>}
     * @memberof ObjectTypeLevelUpdateRequest
     */
    'description': Array<TextLanguageRequest>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObjectTypeLevelUpdateRequest
     */
    'properties': Array<string>;
}
/**
 * 
 * @export
 * @interface ObjectTypeResponse
 */
export interface ObjectTypeResponse {
    /**
     * 
     * @type {string}
     * @memberof ObjectTypeResponse
     */
    'objectTypeId': string;
    /**
     * 
     * @type {ObjectTypeGroup}
     * @memberof ObjectTypeResponse
     */
    'group': ObjectTypeGroup;
    /**
     * 
     * @type {string}
     * @memberof ObjectTypeResponse
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof ObjectTypeResponse
     */
    'systemDescription': string;
    /**
     * 
     * @type {Array<TextLanguageResponse>}
     * @memberof ObjectTypeResponse
     */
    'name': Array<TextLanguageResponse>;
    /**
     * 
     * @type {Array<TextLanguageResponse>}
     * @memberof ObjectTypeResponse
     */
    'description': Array<TextLanguageResponse>;
}
/**
 * 
 * @export
 * @interface ObjectTypeUpdateRequest
 */
export interface ObjectTypeUpdateRequest {
    /**
     * 
     * @type {ObjectTypeGroup}
     * @memberof ObjectTypeUpdateRequest
     */
    'group': ObjectTypeGroup;
    /**
     * 
     * @type {Array<TextLanguageRequest>}
     * @memberof ObjectTypeUpdateRequest
     */
    'name': Array<TextLanguageRequest>;
    /**
     * 
     * @type {Array<TextLanguageRequest>}
     * @memberof ObjectTypeUpdateRequest
     */
    'description': Array<TextLanguageRequest>;
    /**
     * 
     * @type {Array<ObjectTypeLevelUpdateRequest>}
     * @memberof ObjectTypeUpdateRequest
     */
    'levels': Array<ObjectTypeLevelUpdateRequest>;
}
/**
 * 
 * @export
 * @interface ObjectsOverviewResponse
 */
export interface ObjectsOverviewResponse {
    /**
     * 
     * @type {string}
     * @memberof ObjectsOverviewResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof ObjectsOverviewResponse
     */
    'firstObjectName': string;
    /**
     * 
     * @type {number}
     * @memberof ObjectsOverviewResponse
     */
    'firstObjectAmount': number;
    /**
     * 
     * @type {string}
     * @memberof ObjectsOverviewResponse
     */
    'secondObjectName': string;
    /**
     * 
     * @type {number}
     * @memberof ObjectsOverviewResponse
     */
    'secondObjectAmount': number;
    /**
     * 
     * @type {string}
     * @memberof ObjectsOverviewResponse
     */
    'thirdObjectName': string;
    /**
     * 
     * @type {number}
     * @memberof ObjectsOverviewResponse
     */
    'thirdObjectAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ObjectsOverviewResponse
     */
    'areal': number;
    /**
     * 
     * @type {number}
     * @memberof ObjectsOverviewResponse
     */
    'year': number;
}
/**
 * 
 * @export
 * @interface OrganizationCreditInfoResponse
 */
export interface OrganizationCreditInfoResponse {
    /**
     * 
     * @type {BasicInformationResponse}
     * @memberof OrganizationCreditInfoResponse
     */
    'basicInformation'?: BasicInformationResponse;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreditInfoResponse
     */
    'sectorCode'?: string | null;
    /**
     * 
     * @type {Array<IndustryCodeResponse>}
     * @memberof OrganizationCreditInfoResponse
     */
    'industryCodes'?: Array<IndustryCodeResponse> | null;
    /**
     * 
     * @type {CreditScoreResponse}
     * @memberof OrganizationCreditInfoResponse
     */
    'creditScore'?: CreditScoreResponse;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreditInfoResponse
     */
    'statutoryPurpose'?: string | null;
}
/**
 * 
 * @export
 * @interface OrganizationDetailResponse
 */
export interface OrganizationDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailResponse
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailResponse
     */
    'organizationNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailResponse
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailResponse
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailResponse
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailResponse
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailResponse
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailResponse
     */
    'phone'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganizationDetailResponse
     */
    'userCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationDetailResponse
     */
    'pendingInvitationsCount'?: number;
    /**
     * 
     * @type {OrganizationType}
     * @memberof OrganizationDetailResponse
     */
    'organizationType'?: OrganizationType;
    /**
     * 
     * @type {Array<OrganizationServiceTypeResponse>}
     * @memberof OrganizationDetailResponse
     */
    'serviceTypes'?: Array<OrganizationServiceTypeResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailResponse
     */
    'contactPerson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailResponse
     */
    'invoiceReference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailResponse
     */
    'customerNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface OrganizationInfoResponse
 */
export interface OrganizationInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof OrganizationInfoResponse
     */
    'organizationNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInfoResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInfoResponse
     */
    'form'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInfoResponse
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInfoResponse
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInfoResponse
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInfoResponse
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationInfoResponse
     */
    'country'?: string | null;
}
/**
 * 
 * @export
 * @interface OrganizationMessageRequest
 */
export interface OrganizationMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationMessageRequest
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationMessageRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationMessageRequest
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationMessageRequest
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationMessageRequest
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationMessageRequest
     */
    'userEmail'?: string | null;
}
/**
 * 
 * @export
 * @interface OrganizationModuleResponse
 */
export interface OrganizationModuleResponse {
    /**
     * 
     * @type {string}
     * @memberof OrganizationModuleResponse
     */
    'organizationModuleId': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationModuleResponse
     */
    'organizationId': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationModuleResponse
     */
    'moduleId': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationModuleResponse
     */
    'objectTypeName': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationModuleResponse
     */
    'rootObjectTypeName': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationModuleResponse
     */
    'maconomyId': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationModuleResponse
     */
    'moduleName': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationModuleResponse
     */
    'moduleDescription': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationModuleResponse
     */
    'moduleObjectTypeId': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationModuleResponse
     */
    'moduleIcon': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationModuleResponse
     */
    'createdOn': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationModuleResponse
     */
    'invoiceDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationModuleResponse
     */
    'lastInvoiceDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganizationModuleResponse
     */
    'price': number;
}
/**
 * 
 * @export
 * @interface OrganizationReportResponse
 */
export interface OrganizationReportResponse {
    /**
     * 
     * @type {string}
     * @memberof OrganizationReportResponse
     */
    'organizationReportId': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationReportResponse
     */
    'organizationId': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationReportResponse
     */
    'reportId': string;
    /**
     * 
     * @type {ReportType}
     * @memberof OrganizationReportResponse
     */
    'reportTypeId': ReportType;
    /**
     * 
     * @type {string}
     * @memberof OrganizationReportResponse
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationReportResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationReportResponse
     */
    'readMore': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationReportResponse
     */
    'icon': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationReportResponse
     */
    'link': string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationReportResponse
     */
    'reportPrice': number;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationReportResponse
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @interface OrganizationResponse
 */
export interface OrganizationResponse {
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    'organizationNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    'phone'?: string | null;
    /**
     * 
     * @type {OrganizationType}
     * @memberof OrganizationResponse
     */
    'organizationType'?: OrganizationType;
    /**
     * 
     * @type {number}
     * @memberof OrganizationResponse
     */
    'userCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    'contactPerson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    'invoiceReference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    'customerNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface OrganizationSearchRequest
 */
export interface OrganizationSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationSearchRequest
     */
    'organizationNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface OrganizationServiceTypeResponse
 */
export interface OrganizationServiceTypeResponse {
    /**
     * 
     * @type {string}
     * @memberof OrganizationServiceTypeResponse
     */
    'organizationServiceTypeId': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationServiceTypeResponse
     */
    'organizationId': string;
    /**
     * 
     * @type {ServiceType}
     * @memberof OrganizationServiceTypeResponse
     */
    'serviceType': ServiceType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrganizationType = {
    Unknown: 'Unknown',
    Public: 'Public',
    Private: 'Private'
} as const;

export type OrganizationType = typeof OrganizationType[keyof typeof OrganizationType];


/**
 * 
 * @export
 * @interface OrganizationWorkspaceResponse
 */
export interface OrganizationWorkspaceResponse {
    /**
     * 
     * @type {string}
     * @memberof OrganizationWorkspaceResponse
     */
    'organizationId': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWorkspaceResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWorkspaceResponse
     */
    'workspaceName': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWorkspaceResponse
     */
    'objectTypeName': string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationWorkspaceResponse
     */
    'objectsAmount': number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWorkspaceResponse
     */
    'ownedBy': string;
}
/**
 * 
 * @export
 * @interface ParameterValue
 */
export interface ParameterValue {
    /**
     * 
     * @type {string}
     * @memberof ParameterValue
     */
    'mappingParameterValueId': string;
    /**
     * 
     * @type {string}
     * @memberof ParameterValue
     */
    'mappingParameterId': string;
    /**
     * 
     * @type {number}
     * @memberof ParameterValue
     */
    'value'?: number | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ReportObjectResponse
 */
export interface ReportObjectResponse {
    /**
     * 
     * @type {string}
     * @memberof ReportObjectResponse
     */
    'mappingObjectId': string;
    /**
     * 
     * @type {string}
     * @memberof ReportObjectResponse
     */
    'objectToMapId': string;
    /**
     * 
     * @type {string}
     * @memberof ReportObjectResponse
     */
    'objectName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportObjectResponse
     */
    'averageWTCD': number;
    /**
     * 
     * @type {number}
     * @memberof ReportObjectResponse
     */
    'areal': number;
    /**
     * 
     * @type {number}
     * @memberof ReportObjectResponse
     */
    'year': number;
}
/**
 * 
 * @export
 * @interface ReportOverviewResponse
 */
export interface ReportOverviewResponse {
    /**
     * 
     * @type {string}
     * @memberof ReportOverviewResponse
     */
    'workspaceModuleId': string;
    /**
     * 
     * @type {string}
     * @memberof ReportOverviewResponse
     */
    'moduleName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportOverviewResponse
     */
    'objectTypeName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportOverviewResponse
     */
    'collectedCD': number;
    /**
     * 
     * @type {number}
     * @memberof ReportOverviewResponse
     */
    'mappedPercentage': number;
    /**
     * 
     * @type {number}
     * @memberof ReportOverviewResponse
     */
    'mappingObjectsAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ReportOverviewResponse
     */
    'mappingObjectsAmountDone': number;
    /**
     * 
     * @type {Array<ReportObjectResponse>}
     * @memberof ReportOverviewResponse
     */
    'reportObjects': Array<ReportObjectResponse>;
    /**
     * 
     * @type {string}
     * @memberof ReportOverviewResponse
     */
    'lastMappingPeriodId': string;
    /**
     * 
     * @type {string}
     * @memberof ReportOverviewResponse
     */
    'periodDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportOverviewResponse
     */
    'periodName': string;
}
/**
 * 
 * @export
 * @interface ReportResponse
 */
export interface ReportResponse {
    /**
     * 
     * @type {string}
     * @memberof ReportResponse
     */
    'reportId': string;
    /**
     * 
     * @type {string}
     * @memberof ReportResponse
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportResponse
     */
    'systemDescription': string;
    /**
     * 
     * @type {string}
     * @memberof ReportResponse
     */
    'readMore': string;
    /**
     * 
     * @type {string}
     * @memberof ReportResponse
     */
    'orderText': string;
    /**
     * 
     * @type {string}
     * @memberof ReportResponse
     */
    'icon': string;
    /**
     * 
     * @type {ReportType}
     * @memberof ReportResponse
     */
    'reportTypeId': ReportType;
    /**
     * 
     * @type {number}
     * @memberof ReportResponse
     */
    'reportPrice': number;
    /**
     * 
     * @type {string}
     * @memberof ReportResponse
     */
    'objectTypeId': string;
    /**
     * 
     * @type {string}
     * @memberof ReportResponse
     */
    'objectTypeName': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReportType = {
    PowerBi: 'PowerBI',
    Pdf: 'PDF'
} as const;

export type ReportType = typeof ReportType[keyof typeof ReportType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ServiceType = {
    Planning: 'Planning'
} as const;

export type ServiceType = typeof ServiceType[keyof typeof ServiceType];


/**
 * 
 * @export
 * @interface TextLanguageRequest
 */
export interface TextLanguageRequest {
    /**
     * 
     * @type {Language}
     * @memberof TextLanguageRequest
     */
    'language': Language;
    /**
     * 
     * @type {string}
     * @memberof TextLanguageRequest
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface TextLanguageResponse
 */
export interface TextLanguageResponse {
    /**
     * 
     * @type {Language}
     * @memberof TextLanguageResponse
     */
    'language': Language;
    /**
     * 
     * @type {string}
     * @memberof TextLanguageResponse
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface UpdateCustomerAccessRequest
 */
export interface UpdateCustomerAccessRequest {
    /**
     * 
     * @type {CustomerAccessRequestStatus}
     * @memberof UpdateCustomerAccessRequest
     */
    'status'?: CustomerAccessRequestStatus;
}
/**
 * 
 * @export
 * @interface UpdateLearningRequest
 */
export interface UpdateLearningRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateLearningRequest
     */
    'learningId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLearningRequest
     */
    'mappingSetId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLearningRequest
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateLearningRequest
     */
    'videoPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLearningRequest
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateMaintenancePlanMeasureRequest
 */
export interface UpdateMaintenancePlanMeasureRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateMaintenancePlanMeasureRequest
     */
    'maintenancePlanMeasureId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMaintenancePlanMeasureRequest
     */
    'maintenancePlanId'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateMaintenancePlanMeasureRequest
     */
    'startYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateMaintenancePlanMeasureRequest
     */
    'budgetYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateMaintenancePlanMeasureRequest
     */
    'priceEstimate'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateMaintenancePlanMeasureRequest
     */
    'priceCalculate'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateMaintenancePlanMeasureRequest
     */
    'finishYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateMaintenancePlanMeasureRequest
     */
    'priceFinish'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateMaintenancePlanParameterValueBaseRequest
 */
export interface UpdateMaintenancePlanParameterValueBaseRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateMaintenancePlanParameterValueBaseRequest
     */
    'maintenancePlanParameterValueBaseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMaintenancePlanParameterValueBaseRequest
     */
    'maintenancePlanId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMaintenancePlanParameterValueBaseRequest
     */
    'mappingParameterValueId'?: string;
}
/**
 * 
 * @export
 * @interface UpdateMaintenancePlanRequest
 */
export interface UpdateMaintenancePlanRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateMaintenancePlanRequest
     */
    'maintenancePlanId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMaintenancePlanRequest
     */
    'mappingObjectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMaintenancePlanRequest
     */
    'maintenancePlanName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateMaintenancePlanRequest
     */
    'maintenancePlanNumber'?: number;
    /**
     * 
     * @type {MaintenancePlanType}
     * @memberof UpdateMaintenancePlanRequest
     */
    'maintenancePlanTypeId'?: MaintenancePlanType;
    /**
     * 
     * @type {number}
     * @memberof UpdateMaintenancePlanRequest
     */
    'consequenceDegree'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateMaintenancePlanRequest
     */
    'priority'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateMaintenancePlanRequest
     */
    'maintenancePlanDescription'?: string | null;
    /**
     * 
     * @type {Array<UpdateMaintenancePlanMeasureRequest>}
     * @memberof UpdateMaintenancePlanRequest
     */
    'maintenancePlanMeasures'?: Array<UpdateMaintenancePlanMeasureRequest> | null;
    /**
     * 
     * @type {Array<UpdateMaintenancePlanParameterValueBaseRequest>}
     * @memberof UpdateMaintenancePlanRequest
     */
    'maintenancePlanParameterValueBases'?: Array<UpdateMaintenancePlanParameterValueBaseRequest> | null;
}
/**
 * 
 * @export
 * @interface UpdateMappingSetRequest
 */
export interface UpdateMappingSetRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateMappingSetRequest
     */
    'systemName'?: string | null;
    /**
     * 
     * @type {Array<CreateMappingGroupRequest>}
     * @memberof UpdateMappingSetRequest
     */
    'mappingGroups'?: Array<CreateMappingGroupRequest> | null;
}
/**
 * 
 * @export
 * @interface UpdateModuleConditionDegreeDescriptionRequest
 */
export interface UpdateModuleConditionDegreeDescriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleConditionDegreeDescriptionRequest
     */
    'moduleConditionDegreeDescriptionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateModuleConditionDegreeDescriptionRequest
     */
    'conditionDegree'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleConditionDegreeDescriptionRequest
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateModuleMappingGroupDescriptionRequest
 */
export interface UpdateModuleMappingGroupDescriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleMappingGroupDescriptionRequest
     */
    'mappingGroupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleMappingGroupDescriptionRequest
     */
    'mappingGroupName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateModuleMappingGroupDescriptionRequest
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {Array<UpdateModuleParameterDescriptionRequest>}
     * @memberof UpdateModuleMappingGroupDescriptionRequest
     */
    'parameters'?: Array<UpdateModuleParameterDescriptionRequest> | null;
}
/**
 * 
 * @export
 * @interface UpdateModuleParameterDescriptionRequest
 */
export interface UpdateModuleParameterDescriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleParameterDescriptionRequest
     */
    'moduleParameterDescriptionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleParameterDescriptionRequest
     */
    'parameterName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateModuleParameterDescriptionRequest
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleParameterDescriptionRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<UpdateModuleConditionDegreeDescriptionRequest>}
     * @memberof UpdateModuleParameterDescriptionRequest
     */
    'conditionDegrees'?: Array<UpdateModuleConditionDegreeDescriptionRequest> | null;
}
/**
 * 
 * @export
 * @interface UpdateModuleRequest
 */
export interface UpdateModuleRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleRequest
     */
    'moduleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleRequest
     */
    'objectTypeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleRequest
     */
    'mappingSetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleRequest
     */
    'objectPropertyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleRequest
     */
    'systemName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleRequest
     */
    'systemDescription'?: string | null;
    /**
     * 
     * @type {MappingModel}
     * @memberof UpdateModuleRequest
     */
    'mappingModelId'?: MappingModel;
    /**
     * 
     * @type {number}
     * @memberof UpdateModuleRequest
     */
    'mapLowValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateModuleRequest
     */
    'mapHighValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateModuleRequest
     */
    'usePercentValues'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateModuleRequest
     */
    'useObjectPropertyOptionSet'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleRequest
     */
    'icon'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateModuleRequest
     */
    'customValues'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleRequest
     */
    'maconomyId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateModuleRequest
     */
    'modulePrice'?: number;
}
/**
 * 
 * @export
 * @interface UpdateModuleWeightingFactorRequest
 */
export interface UpdateModuleWeightingFactorRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleWeightingFactorRequest
     */
    'moduleWeightingFactorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleWeightingFactorRequest
     */
    'parameterName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateModuleWeightingFactorRequest
     */
    'weighting'?: number;
}
/**
 * 
 * @export
 * @interface UpdateModuleWeightingGroupRequest
 */
export interface UpdateModuleWeightingGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleWeightingGroupRequest
     */
    'mappingGroupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleWeightingGroupRequest
     */
    'mappingGroupName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateModuleWeightingGroupRequest
     */
    'totalWeighting'?: number;
    /**
     * 
     * @type {Array<UpdateModuleWeightingFactorRequest>}
     * @memberof UpdateModuleWeightingGroupRequest
     */
    'factors'?: Array<UpdateModuleWeightingFactorRequest> | null;
}
/**
 * 
 * @export
 * @interface UpdateModuleWeightingOptionRequest
 */
export interface UpdateModuleWeightingOptionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleWeightingOptionRequest
     */
    'objectPropertyOptionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleWeightingOptionRequest
     */
    'optionName'?: string | null;
    /**
     * 
     * @type {Array<UpdateModuleWeightingGroupRequest>}
     * @memberof UpdateModuleWeightingOptionRequest
     */
    'groups'?: Array<UpdateModuleWeightingGroupRequest> | null;
}
/**
 * 
 * @export
 * @interface UpdateModuleWeightingVersionRequest
 */
export interface UpdateModuleWeightingVersionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleWeightingVersionRequest
     */
    'moduleWeightingVersionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModuleWeightingVersionRequest
     */
    'versionName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateModuleWeightingVersionRequest
     */
    'active'?: boolean;
    /**
     * 
     * @type {Array<UpdateModuleWeightingOptionRequest>}
     * @memberof UpdateModuleWeightingVersionRequest
     */
    'options'?: Array<UpdateModuleWeightingOptionRequest> | null;
}
/**
 * 
 * @export
 * @interface UpdateObjectPropertyOptionRequest
 */
export interface UpdateObjectPropertyOptionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateObjectPropertyOptionRequest
     */
    'objectPropertyOptionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateObjectPropertyOptionRequest
     */
    'objectPropertyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateObjectPropertyOptionRequest
     */
    'systemName'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateObjectPropertyRequest
 */
export interface UpdateObjectPropertyRequest {
    /**
     * 
     * @type {ObjectPropertyDatatype}
     * @memberof UpdateObjectPropertyRequest
     */
    'datatypeId'?: ObjectPropertyDatatype;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateObjectPropertyRequest
     */
    'required'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateObjectPropertyRequest
     */
    'systemName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateObjectPropertyRequest
     */
    'systemDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateObjectPropertyRequest
     */
    'isTotal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateObjectPropertyRequest
     */
    'isAverage'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateObjectPropertyRequest
     */
    'weightedAveragePropertyId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateObjectPropertyRequest
     */
    'minValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateObjectPropertyRequest
     */
    'maxValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateObjectPropertyRequest
     */
    'suffix'?: string | null;
    /**
     * 
     * @type {Array<UpdateObjectPropertyOptionRequest>}
     * @memberof UpdateObjectPropertyRequest
     */
    'options'?: Array<UpdateObjectPropertyOptionRequest> | null;
}
/**
 * 
 * @export
 * @interface UpdateOrganizationModuleRequest
 */
export interface UpdateOrganizationModuleRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationModuleRequest
     */
    'organizationModuleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationModuleRequest
     */
    'invoiceDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrganizationModuleRequest
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @interface UpdateOrganizationRequest
 */
export interface UpdateOrganizationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationRequest
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationRequest
     */
    'contactPerson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationRequest
     */
    'invoiceReference'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdatePreferredLanguageRequest
 */
export interface UpdatePreferredLanguageRequest {
    /**
     * 
     * @type {Language}
     * @memberof UpdatePreferredLanguageRequest
     */
    'preferredLanguage': Language;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequest
     */
    'isOrganizationAdministrator'?: boolean;
}
/**
 * 
 * @export
 * @interface UserInfoResponse
 */
export interface UserInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponse
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponse
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponse
     */
    'lastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponse
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {Language}
     * @memberof UserInfoResponse
     */
    'preferredLanguage'?: Language;
    /**
     * 
     * @type {OrganizationResponse}
     * @memberof UserInfoResponse
     */
    'organization'?: OrganizationResponse;
    /**
     * 
     * @type {Array<AccessControl>}
     * @memberof UserInfoResponse
     */
    'acl'?: Array<AccessControl> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfoResponse
     */
    'isSystemAdministrator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfoResponse
     */
    'isOrganizationAdministrator'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponse
     */
    'createdOn'?: string;
}
/**
 * 
 * @export
 * @interface UserInvitationResponse
 */
export interface UserInvitationResponse {
    /**
     * 
     * @type {string}
     * @memberof UserInvitationResponse
     */
    'userInvitationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationResponse
     */
    'createdByUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationResponse
     */
    'redeemedByUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationResponse
     */
    'userEmail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserInvitationResponse
     */
    'assignOrgAdmin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserInvitationResponse
     */
    'assignSysAdmin'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationResponse
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationResponse
     */
    'redeemedOn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationResponse
     */
    'expiresOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationResponse
     */
    'createdByUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationResponse
     */
    'redeemedByUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationResponse
     */
    'invitedByOrganizationName'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserInvitationStatus = {
    Unknown: 'Unknown',
    Pending: 'Pending',
    Redeemed: 'Redeemed',
    Expired: 'Expired'
} as const;

export type UserInvitationStatus = typeof UserInvitationStatus[keyof typeof UserInvitationStatus];


/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'organizationId': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'createdOn': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'isOrganizationAdministrator': boolean;
}
/**
 * 
 * @export
 * @interface ValidationProblemDetails
 */
export interface ValidationProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'instance'?: string | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ValidationProblemDetails
     */
    'errors'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationProblemDetails
     */
    'genericErrors'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof ValidationProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface WeightedPropertyResponse
 */
export interface WeightedPropertyResponse {
    /**
     * 
     * @type {string}
     * @memberof WeightedPropertyResponse
     */
    'systemName': string;
}
/**
 * 
 * @export
 * @interface WorkspaceCreateRequest
 */
export interface WorkspaceCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceCreateRequest
     */
    'objectTypeId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceCreateRequest
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceCreateRequest
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceCreateRequest
     */
    'systemDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkspaceDetailLevelPropertyResponse
 */
export interface WorkspaceDetailLevelPropertyResponse {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailLevelPropertyResponse
     */
    'objectPropertyId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailLevelPropertyResponse
     */
    'valueString'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceDetailLevelPropertyResponse
     */
    'valueInteger'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceDetailLevelPropertyResponse
     */
    'valueDecimal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailLevelPropertyResponse
     */
    'valueOptionReferenceId'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkspaceDetailLevelPropertyUpdateRequest
 */
export interface WorkspaceDetailLevelPropertyUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailLevelPropertyUpdateRequest
     */
    'objectPropertyId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailLevelPropertyUpdateRequest
     */
    'valueString'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceDetailLevelPropertyUpdateRequest
     */
    'valueInteger'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceDetailLevelPropertyUpdateRequest
     */
    'valueDecimal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailLevelPropertyUpdateRequest
     */
    'valueOptionReferenceId'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkspaceDetailLevelResponse
 */
export interface WorkspaceDetailLevelResponse {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailLevelResponse
     */
    'objectId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailLevelResponse
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceDetailLevelResponse
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailLevelResponse
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailLevelResponse
     */
    'systemDescription': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailLevelResponse
     */
    'deactivatedOn'?: string | null;
    /**
     * 
     * @type {Array<WorkspaceDetailLevelPropertyResponse>}
     * @memberof WorkspaceDetailLevelResponse
     */
    'properties': Array<WorkspaceDetailLevelPropertyResponse>;
}
/**
 * 
 * @export
 * @interface WorkspaceDetailLevelUpdateRequest
 */
export interface WorkspaceDetailLevelUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailLevelUpdateRequest
     */
    'objectId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailLevelUpdateRequest
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceDetailLevelUpdateRequest
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailLevelUpdateRequest
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailLevelUpdateRequest
     */
    'systemDescription': string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceDetailLevelUpdateRequest
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {Array<WorkspaceDetailLevelPropertyUpdateRequest>}
     * @memberof WorkspaceDetailLevelUpdateRequest
     */
    'properties': Array<WorkspaceDetailLevelPropertyUpdateRequest>;
}
/**
 * 
 * @export
 * @interface WorkspaceDetailObjectTypeResponse
 */
export interface WorkspaceDetailObjectTypeResponse {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailObjectTypeResponse
     */
    'objectTypeId': string;
    /**
     * 
     * @type {ObjectTypeGroup}
     * @memberof WorkspaceDetailObjectTypeResponse
     */
    'group': ObjectTypeGroup;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailObjectTypeResponse
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailObjectTypeResponse
     */
    'systemDescription': string;
    /**
     * 
     * @type {Array<TextLanguageResponse>}
     * @memberof WorkspaceDetailObjectTypeResponse
     */
    'name': Array<TextLanguageResponse>;
    /**
     * 
     * @type {Array<TextLanguageResponse>}
     * @memberof WorkspaceDetailObjectTypeResponse
     */
    'description': Array<TextLanguageResponse>;
}
/**
 * 
 * @export
 * @interface WorkspaceDetailResponse
 */
export interface WorkspaceDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailResponse
     */
    'objectId': string;
    /**
     * 
     * @type {WorkspaceDetailObjectTypeResponse}
     * @memberof WorkspaceDetailResponse
     */
    'objectType': WorkspaceDetailObjectTypeResponse;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailResponse
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailResponse
     */
    'systemDescription': string;
    /**
     * 
     * @type {Array<WorkspaceDetailLevelResponse>}
     * @memberof WorkspaceDetailResponse
     */
    'levels': Array<WorkspaceDetailLevelResponse>;
}
/**
 * 
 * @export
 * @interface WorkspaceDetailUpdateRequest
 */
export interface WorkspaceDetailUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailUpdateRequest
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDetailUpdateRequest
     */
    'systemDescription': string;
    /**
     * 
     * @type {Array<WorkspaceDetailLevelUpdateRequest>}
     * @memberof WorkspaceDetailUpdateRequest
     */
    'levels': Array<WorkspaceDetailLevelUpdateRequest>;
}
/**
 * 
 * @export
 * @interface WorkspaceImportVerifyDetailResponse
 */
export interface WorkspaceImportVerifyDetailResponse {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceImportVerifyDetailResponse
     */
    'rowIndex'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceImportVerifyDetailResponse
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceImportVerifyDetailResponse
     */
    'newValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceImportVerifyDetailResponse
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkspaceImportVerifyResponse
 */
export interface WorkspaceImportVerifyResponse {
    /**
     * 
     * @type {Array<WorkspaceImportVerifyDetailResponse>}
     * @memberof WorkspaceImportVerifyResponse
     */
    'errors': Array<WorkspaceImportVerifyDetailResponse>;
    /**
     * 
     * @type {Array<WorkspaceImportVerifyRowResponse>}
     * @memberof WorkspaceImportVerifyResponse
     */
    'rows': Array<WorkspaceImportVerifyRowResponse>;
}
/**
 * 
 * @export
 * @interface WorkspaceImportVerifyRowPropertyResponse
 */
export interface WorkspaceImportVerifyRowPropertyResponse {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceImportVerifyRowPropertyResponse
     */
    'objectPropertyId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceImportVerifyRowPropertyResponse
     */
    'valueString'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceImportVerifyRowPropertyResponse
     */
    'valueInteger'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceImportVerifyRowPropertyResponse
     */
    'valueDecimal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceImportVerifyRowPropertyResponse
     */
    'valueOptionReferenceId'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkspaceImportVerifyRowResponse
 */
export interface WorkspaceImportVerifyRowResponse {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceImportVerifyRowResponse
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceImportVerifyRowResponse
     */
    'objectId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceImportVerifyRowResponse
     */
    'objectName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceImportVerifyRowResponse
     */
    'parentObjectId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceImportVerifyRowResponse
     */
    'parentObjectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceImportVerifyRowResponse
     */
    'objectTypeName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceImportVerifyRowResponse
     */
    'objectDescription': string;
    /**
     * 
     * @type {Array<WorkspaceImportVerifyRowPropertyResponse>}
     * @memberof WorkspaceImportVerifyRowResponse
     */
    'properties': Array<WorkspaceImportVerifyRowPropertyResponse>;
}
/**
 * 
 * @export
 * @interface WorkspaceModuleResponse
 */
export interface WorkspaceModuleResponse {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceModuleResponse
     */
    'workspaceModuleId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceModuleResponse
     */
    'moduleId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceModuleResponse
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceModuleResponse
     */
    'systemDescription': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceModuleResponse
     */
    'icon': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceModuleResponse
     */
    'lastMappingDate': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceModuleResponse
     */
    'lastMappingPeriod': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceModuleResponse
     */
    'lastMappingPeriodId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceModuleResponse
     */
    'mappingSetId': string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceModuleResponse
     */
    'hasPreviousMapping': boolean;
}
/**
 * 
 * @export
 * @interface WorkspaceObjectTypeResponse
 */
export interface WorkspaceObjectTypeResponse {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceObjectTypeResponse
     */
    'objectTypeId': string;
    /**
     * 
     * @type {ObjectTypeGroup}
     * @memberof WorkspaceObjectTypeResponse
     */
    'group': ObjectTypeGroup;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceObjectTypeResponse
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceObjectTypeResponse
     */
    'systemDescription': string;
    /**
     * 
     * @type {Array<TextLanguageResponse>}
     * @memberof WorkspaceObjectTypeResponse
     */
    'name': Array<TextLanguageResponse>;
    /**
     * 
     * @type {Array<TextLanguageResponse>}
     * @memberof WorkspaceObjectTypeResponse
     */
    'description': Array<TextLanguageResponse>;
}
/**
 * 
 * @export
 * @interface WorkspaceResponse
 */
export interface WorkspaceResponse {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceResponse
     */
    'workspaceId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceResponse
     */
    'systemName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceResponse
     */
    'systemDescription': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceResponse
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceResponse
     */
    'organizationName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceResponse
     */
    'createdOn': string;
    /**
     * 
     * @type {WorkspaceObjectTypeResponse}
     * @memberof WorkspaceResponse
     */
    'objectType': WorkspaceObjectTypeResponse;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceResponse
     */
    'objectsCount': number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceResponse
     */
    'usersCount': number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceResponse
     */
    'lastMapping': string;
}

/**
 * CustomerAccessRequestsApi - axios parameter creator
 * @export
 */
export const CustomerAccessRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {CustomerAccessRequestStatus} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAccessRequestsGet: async (filter?: CustomerAccessRequestStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer-access-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {UpdateCustomerAccessRequest} [updateCustomerAccessRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAccessRequestsIdPut: async (id: string, updateCustomerAccessRequest?: UpdateCustomerAccessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('customerAccessRequestsIdPut', 'id', id)
            const localVarPath = `/customer-access-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerAccessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {CreateCustomerAccessRequest} [createCustomerAccessRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAccessRequestsPost: async (createCustomerAccessRequest?: CreateCustomerAccessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer-access-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomerAccessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdRoute: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getByIdRoute', 'id', id)
            const localVarPath = `/customer-access-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByUserIdRoute: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getByUserIdRoute', 'userId', userId)
            const localVarPath = `/customer-access-requests/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerAccessRequestsApi - functional programming interface
 * @export
 */
export const CustomerAccessRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerAccessRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {CustomerAccessRequestStatus} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAccessRequestsGet(filter?: CustomerAccessRequestStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerAccessRequestResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAccessRequestsGet(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {UpdateCustomerAccessRequest} [updateCustomerAccessRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAccessRequestsIdPut(id: string, updateCustomerAccessRequest?: UpdateCustomerAccessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerAccessRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAccessRequestsIdPut(id, updateCustomerAccessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {CreateCustomerAccessRequest} [createCustomerAccessRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAccessRequestsPost(createCustomerAccessRequest?: CreateCustomerAccessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerAccessRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAccessRequestsPost(createCustomerAccessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIdRoute(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerAccessRequestDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIdRoute(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByUserIdRoute(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerAccessRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByUserIdRoute(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerAccessRequestsApi - factory interface
 * @export
 */
export const CustomerAccessRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerAccessRequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {CustomerAccessRequestStatus} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAccessRequestsGet(filter?: CustomerAccessRequestStatus, options?: any): AxiosPromise<Array<CustomerAccessRequestResponse>> {
            return localVarFp.customerAccessRequestsGet(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {UpdateCustomerAccessRequest} [updateCustomerAccessRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAccessRequestsIdPut(id: string, updateCustomerAccessRequest?: UpdateCustomerAccessRequest, options?: any): AxiosPromise<CustomerAccessRequestResponse> {
            return localVarFp.customerAccessRequestsIdPut(id, updateCustomerAccessRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {CreateCustomerAccessRequest} [createCustomerAccessRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAccessRequestsPost(createCustomerAccessRequest?: CreateCustomerAccessRequest, options?: any): AxiosPromise<CustomerAccessRequestResponse> {
            return localVarFp.customerAccessRequestsPost(createCustomerAccessRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdRoute(id: string, options?: any): AxiosPromise<CustomerAccessRequestDetailResponse> {
            return localVarFp.getByIdRoute(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByUserIdRoute(userId: string, options?: any): AxiosPromise<CustomerAccessRequestResponse> {
            return localVarFp.getByUserIdRoute(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerAccessRequestsApi - object-oriented interface
 * @export
 * @class CustomerAccessRequestsApi
 * @extends {BaseAPI}
 */
export class CustomerAccessRequestsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {CustomerAccessRequestStatus} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccessRequestsApi
     */
    public customerAccessRequestsGet(filter?: CustomerAccessRequestStatus, options?: AxiosRequestConfig) {
        return CustomerAccessRequestsApiFp(this.configuration).customerAccessRequestsGet(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {string} id 
     * @param {UpdateCustomerAccessRequest} [updateCustomerAccessRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccessRequestsApi
     */
    public customerAccessRequestsIdPut(id: string, updateCustomerAccessRequest?: UpdateCustomerAccessRequest, options?: AxiosRequestConfig) {
        return CustomerAccessRequestsApiFp(this.configuration).customerAccessRequestsIdPut(id, updateCustomerAccessRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {CreateCustomerAccessRequest} [createCustomerAccessRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccessRequestsApi
     */
    public customerAccessRequestsPost(createCustomerAccessRequest?: CreateCustomerAccessRequest, options?: AxiosRequestConfig) {
        return CustomerAccessRequestsApiFp(this.configuration).customerAccessRequestsPost(createCustomerAccessRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccessRequestsApi
     */
    public getByIdRoute(id: string, options?: AxiosRequestConfig) {
        return CustomerAccessRequestsApiFp(this.configuration).getByIdRoute(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccessRequestsApi
     */
    public getByUserIdRoute(userId: string, options?: AxiosRequestConfig) {
        return CustomerAccessRequestsApiFp(this.configuration).getByUserIdRoute(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HomeApi - axios parameter creator
 * @export
 */
export const HomeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeMaintenancePlansOverviewWorkspaceIdGet: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('homeMaintenancePlansOverviewWorkspaceIdGet', 'workspaceId', workspaceId)
            const localVarPath = `/home/maintenance-plans-overview/{workspaceId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeObjectsOverviewWorkspaceIdGet: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('homeObjectsOverviewWorkspaceIdGet', 'workspaceId', workspaceId)
            const localVarPath = `/home/objects-overview/{workspaceId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeReportsOverviewWorkspaceModuleIdGet: async (workspaceModuleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceModuleId' is not null or undefined
            assertParamExists('homeReportsOverviewWorkspaceModuleIdGet', 'workspaceModuleId', workspaceModuleId)
            const localVarPath = `/home/reports-overview/{workspaceModuleId}`
                .replace(`{${"workspaceModuleId"}}`, encodeURIComponent(String(workspaceModuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HomeApi - functional programming interface
 * @export
 */
export const HomeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HomeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async homeMaintenancePlansOverviewWorkspaceIdGet(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintenancePlanResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.homeMaintenancePlansOverviewWorkspaceIdGet(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async homeObjectsOverviewWorkspaceIdGet(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectsOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.homeObjectsOverviewWorkspaceIdGet(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async homeReportsOverviewWorkspaceModuleIdGet(workspaceModuleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.homeReportsOverviewWorkspaceModuleIdGet(workspaceModuleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HomeApi - factory interface
 * @export
 */
export const HomeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HomeApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeMaintenancePlansOverviewWorkspaceIdGet(workspaceId: string, options?: any): AxiosPromise<Array<MaintenancePlanResponse>> {
            return localVarFp.homeMaintenancePlansOverviewWorkspaceIdGet(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeObjectsOverviewWorkspaceIdGet(workspaceId: string, options?: any): AxiosPromise<ObjectsOverviewResponse> {
            return localVarFp.homeObjectsOverviewWorkspaceIdGet(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeReportsOverviewWorkspaceModuleIdGet(workspaceModuleId: string, options?: any): AxiosPromise<ReportOverviewResponse> {
            return localVarFp.homeReportsOverviewWorkspaceModuleIdGet(workspaceModuleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HomeApi - object-oriented interface
 * @export
 * @class HomeApi
 * @extends {BaseAPI}
 */
export class HomeApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public homeMaintenancePlansOverviewWorkspaceIdGet(workspaceId: string, options?: AxiosRequestConfig) {
        return HomeApiFp(this.configuration).homeMaintenancePlansOverviewWorkspaceIdGet(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public homeObjectsOverviewWorkspaceIdGet(workspaceId: string, options?: AxiosRequestConfig) {
        return HomeApiFp(this.configuration).homeObjectsOverviewWorkspaceIdGet(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} workspaceModuleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public homeReportsOverviewWorkspaceModuleIdGet(workspaceModuleId: string, options?: AxiosRequestConfig) {
        return HomeApiFp(this.configuration).homeReportsOverviewWorkspaceModuleIdGet(workspaceModuleId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MaintenancePlansApi - axios parameter creator
 * @export
 */
export const MaintenancePlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} maintenancePlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePlansMaintenancePlanIdDelete: async (maintenancePlanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'maintenancePlanId' is not null or undefined
            assertParamExists('maintenancePlansMaintenancePlanIdDelete', 'maintenancePlanId', maintenancePlanId)
            const localVarPath = `/maintenance-plans/{maintenancePlanId}`
                .replace(`{${"maintenancePlanId"}}`, encodeURIComponent(String(maintenancePlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} maintenancePlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePlansMaintenancePlanIdGet: async (maintenancePlanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'maintenancePlanId' is not null or undefined
            assertParamExists('maintenancePlansMaintenancePlanIdGet', 'maintenancePlanId', maintenancePlanId)
            const localVarPath = `/maintenance-plans/{maintenancePlanId}`
                .replace(`{${"maintenancePlanId"}}`, encodeURIComponent(String(maintenancePlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} maintenancePlanId 
         * @param {UpdateMaintenancePlanRequest} [updateMaintenancePlanRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePlansMaintenancePlanIdPut: async (maintenancePlanId: string, updateMaintenancePlanRequest?: UpdateMaintenancePlanRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'maintenancePlanId' is not null or undefined
            assertParamExists('maintenancePlansMaintenancePlanIdPut', 'maintenancePlanId', maintenancePlanId)
            const localVarPath = `/maintenance-plans/{maintenancePlanId}`
                .replace(`{${"maintenancePlanId"}}`, encodeURIComponent(String(maintenancePlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMaintenancePlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {CreateMaintenancePlanRequest} [createMaintenancePlanRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePlansPost: async (createMaintenancePlanRequest?: CreateMaintenancePlanRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/maintenance-plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMaintenancePlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePlansWorkspaceModuleIdExportGet: async (workspaceModuleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceModuleId' is not null or undefined
            assertParamExists('maintenancePlansWorkspaceModuleIdExportGet', 'workspaceModuleId', workspaceModuleId)
            const localVarPath = `/maintenance-plans/{workspaceModuleId}/export`
                .replace(`{${"workspaceModuleId"}}`, encodeURIComponent(String(workspaceModuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceModuleId 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePlansWorkspaceModuleIdImportPost: async (workspaceModuleId: string, files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceModuleId' is not null or undefined
            assertParamExists('maintenancePlansWorkspaceModuleIdImportPost', 'workspaceModuleId', workspaceModuleId)
            const localVarPath = `/maintenance-plans/{workspaceModuleId}/import`
                .replace(`{${"workspaceModuleId"}}`, encodeURIComponent(String(workspaceModuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePlansWorkspaceModuleWorkspaceModuleIdDetailsGet: async (workspaceModuleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceModuleId' is not null or undefined
            assertParamExists('maintenancePlansWorkspaceModuleWorkspaceModuleIdDetailsGet', 'workspaceModuleId', workspaceModuleId)
            const localVarPath = `/maintenance-plans/workspace-module/{workspaceModuleId}/details`
                .replace(`{${"workspaceModuleId"}}`, encodeURIComponent(String(workspaceModuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePlansWorkspaceModuleWorkspaceModuleIdGet: async (workspaceModuleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceModuleId' is not null or undefined
            assertParamExists('maintenancePlansWorkspaceModuleWorkspaceModuleIdGet', 'workspaceModuleId', workspaceModuleId)
            const localVarPath = `/maintenance-plans/workspace-module/{workspaceModuleId}`
                .replace(`{${"workspaceModuleId"}}`, encodeURIComponent(String(workspaceModuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaintenancePlansApi - functional programming interface
 * @export
 */
export const MaintenancePlansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaintenancePlansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} maintenancePlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenancePlansMaintenancePlanIdDelete(maintenancePlanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenancePlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenancePlansMaintenancePlanIdDelete(maintenancePlanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} maintenancePlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenancePlansMaintenancePlanIdGet(maintenancePlanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenancePlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenancePlansMaintenancePlanIdGet(maintenancePlanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} maintenancePlanId 
         * @param {UpdateMaintenancePlanRequest} [updateMaintenancePlanRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenancePlansMaintenancePlanIdPut(maintenancePlanId: string, updateMaintenancePlanRequest?: UpdateMaintenancePlanRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenancePlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenancePlansMaintenancePlanIdPut(maintenancePlanId, updateMaintenancePlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {CreateMaintenancePlanRequest} [createMaintenancePlanRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenancePlansPost(createMaintenancePlanRequest?: CreateMaintenancePlanRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenancePlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenancePlansPost(createMaintenancePlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenancePlansWorkspaceModuleIdExportGet(workspaceModuleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenancePlansWorkspaceModuleIdExportGet(workspaceModuleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceModuleId 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenancePlansWorkspaceModuleIdImportPost(workspaceModuleId: string, files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenancePlansWorkspaceModuleIdImportPost(workspaceModuleId, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenancePlansWorkspaceModuleWorkspaceModuleIdDetailsGet(workspaceModuleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintenancePlanParameterDetailsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenancePlansWorkspaceModuleWorkspaceModuleIdDetailsGet(workspaceModuleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenancePlansWorkspaceModuleWorkspaceModuleIdGet(workspaceModuleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintenancePlanResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenancePlansWorkspaceModuleWorkspaceModuleIdGet(workspaceModuleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MaintenancePlansApi - factory interface
 * @export
 */
export const MaintenancePlansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaintenancePlansApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} maintenancePlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePlansMaintenancePlanIdDelete(maintenancePlanId: string, options?: any): AxiosPromise<MaintenancePlanResponse> {
            return localVarFp.maintenancePlansMaintenancePlanIdDelete(maintenancePlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} maintenancePlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePlansMaintenancePlanIdGet(maintenancePlanId: string, options?: any): AxiosPromise<MaintenancePlanResponse> {
            return localVarFp.maintenancePlansMaintenancePlanIdGet(maintenancePlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} maintenancePlanId 
         * @param {UpdateMaintenancePlanRequest} [updateMaintenancePlanRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePlansMaintenancePlanIdPut(maintenancePlanId: string, updateMaintenancePlanRequest?: UpdateMaintenancePlanRequest, options?: any): AxiosPromise<MaintenancePlanResponse> {
            return localVarFp.maintenancePlansMaintenancePlanIdPut(maintenancePlanId, updateMaintenancePlanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {CreateMaintenancePlanRequest} [createMaintenancePlanRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePlansPost(createMaintenancePlanRequest?: CreateMaintenancePlanRequest, options?: any): AxiosPromise<MaintenancePlanResponse> {
            return localVarFp.maintenancePlansPost(createMaintenancePlanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePlansWorkspaceModuleIdExportGet(workspaceModuleId: string, options?: any): AxiosPromise<File> {
            return localVarFp.maintenancePlansWorkspaceModuleIdExportGet(workspaceModuleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceModuleId 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePlansWorkspaceModuleIdImportPost(workspaceModuleId: string, files?: Array<File>, options?: any): AxiosPromise<boolean> {
            return localVarFp.maintenancePlansWorkspaceModuleIdImportPost(workspaceModuleId, files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePlansWorkspaceModuleWorkspaceModuleIdDetailsGet(workspaceModuleId: string, options?: any): AxiosPromise<Array<MaintenancePlanParameterDetailsResponse>> {
            return localVarFp.maintenancePlansWorkspaceModuleWorkspaceModuleIdDetailsGet(workspaceModuleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePlansWorkspaceModuleWorkspaceModuleIdGet(workspaceModuleId: string, options?: any): AxiosPromise<Array<MaintenancePlanResponse>> {
            return localVarFp.maintenancePlansWorkspaceModuleWorkspaceModuleIdGet(workspaceModuleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MaintenancePlansApi - object-oriented interface
 * @export
 * @class MaintenancePlansApi
 * @extends {BaseAPI}
 */
export class MaintenancePlansApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string} maintenancePlanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenancePlansApi
     */
    public maintenancePlansMaintenancePlanIdDelete(maintenancePlanId: string, options?: AxiosRequestConfig) {
        return MaintenancePlansApiFp(this.configuration).maintenancePlansMaintenancePlanIdDelete(maintenancePlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} maintenancePlanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenancePlansApi
     */
    public maintenancePlansMaintenancePlanIdGet(maintenancePlanId: string, options?: AxiosRequestConfig) {
        return MaintenancePlansApiFp(this.configuration).maintenancePlansMaintenancePlanIdGet(maintenancePlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} maintenancePlanId 
     * @param {UpdateMaintenancePlanRequest} [updateMaintenancePlanRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenancePlansApi
     */
    public maintenancePlansMaintenancePlanIdPut(maintenancePlanId: string, updateMaintenancePlanRequest?: UpdateMaintenancePlanRequest, options?: AxiosRequestConfig) {
        return MaintenancePlansApiFp(this.configuration).maintenancePlansMaintenancePlanIdPut(maintenancePlanId, updateMaintenancePlanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {CreateMaintenancePlanRequest} [createMaintenancePlanRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenancePlansApi
     */
    public maintenancePlansPost(createMaintenancePlanRequest?: CreateMaintenancePlanRequest, options?: AxiosRequestConfig) {
        return MaintenancePlansApiFp(this.configuration).maintenancePlansPost(createMaintenancePlanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} workspaceModuleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenancePlansApi
     */
    public maintenancePlansWorkspaceModuleIdExportGet(workspaceModuleId: string, options?: AxiosRequestConfig) {
        return MaintenancePlansApiFp(this.configuration).maintenancePlansWorkspaceModuleIdExportGet(workspaceModuleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiOrOrgAdmin)
     * @param {string} workspaceModuleId 
     * @param {Array<File>} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenancePlansApi
     */
    public maintenancePlansWorkspaceModuleIdImportPost(workspaceModuleId: string, files?: Array<File>, options?: AxiosRequestConfig) {
        return MaintenancePlansApiFp(this.configuration).maintenancePlansWorkspaceModuleIdImportPost(workspaceModuleId, files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} workspaceModuleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenancePlansApi
     */
    public maintenancePlansWorkspaceModuleWorkspaceModuleIdDetailsGet(workspaceModuleId: string, options?: AxiosRequestConfig) {
        return MaintenancePlansApiFp(this.configuration).maintenancePlansWorkspaceModuleWorkspaceModuleIdDetailsGet(workspaceModuleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} workspaceModuleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenancePlansApi
     */
    public maintenancePlansWorkspaceModuleWorkspaceModuleIdGet(workspaceModuleId: string, options?: AxiosRequestConfig) {
        return MaintenancePlansApiFp(this.configuration).maintenancePlansWorkspaceModuleWorkspaceModuleIdGet(workspaceModuleId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MappingSetsApi - axios parameter creator
 * @export
 */
export const MappingSetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMappingSetByIdRoute: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMappingSetByIdRoute', 'id', id)
            const localVarPath = `/mapping-sets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingSetsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mapping-sets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {CreateLearningRequest} [createLearningRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingSetsIdLearningPost: async (id: string, createLearningRequest?: CreateLearningRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mappingSetsIdLearningPost', 'id', id)
            const localVarPath = `/mapping-sets/{id}/learning`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLearningRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {UpdateLearningRequest} [updateLearningRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingSetsIdLearningPut: async (id: string, updateLearningRequest?: UpdateLearningRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mappingSetsIdLearningPut', 'id', id)
            const localVarPath = `/mapping-sets/{id}/learning`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLearningRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {UpdateMappingSetRequest} [updateMappingSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingSetsIdPut: async (id: string, updateMappingSetRequest?: UpdateMappingSetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mappingSetsIdPut', 'id', id)
            const localVarPath = `/mapping-sets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMappingSetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingSetsLearningActiveGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mapping-sets/learning/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} mappingSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingSetsMappingSetIdLearningGet: async (mappingSetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mappingSetId' is not null or undefined
            assertParamExists('mappingSetsMappingSetIdLearningGet', 'mappingSetId', mappingSetId)
            const localVarPath = `/mapping-sets/{mappingSetId}/learning`
                .replace(`{${"mappingSetId"}}`, encodeURIComponent(String(mappingSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {CreateMappingSetRequest} [createMappingSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingSetsPost: async (createMappingSetRequest?: CreateMappingSetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mapping-sets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMappingSetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MappingSetsApi - functional programming interface
 * @export
 */
export const MappingSetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MappingSetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMappingSetByIdRoute(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappingSetDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMappingSetByIdRoute(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mappingSetsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MappingSetResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mappingSetsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {CreateLearningRequest} [createLearningRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mappingSetsIdLearningPost(id: string, createLearningRequest?: CreateLearningRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LearningResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mappingSetsIdLearningPost(id, createLearningRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {UpdateLearningRequest} [updateLearningRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mappingSetsIdLearningPut(id: string, updateLearningRequest?: UpdateLearningRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LearningResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mappingSetsIdLearningPut(id, updateLearningRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {UpdateMappingSetRequest} [updateMappingSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mappingSetsIdPut(id: string, updateMappingSetRequest?: UpdateMappingSetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappingSetDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mappingSetsIdPut(id, updateMappingSetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mappingSetsLearningActiveGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LearningResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mappingSetsLearningActiveGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} mappingSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mappingSetsMappingSetIdLearningGet(mappingSetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LearningResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mappingSetsMappingSetIdLearningGet(mappingSetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {CreateMappingSetRequest} [createMappingSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mappingSetsPost(createMappingSetRequest?: CreateMappingSetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappingSetDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mappingSetsPost(createMappingSetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MappingSetsApi - factory interface
 * @export
 */
export const MappingSetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MappingSetsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMappingSetByIdRoute(id: string, options?: any): AxiosPromise<MappingSetDetailResponse> {
            return localVarFp.getMappingSetByIdRoute(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingSetsGet(options?: any): AxiosPromise<Array<MappingSetResponse>> {
            return localVarFp.mappingSetsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {CreateLearningRequest} [createLearningRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingSetsIdLearningPost(id: string, createLearningRequest?: CreateLearningRequest, options?: any): AxiosPromise<LearningResponse> {
            return localVarFp.mappingSetsIdLearningPost(id, createLearningRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {UpdateLearningRequest} [updateLearningRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingSetsIdLearningPut(id: string, updateLearningRequest?: UpdateLearningRequest, options?: any): AxiosPromise<LearningResponse> {
            return localVarFp.mappingSetsIdLearningPut(id, updateLearningRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {UpdateMappingSetRequest} [updateMappingSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingSetsIdPut(id: string, updateMappingSetRequest?: UpdateMappingSetRequest, options?: any): AxiosPromise<MappingSetDetailResponse> {
            return localVarFp.mappingSetsIdPut(id, updateMappingSetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingSetsLearningActiveGet(options?: any): AxiosPromise<Array<LearningResponse>> {
            return localVarFp.mappingSetsLearningActiveGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} mappingSetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingSetsMappingSetIdLearningGet(mappingSetId: string, options?: any): AxiosPromise<LearningResponse> {
            return localVarFp.mappingSetsMappingSetIdLearningGet(mappingSetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {CreateMappingSetRequest} [createMappingSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mappingSetsPost(createMappingSetRequest?: CreateMappingSetRequest, options?: any): AxiosPromise<MappingSetDetailResponse> {
            return localVarFp.mappingSetsPost(createMappingSetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MappingSetsApi - object-oriented interface
 * @export
 * @class MappingSetsApi
 * @extends {BaseAPI}
 */
export class MappingSetsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MappingSetsApi
     */
    public getMappingSetByIdRoute(id: string, options?: AxiosRequestConfig) {
        return MappingSetsApiFp(this.configuration).getMappingSetByIdRoute(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MappingSetsApi
     */
    public mappingSetsGet(options?: AxiosRequestConfig) {
        return MappingSetsApiFp(this.configuration).mappingSetsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {CreateLearningRequest} [createLearningRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MappingSetsApi
     */
    public mappingSetsIdLearningPost(id: string, createLearningRequest?: CreateLearningRequest, options?: AxiosRequestConfig) {
        return MappingSetsApiFp(this.configuration).mappingSetsIdLearningPost(id, createLearningRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {string} id 
     * @param {UpdateLearningRequest} [updateLearningRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MappingSetsApi
     */
    public mappingSetsIdLearningPut(id: string, updateLearningRequest?: UpdateLearningRequest, options?: AxiosRequestConfig) {
        return MappingSetsApiFp(this.configuration).mappingSetsIdLearningPut(id, updateLearningRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {string} id 
     * @param {UpdateMappingSetRequest} [updateMappingSetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MappingSetsApi
     */
    public mappingSetsIdPut(id: string, updateMappingSetRequest?: UpdateMappingSetRequest, options?: AxiosRequestConfig) {
        return MappingSetsApiFp(this.configuration).mappingSetsIdPut(id, updateMappingSetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MappingSetsApi
     */
    public mappingSetsLearningActiveGet(options?: AxiosRequestConfig) {
        return MappingSetsApiFp(this.configuration).mappingSetsLearningActiveGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} mappingSetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MappingSetsApi
     */
    public mappingSetsMappingSetIdLearningGet(mappingSetId: string, options?: AxiosRequestConfig) {
        return MappingSetsApiFp(this.configuration).mappingSetsMappingSetIdLearningGet(mappingSetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {CreateMappingSetRequest} [createMappingSetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MappingSetsApi
     */
    public mappingSetsPost(createMappingSetRequest?: CreateMappingSetRequest, options?: AxiosRequestConfig) {
        return MappingSetsApiFp(this.configuration).mappingSetsPost(createMappingSetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ModulesApi - axios parameter creator
 * @export
 */
export const ModulesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleByIdRoute: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getModuleByIdRoute', 'id', id)
            const localVarPath = `/modules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/modules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdConditionDescriptionsCreatePost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modulesIdConditionDescriptionsCreatePost', 'id', id)
            const localVarPath = `/modules/{id}/condition-descriptions/create`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdConditionDescriptionsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modulesIdConditionDescriptionsGet', 'id', id)
            const localVarPath = `/modules/{id}/condition-descriptions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {Array<UpdateModuleMappingGroupDescriptionRequest>} [updateModuleMappingGroupDescriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdConditionDescriptionsPut: async (id: string, updateModuleMappingGroupDescriptionRequest?: Array<UpdateModuleMappingGroupDescriptionRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modulesIdConditionDescriptionsPut', 'id', id)
            const localVarPath = `/modules/{id}/condition-descriptions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateModuleMappingGroupDescriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {string} parameterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdParameterParameterIdConditionDescriptionsGet: async (id: string, parameterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modulesIdParameterParameterIdConditionDescriptionsGet', 'id', id)
            // verify required parameter 'parameterId' is not null or undefined
            assertParamExists('modulesIdParameterParameterIdConditionDescriptionsGet', 'parameterId', parameterId)
            const localVarPath = `/modules/{id}/parameter/{parameterId}/condition-descriptions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"parameterId"}}`, encodeURIComponent(String(parameterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {UpdateModuleRequest} [updateModuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdPut: async (id: string, updateModuleRequest?: UpdateModuleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modulesIdPut', 'id', id)
            const localVarPath = `/modules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateModuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {CreateModuleWeightingVersionRequest} [createModuleWeightingVersionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdWeightingVersionsCreatePost: async (id: string, createModuleWeightingVersionRequest?: CreateModuleWeightingVersionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modulesIdWeightingVersionsCreatePost', 'id', id)
            const localVarPath = `/modules/{id}/weighting-versions/create`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createModuleWeightingVersionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdWeightingVersionsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modulesIdWeightingVersionsGet', 'id', id)
            const localVarPath = `/modules/{id}/weighting-versions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {string} weightingVersionId 
         * @param {boolean} [empty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdWeightingVersionsWeightingVersionIdExportGet: async (id: string, weightingVersionId: string, empty?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modulesIdWeightingVersionsWeightingVersionIdExportGet', 'id', id)
            // verify required parameter 'weightingVersionId' is not null or undefined
            assertParamExists('modulesIdWeightingVersionsWeightingVersionIdExportGet', 'weightingVersionId', weightingVersionId)
            const localVarPath = `/modules/{id}/weighting-versions/{weightingVersionId}/export`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"weightingVersionId"}}`, encodeURIComponent(String(weightingVersionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (empty !== undefined) {
                localVarQueryParameter['empty'] = empty;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {string} weightingVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdWeightingVersionsWeightingVersionIdGet: async (id: string, weightingVersionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modulesIdWeightingVersionsWeightingVersionIdGet', 'id', id)
            // verify required parameter 'weightingVersionId' is not null or undefined
            assertParamExists('modulesIdWeightingVersionsWeightingVersionIdGet', 'weightingVersionId', weightingVersionId)
            const localVarPath = `/modules/{id}/weighting-versions/{weightingVersionId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"weightingVersionId"}}`, encodeURIComponent(String(weightingVersionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {string} weightingVersionId 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdWeightingVersionsWeightingVersionIdImportPost: async (id: string, weightingVersionId: string, files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modulesIdWeightingVersionsWeightingVersionIdImportPost', 'id', id)
            // verify required parameter 'weightingVersionId' is not null or undefined
            assertParamExists('modulesIdWeightingVersionsWeightingVersionIdImportPost', 'weightingVersionId', weightingVersionId)
            const localVarPath = `/modules/{id}/weighting-versions/{weightingVersionId}/import`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"weightingVersionId"}}`, encodeURIComponent(String(weightingVersionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {string} weightingVersionId 
         * @param {UpdateModuleWeightingVersionRequest} [updateModuleWeightingVersionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdWeightingVersionsWeightingVersionIdPut: async (id: string, weightingVersionId: string, updateModuleWeightingVersionRequest?: UpdateModuleWeightingVersionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modulesIdWeightingVersionsWeightingVersionIdPut', 'id', id)
            // verify required parameter 'weightingVersionId' is not null or undefined
            assertParamExists('modulesIdWeightingVersionsWeightingVersionIdPut', 'weightingVersionId', weightingVersionId)
            const localVarPath = `/modules/{id}/weighting-versions/{weightingVersionId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"weightingVersionId"}}`, encodeURIComponent(String(weightingVersionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateModuleWeightingVersionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {CreateModuleRequest} [createModuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesPost: async (createModuleRequest?: CreateModuleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/modules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createModuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModulesApi - functional programming interface
 * @export
 */
export const ModulesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModulesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModuleByIdRoute(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModuleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModuleByIdRoute(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modulesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModuleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modulesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modulesIdConditionDescriptionsCreatePost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModuleMappingGroupDescriptionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modulesIdConditionDescriptionsCreatePost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modulesIdConditionDescriptionsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModuleMappingGroupDescriptionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modulesIdConditionDescriptionsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {Array<UpdateModuleMappingGroupDescriptionRequest>} [updateModuleMappingGroupDescriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modulesIdConditionDescriptionsPut(id: string, updateModuleMappingGroupDescriptionRequest?: Array<UpdateModuleMappingGroupDescriptionRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModuleMappingGroupDescriptionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modulesIdConditionDescriptionsPut(id, updateModuleMappingGroupDescriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {string} parameterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modulesIdParameterParameterIdConditionDescriptionsGet(id: string, parameterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModuleParameterDescriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modulesIdParameterParameterIdConditionDescriptionsGet(id, parameterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {UpdateModuleRequest} [updateModuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modulesIdPut(id: string, updateModuleRequest?: UpdateModuleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModuleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modulesIdPut(id, updateModuleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {CreateModuleWeightingVersionRequest} [createModuleWeightingVersionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modulesIdWeightingVersionsCreatePost(id: string, createModuleWeightingVersionRequest?: CreateModuleWeightingVersionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModuleWeightingVersionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modulesIdWeightingVersionsCreatePost(id, createModuleWeightingVersionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modulesIdWeightingVersionsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModuleWeightingVersionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modulesIdWeightingVersionsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {string} weightingVersionId 
         * @param {boolean} [empty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modulesIdWeightingVersionsWeightingVersionIdExportGet(id: string, weightingVersionId: string, empty?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modulesIdWeightingVersionsWeightingVersionIdExportGet(id, weightingVersionId, empty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {string} weightingVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modulesIdWeightingVersionsWeightingVersionIdGet(id: string, weightingVersionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModuleWeightingVersionDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modulesIdWeightingVersionsWeightingVersionIdGet(id, weightingVersionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {string} weightingVersionId 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modulesIdWeightingVersionsWeightingVersionIdImportPost(id: string, weightingVersionId: string, files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modulesIdWeightingVersionsWeightingVersionIdImportPost(id, weightingVersionId, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {string} weightingVersionId 
         * @param {UpdateModuleWeightingVersionRequest} [updateModuleWeightingVersionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modulesIdWeightingVersionsWeightingVersionIdPut(id: string, weightingVersionId: string, updateModuleWeightingVersionRequest?: UpdateModuleWeightingVersionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModuleWeightingVersionDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modulesIdWeightingVersionsWeightingVersionIdPut(id, weightingVersionId, updateModuleWeightingVersionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {CreateModuleRequest} [createModuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modulesPost(createModuleRequest?: CreateModuleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModuleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modulesPost(createModuleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModulesApi - factory interface
 * @export
 */
export const ModulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModulesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleByIdRoute(id: string, options?: any): AxiosPromise<ModuleResponse> {
            return localVarFp.getModuleByIdRoute(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesGet(options?: any): AxiosPromise<Array<ModuleResponse>> {
            return localVarFp.modulesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdConditionDescriptionsCreatePost(id: string, options?: any): AxiosPromise<Array<ModuleMappingGroupDescriptionResponse>> {
            return localVarFp.modulesIdConditionDescriptionsCreatePost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdConditionDescriptionsGet(id: string, options?: any): AxiosPromise<Array<ModuleMappingGroupDescriptionResponse>> {
            return localVarFp.modulesIdConditionDescriptionsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {Array<UpdateModuleMappingGroupDescriptionRequest>} [updateModuleMappingGroupDescriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdConditionDescriptionsPut(id: string, updateModuleMappingGroupDescriptionRequest?: Array<UpdateModuleMappingGroupDescriptionRequest>, options?: any): AxiosPromise<Array<ModuleMappingGroupDescriptionDto>> {
            return localVarFp.modulesIdConditionDescriptionsPut(id, updateModuleMappingGroupDescriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {string} parameterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdParameterParameterIdConditionDescriptionsGet(id: string, parameterId: string, options?: any): AxiosPromise<ModuleParameterDescriptionResponse> {
            return localVarFp.modulesIdParameterParameterIdConditionDescriptionsGet(id, parameterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {UpdateModuleRequest} [updateModuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdPut(id: string, updateModuleRequest?: UpdateModuleRequest, options?: any): AxiosPromise<ModuleResponse> {
            return localVarFp.modulesIdPut(id, updateModuleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {CreateModuleWeightingVersionRequest} [createModuleWeightingVersionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdWeightingVersionsCreatePost(id: string, createModuleWeightingVersionRequest?: CreateModuleWeightingVersionRequest, options?: any): AxiosPromise<ModuleWeightingVersionResponse> {
            return localVarFp.modulesIdWeightingVersionsCreatePost(id, createModuleWeightingVersionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdWeightingVersionsGet(id: string, options?: any): AxiosPromise<Array<ModuleWeightingVersionResponse>> {
            return localVarFp.modulesIdWeightingVersionsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {string} weightingVersionId 
         * @param {boolean} [empty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdWeightingVersionsWeightingVersionIdExportGet(id: string, weightingVersionId: string, empty?: boolean, options?: any): AxiosPromise<File> {
            return localVarFp.modulesIdWeightingVersionsWeightingVersionIdExportGet(id, weightingVersionId, empty, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {string} weightingVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdWeightingVersionsWeightingVersionIdGet(id: string, weightingVersionId: string, options?: any): AxiosPromise<ModuleWeightingVersionDetailResponse> {
            return localVarFp.modulesIdWeightingVersionsWeightingVersionIdGet(id, weightingVersionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {string} weightingVersionId 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdWeightingVersionsWeightingVersionIdImportPost(id: string, weightingVersionId: string, files?: Array<File>, options?: any): AxiosPromise<boolean> {
            return localVarFp.modulesIdWeightingVersionsWeightingVersionIdImportPost(id, weightingVersionId, files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {string} weightingVersionId 
         * @param {UpdateModuleWeightingVersionRequest} [updateModuleWeightingVersionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesIdWeightingVersionsWeightingVersionIdPut(id: string, weightingVersionId: string, updateModuleWeightingVersionRequest?: UpdateModuleWeightingVersionRequest, options?: any): AxiosPromise<ModuleWeightingVersionDetailResponse> {
            return localVarFp.modulesIdWeightingVersionsWeightingVersionIdPut(id, weightingVersionId, updateModuleWeightingVersionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {CreateModuleRequest} [createModuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modulesPost(createModuleRequest?: CreateModuleRequest, options?: any): AxiosPromise<ModuleResponse> {
            return localVarFp.modulesPost(createModuleRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModulesApi - object-oriented interface
 * @export
 * @class ModulesApi
 * @extends {BaseAPI}
 */
export class ModulesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModulesApi
     */
    public getModuleByIdRoute(id: string, options?: AxiosRequestConfig) {
        return ModulesApiFp(this.configuration).getModuleByIdRoute(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModulesApi
     */
    public modulesGet(options?: AxiosRequestConfig) {
        return ModulesApiFp(this.configuration).modulesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModulesApi
     */
    public modulesIdConditionDescriptionsCreatePost(id: string, options?: AxiosRequestConfig) {
        return ModulesApiFp(this.configuration).modulesIdConditionDescriptionsCreatePost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModulesApi
     */
    public modulesIdConditionDescriptionsGet(id: string, options?: AxiosRequestConfig) {
        return ModulesApiFp(this.configuration).modulesIdConditionDescriptionsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {string} id 
     * @param {Array<UpdateModuleMappingGroupDescriptionRequest>} [updateModuleMappingGroupDescriptionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModulesApi
     */
    public modulesIdConditionDescriptionsPut(id: string, updateModuleMappingGroupDescriptionRequest?: Array<UpdateModuleMappingGroupDescriptionRequest>, options?: AxiosRequestConfig) {
        return ModulesApiFp(this.configuration).modulesIdConditionDescriptionsPut(id, updateModuleMappingGroupDescriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {string} parameterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModulesApi
     */
    public modulesIdParameterParameterIdConditionDescriptionsGet(id: string, parameterId: string, options?: AxiosRequestConfig) {
        return ModulesApiFp(this.configuration).modulesIdParameterParameterIdConditionDescriptionsGet(id, parameterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {string} id 
     * @param {UpdateModuleRequest} [updateModuleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModulesApi
     */
    public modulesIdPut(id: string, updateModuleRequest?: UpdateModuleRequest, options?: AxiosRequestConfig) {
        return ModulesApiFp(this.configuration).modulesIdPut(id, updateModuleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {string} id 
     * @param {CreateModuleWeightingVersionRequest} [createModuleWeightingVersionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModulesApi
     */
    public modulesIdWeightingVersionsCreatePost(id: string, createModuleWeightingVersionRequest?: CreateModuleWeightingVersionRequest, options?: AxiosRequestConfig) {
        return ModulesApiFp(this.configuration).modulesIdWeightingVersionsCreatePost(id, createModuleWeightingVersionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModulesApi
     */
    public modulesIdWeightingVersionsGet(id: string, options?: AxiosRequestConfig) {
        return ModulesApiFp(this.configuration).modulesIdWeightingVersionsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {string} id 
     * @param {string} weightingVersionId 
     * @param {boolean} [empty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModulesApi
     */
    public modulesIdWeightingVersionsWeightingVersionIdExportGet(id: string, weightingVersionId: string, empty?: boolean, options?: AxiosRequestConfig) {
        return ModulesApiFp(this.configuration).modulesIdWeightingVersionsWeightingVersionIdExportGet(id, weightingVersionId, empty, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {string} weightingVersionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModulesApi
     */
    public modulesIdWeightingVersionsWeightingVersionIdGet(id: string, weightingVersionId: string, options?: AxiosRequestConfig) {
        return ModulesApiFp(this.configuration).modulesIdWeightingVersionsWeightingVersionIdGet(id, weightingVersionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {string} id 
     * @param {string} weightingVersionId 
     * @param {Array<File>} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModulesApi
     */
    public modulesIdWeightingVersionsWeightingVersionIdImportPost(id: string, weightingVersionId: string, files?: Array<File>, options?: AxiosRequestConfig) {
        return ModulesApiFp(this.configuration).modulesIdWeightingVersionsWeightingVersionIdImportPost(id, weightingVersionId, files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {string} id 
     * @param {string} weightingVersionId 
     * @param {UpdateModuleWeightingVersionRequest} [updateModuleWeightingVersionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModulesApi
     */
    public modulesIdWeightingVersionsWeightingVersionIdPut(id: string, weightingVersionId: string, updateModuleWeightingVersionRequest?: UpdateModuleWeightingVersionRequest, options?: AxiosRequestConfig) {
        return ModulesApiFp(this.configuration).modulesIdWeightingVersionsWeightingVersionIdPut(id, weightingVersionId, updateModuleWeightingVersionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {CreateModuleRequest} [createModuleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModulesApi
     */
    public modulesPost(createModuleRequest?: CreateModuleRequest, options?: AxiosRequestConfig) {
        return ModulesApiFp(this.configuration).modulesPost(createModuleRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ObjectMappingsApi - axios parameter creator
 * @export
 */
export const ObjectMappingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('objectMappingsIdGet', 'id', id)
            const localVarPath = `/object-mappings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} id 
         * @param {CreateWorkspaceModuleRequest} [createWorkspaceModuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsIdWorkspaceModulePost: async (id: string, createWorkspaceModuleRequest?: CreateWorkspaceModuleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('objectMappingsIdWorkspaceModulePost', 'id', id)
            const localVarPath = `/object-mappings/{id}/workspace-module`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkspaceModuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} mappingObjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsObjectsMappingObjectIdDetailGet: async (mappingObjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mappingObjectId' is not null or undefined
            assertParamExists('objectMappingsObjectsMappingObjectIdDetailGet', 'mappingObjectId', mappingObjectId)
            const localVarPath = `/object-mappings/objects/{mappingObjectId}/detail`
                .replace(`{${"mappingObjectId"}}`, encodeURIComponent(String(mappingObjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} mappingObjectId 
         * @param {CreateMappingObjectStateRequest} [createMappingObjectStateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsObjectsMappingObjectIdDetailPost: async (mappingObjectId: string, createMappingObjectStateRequest?: CreateMappingObjectStateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mappingObjectId' is not null or undefined
            assertParamExists('objectMappingsObjectsMappingObjectIdDetailPost', 'mappingObjectId', mappingObjectId)
            const localVarPath = `/object-mappings/objects/{mappingObjectId}/detail`
                .replace(`{${"mappingObjectId"}}`, encodeURIComponent(String(mappingObjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMappingObjectStateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} periodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsPeriodPeriodIdCheckIfUpdateNeededGet: async (periodId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'periodId' is not null or undefined
            assertParamExists('objectMappingsPeriodPeriodIdCheckIfUpdateNeededGet', 'periodId', periodId)
            const localVarPath = `/object-mappings/period/{periodId}/check-if-update-needed`
                .replace(`{${"periodId"}}`, encodeURIComponent(String(periodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} periodId 
         * @param {string} objectId 
         * @param {string} parameterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsPeriodPeriodIdObjectObjectIdParameterParameterIdCommentsCdGet: async (periodId: string, objectId: string, parameterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'periodId' is not null or undefined
            assertParamExists('objectMappingsPeriodPeriodIdObjectObjectIdParameterParameterIdCommentsCdGet', 'periodId', periodId)
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('objectMappingsPeriodPeriodIdObjectObjectIdParameterParameterIdCommentsCdGet', 'objectId', objectId)
            // verify required parameter 'parameterId' is not null or undefined
            assertParamExists('objectMappingsPeriodPeriodIdObjectObjectIdParameterParameterIdCommentsCdGet', 'parameterId', parameterId)
            const localVarPath = `/object-mappings/period/{periodId}/object/{objectId}/parameter/{parameterId}/comments/cd`
                .replace(`{${"periodId"}}`, encodeURIComponent(String(periodId)))
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"parameterId"}}`, encodeURIComponent(String(parameterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} periodId 
         * @param {Array<AddedOrRemovedObjectResponse>} [addedOrRemovedObjectResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsPeriodPeriodIdUpdatePost: async (periodId: string, addedOrRemovedObjectResponse?: Array<AddedOrRemovedObjectResponse>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'periodId' is not null or undefined
            assertParamExists('objectMappingsPeriodPeriodIdUpdatePost', 'periodId', periodId)
            const localVarPath = `/object-mappings/period/{periodId}/update`
                .replace(`{${"periodId"}}`, encodeURIComponent(String(periodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addedOrRemovedObjectResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} mappingPeriodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsPeriodsMappingPeriodIdGet: async (mappingPeriodId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mappingPeriodId' is not null or undefined
            assertParamExists('objectMappingsPeriodsMappingPeriodIdGet', 'mappingPeriodId', mappingPeriodId)
            const localVarPath = `/object-mappings/periods/{mappingPeriodId}`
                .replace(`{${"mappingPeriodId"}}`, encodeURIComponent(String(mappingPeriodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} mappingPeriodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsPeriodsMappingPeriodIdObjectsGet: async (mappingPeriodId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mappingPeriodId' is not null or undefined
            assertParamExists('objectMappingsPeriodsMappingPeriodIdObjectsGet', 'mappingPeriodId', mappingPeriodId)
            const localVarPath = `/object-mappings/periods/{mappingPeriodId}/objects`
                .replace(`{${"mappingPeriodId"}}`, encodeURIComponent(String(mappingPeriodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} periodId 
         * @param {CreateMappingPeriodDescriptionAndStateRequest} [createMappingPeriodDescriptionAndStateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsPeriodsPeriodIdDescriptionAndStatePost: async (periodId: string, createMappingPeriodDescriptionAndStateRequest?: CreateMappingPeriodDescriptionAndStateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'periodId' is not null or undefined
            assertParamExists('objectMappingsPeriodsPeriodIdDescriptionAndStatePost', 'periodId', periodId)
            const localVarPath = `/object-mappings/periods/{periodId}/description-and-state`
                .replace(`{${"periodId"}}`, encodeURIComponent(String(periodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMappingPeriodDescriptionAndStateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsWorkspaceIdModulesGet: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('objectMappingsWorkspaceIdModulesGet', 'workspaceId', workspaceId)
            const localVarPath = `/object-mappings/{workspaceId}/modules`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsWorkspaceModuleWorkspaceModuleIdActivePeriodGet: async (workspaceModuleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceModuleId' is not null or undefined
            assertParamExists('objectMappingsWorkspaceModuleWorkspaceModuleIdActivePeriodGet', 'workspaceModuleId', workspaceModuleId)
            const localVarPath = `/object-mappings/workspace-module/{workspaceModuleId}/active-period`
                .replace(`{${"workspaceModuleId"}}`, encodeURIComponent(String(workspaceModuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsWorkspaceModuleWorkspaceModuleIdClosedAndInactivePeriodsGet: async (workspaceModuleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceModuleId' is not null or undefined
            assertParamExists('objectMappingsWorkspaceModuleWorkspaceModuleIdClosedAndInactivePeriodsGet', 'workspaceModuleId', workspaceModuleId)
            const localVarPath = `/object-mappings/workspace-module/{workspaceModuleId}/closed-and-inactive-periods`
                .replace(`{${"workspaceModuleId"}}`, encodeURIComponent(String(workspaceModuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsWorkspaceModuleWorkspaceModuleIdClosedPeriodsGet: async (workspaceModuleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceModuleId' is not null or undefined
            assertParamExists('objectMappingsWorkspaceModuleWorkspaceModuleIdClosedPeriodsGet', 'workspaceModuleId', workspaceModuleId)
            const localVarPath = `/object-mappings/workspace-module/{workspaceModuleId}/closed-periods`
                .replace(`{${"workspaceModuleId"}}`, encodeURIComponent(String(workspaceModuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsWorkspaceModuleWorkspaceModuleIdGet: async (workspaceModuleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceModuleId' is not null or undefined
            assertParamExists('objectMappingsWorkspaceModuleWorkspaceModuleIdGet', 'workspaceModuleId', workspaceModuleId)
            const localVarPath = `/object-mappings/workspace-module/{workspaceModuleId}`
                .replace(`{${"workspaceModuleId"}}`, encodeURIComponent(String(workspaceModuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsWorkspaceModuleWorkspaceModuleIdLastClosedPeriodGet: async (workspaceModuleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceModuleId' is not null or undefined
            assertParamExists('objectMappingsWorkspaceModuleWorkspaceModuleIdLastClosedPeriodGet', 'workspaceModuleId', workspaceModuleId)
            const localVarPath = `/object-mappings/workspace-module/{workspaceModuleId}/last-closed-period`
                .replace(`{${"workspaceModuleId"}}`, encodeURIComponent(String(workspaceModuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceModuleId 
         * @param {CreateMappingPeriodRequest} [createMappingPeriodRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsWorkspaceModuleWorkspaceModuleIdMappingPeriodPost: async (workspaceModuleId: string, createMappingPeriodRequest?: CreateMappingPeriodRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceModuleId' is not null or undefined
            assertParamExists('objectMappingsWorkspaceModuleWorkspaceModuleIdMappingPeriodPost', 'workspaceModuleId', workspaceModuleId)
            const localVarPath = `/object-mappings/workspace-module/{workspaceModuleId}/mapping-period`
                .replace(`{${"workspaceModuleId"}}`, encodeURIComponent(String(workspaceModuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMappingPeriodRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ObjectMappingsApi - functional programming interface
 * @export
 */
export const ObjectMappingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ObjectMappingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectMappingsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkspaceModuleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectMappingsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} id 
         * @param {CreateWorkspaceModuleRequest} [createWorkspaceModuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectMappingsIdWorkspaceModulePost(id: string, createWorkspaceModuleRequest?: CreateWorkspaceModuleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceModuleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectMappingsIdWorkspaceModulePost(id, createWorkspaceModuleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} mappingObjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectMappingsObjectsMappingObjectIdDetailGet(mappingObjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappingObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectMappingsObjectsMappingObjectIdDetailGet(mappingObjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} mappingObjectId 
         * @param {CreateMappingObjectStateRequest} [createMappingObjectStateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectMappingsObjectsMappingObjectIdDetailPost(mappingObjectId: string, createMappingObjectStateRequest?: CreateMappingObjectStateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappingObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectMappingsObjectsMappingObjectIdDetailPost(mappingObjectId, createMappingObjectStateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} periodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectMappingsPeriodPeriodIdCheckIfUpdateNeededGet(periodId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddedOrRemovedObjectResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectMappingsPeriodPeriodIdCheckIfUpdateNeededGet(periodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} periodId 
         * @param {string} objectId 
         * @param {string} parameterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectMappingsPeriodPeriodIdObjectObjectIdParameterParameterIdCommentsCdGet(periodId: string, objectId: string, parameterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MappingParameterConditionDegreeValueResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectMappingsPeriodPeriodIdObjectObjectIdParameterParameterIdCommentsCdGet(periodId, objectId, parameterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} periodId 
         * @param {Array<AddedOrRemovedObjectResponse>} [addedOrRemovedObjectResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectMappingsPeriodPeriodIdUpdatePost(periodId: string, addedOrRemovedObjectResponse?: Array<AddedOrRemovedObjectResponse>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappingPeriodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectMappingsPeriodPeriodIdUpdatePost(periodId, addedOrRemovedObjectResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} mappingPeriodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectMappingsPeriodsMappingPeriodIdGet(mappingPeriodId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappingPeriodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectMappingsPeriodsMappingPeriodIdGet(mappingPeriodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} mappingPeriodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectMappingsPeriodsMappingPeriodIdObjectsGet(mappingPeriodId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappingPeriodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectMappingsPeriodsMappingPeriodIdObjectsGet(mappingPeriodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} periodId 
         * @param {CreateMappingPeriodDescriptionAndStateRequest} [createMappingPeriodDescriptionAndStateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectMappingsPeriodsPeriodIdDescriptionAndStatePost(periodId: string, createMappingPeriodDescriptionAndStateRequest?: CreateMappingPeriodDescriptionAndStateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappingPeriodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectMappingsPeriodsPeriodIdDescriptionAndStatePost(periodId, createMappingPeriodDescriptionAndStateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectMappingsWorkspaceIdModulesGet(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailableModuleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectMappingsWorkspaceIdModulesGet(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectMappingsWorkspaceModuleWorkspaceModuleIdActivePeriodGet(workspaceModuleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappingPeriodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectMappingsWorkspaceModuleWorkspaceModuleIdActivePeriodGet(workspaceModuleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectMappingsWorkspaceModuleWorkspaceModuleIdClosedAndInactivePeriodsGet(workspaceModuleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MappingPeriodResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectMappingsWorkspaceModuleWorkspaceModuleIdClosedAndInactivePeriodsGet(workspaceModuleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectMappingsWorkspaceModuleWorkspaceModuleIdClosedPeriodsGet(workspaceModuleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MappingPeriodResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectMappingsWorkspaceModuleWorkspaceModuleIdClosedPeriodsGet(workspaceModuleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectMappingsWorkspaceModuleWorkspaceModuleIdGet(workspaceModuleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceModuleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectMappingsWorkspaceModuleWorkspaceModuleIdGet(workspaceModuleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectMappingsWorkspaceModuleWorkspaceModuleIdLastClosedPeriodGet(workspaceModuleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappingPeriodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectMappingsWorkspaceModuleWorkspaceModuleIdLastClosedPeriodGet(workspaceModuleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceModuleId 
         * @param {CreateMappingPeriodRequest} [createMappingPeriodRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectMappingsWorkspaceModuleWorkspaceModuleIdMappingPeriodPost(workspaceModuleId: string, createMappingPeriodRequest?: CreateMappingPeriodRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappingPeriodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectMappingsWorkspaceModuleWorkspaceModuleIdMappingPeriodPost(workspaceModuleId, createMappingPeriodRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ObjectMappingsApi - factory interface
 * @export
 */
export const ObjectMappingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ObjectMappingsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsIdGet(id: string, options?: any): AxiosPromise<Array<WorkspaceModuleResponse>> {
            return localVarFp.objectMappingsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} id 
         * @param {CreateWorkspaceModuleRequest} [createWorkspaceModuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsIdWorkspaceModulePost(id: string, createWorkspaceModuleRequest?: CreateWorkspaceModuleRequest, options?: any): AxiosPromise<WorkspaceModuleResponse> {
            return localVarFp.objectMappingsIdWorkspaceModulePost(id, createWorkspaceModuleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} mappingObjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsObjectsMappingObjectIdDetailGet(mappingObjectId: string, options?: any): AxiosPromise<MappingObjectResponse> {
            return localVarFp.objectMappingsObjectsMappingObjectIdDetailGet(mappingObjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} mappingObjectId 
         * @param {CreateMappingObjectStateRequest} [createMappingObjectStateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsObjectsMappingObjectIdDetailPost(mappingObjectId: string, createMappingObjectStateRequest?: CreateMappingObjectStateRequest, options?: any): AxiosPromise<MappingObjectResponse> {
            return localVarFp.objectMappingsObjectsMappingObjectIdDetailPost(mappingObjectId, createMappingObjectStateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} periodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsPeriodPeriodIdCheckIfUpdateNeededGet(periodId: string, options?: any): AxiosPromise<Array<AddedOrRemovedObjectResponse>> {
            return localVarFp.objectMappingsPeriodPeriodIdCheckIfUpdateNeededGet(periodId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} periodId 
         * @param {string} objectId 
         * @param {string} parameterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsPeriodPeriodIdObjectObjectIdParameterParameterIdCommentsCdGet(periodId: string, objectId: string, parameterId: string, options?: any): AxiosPromise<Array<MappingParameterConditionDegreeValueResponse>> {
            return localVarFp.objectMappingsPeriodPeriodIdObjectObjectIdParameterParameterIdCommentsCdGet(periodId, objectId, parameterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} periodId 
         * @param {Array<AddedOrRemovedObjectResponse>} [addedOrRemovedObjectResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsPeriodPeriodIdUpdatePost(periodId: string, addedOrRemovedObjectResponse?: Array<AddedOrRemovedObjectResponse>, options?: any): AxiosPromise<MappingPeriodResponse> {
            return localVarFp.objectMappingsPeriodPeriodIdUpdatePost(periodId, addedOrRemovedObjectResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} mappingPeriodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsPeriodsMappingPeriodIdGet(mappingPeriodId: string, options?: any): AxiosPromise<MappingPeriodResponse> {
            return localVarFp.objectMappingsPeriodsMappingPeriodIdGet(mappingPeriodId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} mappingPeriodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsPeriodsMappingPeriodIdObjectsGet(mappingPeriodId: string, options?: any): AxiosPromise<MappingPeriodResponse> {
            return localVarFp.objectMappingsPeriodsMappingPeriodIdObjectsGet(mappingPeriodId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} periodId 
         * @param {CreateMappingPeriodDescriptionAndStateRequest} [createMappingPeriodDescriptionAndStateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsPeriodsPeriodIdDescriptionAndStatePost(periodId: string, createMappingPeriodDescriptionAndStateRequest?: CreateMappingPeriodDescriptionAndStateRequest, options?: any): AxiosPromise<MappingPeriodResponse> {
            return localVarFp.objectMappingsPeriodsPeriodIdDescriptionAndStatePost(periodId, createMappingPeriodDescriptionAndStateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsWorkspaceIdModulesGet(workspaceId: string, options?: any): AxiosPromise<Array<AvailableModuleResponse>> {
            return localVarFp.objectMappingsWorkspaceIdModulesGet(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsWorkspaceModuleWorkspaceModuleIdActivePeriodGet(workspaceModuleId: string, options?: any): AxiosPromise<MappingPeriodResponse> {
            return localVarFp.objectMappingsWorkspaceModuleWorkspaceModuleIdActivePeriodGet(workspaceModuleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsWorkspaceModuleWorkspaceModuleIdClosedAndInactivePeriodsGet(workspaceModuleId: string, options?: any): AxiosPromise<Array<MappingPeriodResponse>> {
            return localVarFp.objectMappingsWorkspaceModuleWorkspaceModuleIdClosedAndInactivePeriodsGet(workspaceModuleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsWorkspaceModuleWorkspaceModuleIdClosedPeriodsGet(workspaceModuleId: string, options?: any): AxiosPromise<Array<MappingPeriodResponse>> {
            return localVarFp.objectMappingsWorkspaceModuleWorkspaceModuleIdClosedPeriodsGet(workspaceModuleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsWorkspaceModuleWorkspaceModuleIdGet(workspaceModuleId: string, options?: any): AxiosPromise<WorkspaceModuleResponse> {
            return localVarFp.objectMappingsWorkspaceModuleWorkspaceModuleIdGet(workspaceModuleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsWorkspaceModuleWorkspaceModuleIdLastClosedPeriodGet(workspaceModuleId: string, options?: any): AxiosPromise<MappingPeriodResponse> {
            return localVarFp.objectMappingsWorkspaceModuleWorkspaceModuleIdLastClosedPeriodGet(workspaceModuleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceModuleId 
         * @param {CreateMappingPeriodRequest} [createMappingPeriodRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectMappingsWorkspaceModuleWorkspaceModuleIdMappingPeriodPost(workspaceModuleId: string, createMappingPeriodRequest?: CreateMappingPeriodRequest, options?: any): AxiosPromise<MappingPeriodResponse> {
            return localVarFp.objectMappingsWorkspaceModuleWorkspaceModuleIdMappingPeriodPost(workspaceModuleId, createMappingPeriodRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ObjectMappingsApi - object-oriented interface
 * @export
 * @class ObjectMappingsApi
 * @extends {BaseAPI}
 */
export class ObjectMappingsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectMappingsApi
     */
    public objectMappingsIdGet(id: string, options?: AxiosRequestConfig) {
        return ObjectMappingsApiFp(this.configuration).objectMappingsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiOrOrgAdmin)
     * @param {string} id 
     * @param {CreateWorkspaceModuleRequest} [createWorkspaceModuleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectMappingsApi
     */
    public objectMappingsIdWorkspaceModulePost(id: string, createWorkspaceModuleRequest?: CreateWorkspaceModuleRequest, options?: AxiosRequestConfig) {
        return ObjectMappingsApiFp(this.configuration).objectMappingsIdWorkspaceModulePost(id, createWorkspaceModuleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} mappingObjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectMappingsApi
     */
    public objectMappingsObjectsMappingObjectIdDetailGet(mappingObjectId: string, options?: AxiosRequestConfig) {
        return ObjectMappingsApiFp(this.configuration).objectMappingsObjectsMappingObjectIdDetailGet(mappingObjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} mappingObjectId 
     * @param {CreateMappingObjectStateRequest} [createMappingObjectStateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectMappingsApi
     */
    public objectMappingsObjectsMappingObjectIdDetailPost(mappingObjectId: string, createMappingObjectStateRequest?: CreateMappingObjectStateRequest, options?: AxiosRequestConfig) {
        return ObjectMappingsApiFp(this.configuration).objectMappingsObjectsMappingObjectIdDetailPost(mappingObjectId, createMappingObjectStateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} periodId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectMappingsApi
     */
    public objectMappingsPeriodPeriodIdCheckIfUpdateNeededGet(periodId: string, options?: AxiosRequestConfig) {
        return ObjectMappingsApiFp(this.configuration).objectMappingsPeriodPeriodIdCheckIfUpdateNeededGet(periodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} periodId 
     * @param {string} objectId 
     * @param {string} parameterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectMappingsApi
     */
    public objectMappingsPeriodPeriodIdObjectObjectIdParameterParameterIdCommentsCdGet(periodId: string, objectId: string, parameterId: string, options?: AxiosRequestConfig) {
        return ObjectMappingsApiFp(this.configuration).objectMappingsPeriodPeriodIdObjectObjectIdParameterParameterIdCommentsCdGet(periodId, objectId, parameterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} periodId 
     * @param {Array<AddedOrRemovedObjectResponse>} [addedOrRemovedObjectResponse] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectMappingsApi
     */
    public objectMappingsPeriodPeriodIdUpdatePost(periodId: string, addedOrRemovedObjectResponse?: Array<AddedOrRemovedObjectResponse>, options?: AxiosRequestConfig) {
        return ObjectMappingsApiFp(this.configuration).objectMappingsPeriodPeriodIdUpdatePost(periodId, addedOrRemovedObjectResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} mappingPeriodId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectMappingsApi
     */
    public objectMappingsPeriodsMappingPeriodIdGet(mappingPeriodId: string, options?: AxiosRequestConfig) {
        return ObjectMappingsApiFp(this.configuration).objectMappingsPeriodsMappingPeriodIdGet(mappingPeriodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} mappingPeriodId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectMappingsApi
     */
    public objectMappingsPeriodsMappingPeriodIdObjectsGet(mappingPeriodId: string, options?: AxiosRequestConfig) {
        return ObjectMappingsApiFp(this.configuration).objectMappingsPeriodsMappingPeriodIdObjectsGet(mappingPeriodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiOrOrgAdmin)
     * @param {string} periodId 
     * @param {CreateMappingPeriodDescriptionAndStateRequest} [createMappingPeriodDescriptionAndStateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectMappingsApi
     */
    public objectMappingsPeriodsPeriodIdDescriptionAndStatePost(periodId: string, createMappingPeriodDescriptionAndStateRequest?: CreateMappingPeriodDescriptionAndStateRequest, options?: AxiosRequestConfig) {
        return ObjectMappingsApiFp(this.configuration).objectMappingsPeriodsPeriodIdDescriptionAndStatePost(periodId, createMappingPeriodDescriptionAndStateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectMappingsApi
     */
    public objectMappingsWorkspaceIdModulesGet(workspaceId: string, options?: AxiosRequestConfig) {
        return ObjectMappingsApiFp(this.configuration).objectMappingsWorkspaceIdModulesGet(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} workspaceModuleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectMappingsApi
     */
    public objectMappingsWorkspaceModuleWorkspaceModuleIdActivePeriodGet(workspaceModuleId: string, options?: AxiosRequestConfig) {
        return ObjectMappingsApiFp(this.configuration).objectMappingsWorkspaceModuleWorkspaceModuleIdActivePeriodGet(workspaceModuleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} workspaceModuleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectMappingsApi
     */
    public objectMappingsWorkspaceModuleWorkspaceModuleIdClosedAndInactivePeriodsGet(workspaceModuleId: string, options?: AxiosRequestConfig) {
        return ObjectMappingsApiFp(this.configuration).objectMappingsWorkspaceModuleWorkspaceModuleIdClosedAndInactivePeriodsGet(workspaceModuleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} workspaceModuleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectMappingsApi
     */
    public objectMappingsWorkspaceModuleWorkspaceModuleIdClosedPeriodsGet(workspaceModuleId: string, options?: AxiosRequestConfig) {
        return ObjectMappingsApiFp(this.configuration).objectMappingsWorkspaceModuleWorkspaceModuleIdClosedPeriodsGet(workspaceModuleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} workspaceModuleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectMappingsApi
     */
    public objectMappingsWorkspaceModuleWorkspaceModuleIdGet(workspaceModuleId: string, options?: AxiosRequestConfig) {
        return ObjectMappingsApiFp(this.configuration).objectMappingsWorkspaceModuleWorkspaceModuleIdGet(workspaceModuleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} workspaceModuleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectMappingsApi
     */
    public objectMappingsWorkspaceModuleWorkspaceModuleIdLastClosedPeriodGet(workspaceModuleId: string, options?: AxiosRequestConfig) {
        return ObjectMappingsApiFp(this.configuration).objectMappingsWorkspaceModuleWorkspaceModuleIdLastClosedPeriodGet(workspaceModuleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiOrOrgAdmin)
     * @param {string} workspaceModuleId 
     * @param {CreateMappingPeriodRequest} [createMappingPeriodRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectMappingsApi
     */
    public objectMappingsWorkspaceModuleWorkspaceModuleIdMappingPeriodPost(workspaceModuleId: string, createMappingPeriodRequest?: CreateMappingPeriodRequest, options?: AxiosRequestConfig) {
        return ObjectMappingsApiFp(this.configuration).objectMappingsWorkspaceModuleWorkspaceModuleIdMappingPeriodPost(workspaceModuleId, createMappingPeriodRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ObjectPropertiesApi - axios parameter creator
 * @export
 */
export const ObjectPropertiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectPropertiesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/object-properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectPropertiesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('objectPropertiesIdDelete', 'id', id)
            const localVarPath = `/object-properties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {UpdateObjectPropertyRequest} [updateObjectPropertyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectPropertiesIdPut: async (id: string, updateObjectPropertyRequest?: UpdateObjectPropertyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('objectPropertiesIdPut', 'id', id)
            const localVarPath = `/object-properties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateObjectPropertyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectPropertiesObjectTypeIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('objectPropertiesObjectTypeIdGet', 'id', id)
            const localVarPath = `/object-properties/object-type/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {CreateObjectPropertyRequest} [createObjectPropertyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectPropertiesPost: async (createObjectPropertyRequest?: CreateObjectPropertyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/object-properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createObjectPropertyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ObjectPropertiesApi - functional programming interface
 * @export
 */
export const ObjectPropertiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ObjectPropertiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectPropertiesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ObjectPropertyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectPropertiesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectPropertiesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectPropertyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectPropertiesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {UpdateObjectPropertyRequest} [updateObjectPropertyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectPropertiesIdPut(id: string, updateObjectPropertyRequest?: UpdateObjectPropertyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectPropertyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectPropertiesIdPut(id, updateObjectPropertyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectPropertiesObjectTypeIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ObjectPropertyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectPropertiesObjectTypeIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {CreateObjectPropertyRequest} [createObjectPropertyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectPropertiesPost(createObjectPropertyRequest?: CreateObjectPropertyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectPropertyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectPropertiesPost(createObjectPropertyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ObjectPropertiesApi - factory interface
 * @export
 */
export const ObjectPropertiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ObjectPropertiesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectPropertiesGet(options?: any): AxiosPromise<Array<ObjectPropertyResponse>> {
            return localVarFp.objectPropertiesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectPropertiesIdDelete(id: string, options?: any): AxiosPromise<ObjectPropertyResponse> {
            return localVarFp.objectPropertiesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {UpdateObjectPropertyRequest} [updateObjectPropertyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectPropertiesIdPut(id: string, updateObjectPropertyRequest?: UpdateObjectPropertyRequest, options?: any): AxiosPromise<ObjectPropertyResponse> {
            return localVarFp.objectPropertiesIdPut(id, updateObjectPropertyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectPropertiesObjectTypeIdGet(id: string, options?: any): AxiosPromise<Array<ObjectPropertyResponse>> {
            return localVarFp.objectPropertiesObjectTypeIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {CreateObjectPropertyRequest} [createObjectPropertyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectPropertiesPost(createObjectPropertyRequest?: CreateObjectPropertyRequest, options?: any): AxiosPromise<ObjectPropertyResponse> {
            return localVarFp.objectPropertiesPost(createObjectPropertyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ObjectPropertiesApi - object-oriented interface
 * @export
 * @class ObjectPropertiesApi
 * @extends {BaseAPI}
 */
export class ObjectPropertiesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectPropertiesApi
     */
    public objectPropertiesGet(options?: AxiosRequestConfig) {
        return ObjectPropertiesApiFp(this.configuration).objectPropertiesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectPropertiesApi
     */
    public objectPropertiesIdDelete(id: string, options?: AxiosRequestConfig) {
        return ObjectPropertiesApiFp(this.configuration).objectPropertiesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {string} id 
     * @param {UpdateObjectPropertyRequest} [updateObjectPropertyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectPropertiesApi
     */
    public objectPropertiesIdPut(id: string, updateObjectPropertyRequest?: UpdateObjectPropertyRequest, options?: AxiosRequestConfig) {
        return ObjectPropertiesApiFp(this.configuration).objectPropertiesIdPut(id, updateObjectPropertyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectPropertiesApi
     */
    public objectPropertiesObjectTypeIdGet(id: string, options?: AxiosRequestConfig) {
        return ObjectPropertiesApiFp(this.configuration).objectPropertiesObjectTypeIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {CreateObjectPropertyRequest} [createObjectPropertyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectPropertiesApi
     */
    public objectPropertiesPost(createObjectPropertyRequest?: CreateObjectPropertyRequest, options?: AxiosRequestConfig) {
        return ObjectPropertiesApiFp(this.configuration).objectPropertiesPost(createObjectPropertyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ObjectTypesApi - axios parameter creator
 * @export
 */
export const ObjectTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObjectTypeByIdRoute: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getObjectTypeByIdRoute', 'id', id)
            const localVarPath = `/object-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/object-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {ObjectTypeUpdateRequest} [objectTypeUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectTypesIdPut: async (id: string, objectTypeUpdateRequest?: ObjectTypeUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('objectTypesIdPut', 'id', id)
            const localVarPath = `/object-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectTypeUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {ObjectTypeCreateRequest} [objectTypeCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectTypesPost: async (objectTypeCreateRequest?: ObjectTypeCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/object-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectTypeCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ObjectTypesApi - functional programming interface
 * @export
 */
export const ObjectTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ObjectTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getObjectTypeByIdRoute(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectTypeDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getObjectTypeByIdRoute(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ObjectTypeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {ObjectTypeUpdateRequest} [objectTypeUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectTypesIdPut(id: string, objectTypeUpdateRequest?: ObjectTypeUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectTypeDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectTypesIdPut(id, objectTypeUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {ObjectTypeCreateRequest} [objectTypeCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectTypesPost(objectTypeCreateRequest?: ObjectTypeCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectTypeDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectTypesPost(objectTypeCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ObjectTypesApi - factory interface
 * @export
 */
export const ObjectTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ObjectTypesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObjectTypeByIdRoute(id: string, options?: any): AxiosPromise<ObjectTypeDetailResponse> {
            return localVarFp.getObjectTypeByIdRoute(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectTypesGet(options?: any): AxiosPromise<Array<ObjectTypeResponse>> {
            return localVarFp.objectTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {ObjectTypeUpdateRequest} [objectTypeUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectTypesIdPut(id: string, objectTypeUpdateRequest?: ObjectTypeUpdateRequest, options?: any): AxiosPromise<ObjectTypeDetailResponse> {
            return localVarFp.objectTypesIdPut(id, objectTypeUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {ObjectTypeCreateRequest} [objectTypeCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectTypesPost(objectTypeCreateRequest?: ObjectTypeCreateRequest, options?: any): AxiosPromise<ObjectTypeDetailResponse> {
            return localVarFp.objectTypesPost(objectTypeCreateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ObjectTypesApi - object-oriented interface
 * @export
 * @class ObjectTypesApi
 * @extends {BaseAPI}
 */
export class ObjectTypesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectTypesApi
     */
    public getObjectTypeByIdRoute(id: string, options?: AxiosRequestConfig) {
        return ObjectTypesApiFp(this.configuration).getObjectTypeByIdRoute(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectTypesApi
     */
    public objectTypesGet(options?: AxiosRequestConfig) {
        return ObjectTypesApiFp(this.configuration).objectTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {string} id 
     * @param {ObjectTypeUpdateRequest} [objectTypeUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectTypesApi
     */
    public objectTypesIdPut(id: string, objectTypeUpdateRequest?: ObjectTypeUpdateRequest, options?: AxiosRequestConfig) {
        return ObjectTypesApiFp(this.configuration).objectTypesIdPut(id, objectTypeUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {ObjectTypeCreateRequest} [objectTypeCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectTypesApi
     */
    public objectTypesPost(objectTypeCreateRequest?: ObjectTypeCreateRequest, options?: AxiosRequestConfig) {
        return ObjectTypesApiFp(this.configuration).objectTypesPost(objectTypeCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationsApi - axios parameter creator
 * @export
 */
export const OrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrganizations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrganizationById', 'id', id)
            const localVarPath = `/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {UserInvitationStatus} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationInvitations: async (organizationId: string, filter?: UserInvitationStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationInvitations', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/user-invitations`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationModules: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationModules', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/modules`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUsers: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationUsers', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/users`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {OrganizationMessageRequest} [organizationMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdContactPost: async (organizationId: string, organizationMessageRequest?: OrganizationMessageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationsOrganizationIdContactPost', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/contact`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {string} organizationModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdModulesOrganizationModuleIdDelete: async (organizationId: string, organizationModuleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationsOrganizationIdModulesOrganizationModuleIdDelete', 'organizationId', organizationId)
            // verify required parameter 'organizationModuleId' is not null or undefined
            assertParamExists('organizationsOrganizationIdModulesOrganizationModuleIdDelete', 'organizationModuleId', organizationModuleId)
            const localVarPath = `/organizations/{organizationId}/modules/{organizationModuleId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"organizationModuleId"}}`, encodeURIComponent(String(organizationModuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {Array<CreateOrganizationModuleRequest>} [createOrganizationModuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdModulesPost: async (organizationId: string, createOrganizationModuleRequest?: Array<CreateOrganizationModuleRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationsOrganizationIdModulesPost', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/modules`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrganizationModuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} organizationId 
         * @param {UpdateOrganizationRequest} [updateOrganizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdOrganizationPut: async (organizationId: string, updateOrganizationRequest?: UpdateOrganizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationsOrganizationIdOrganizationPut', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/organization`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrganizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdServiceTypesGet: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationsOrganizationIdServiceTypesGet', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/service-types`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {string} organizationServiceTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdServiceTypesOrganizationServiceTypeIdDelete: async (organizationId: string, organizationServiceTypeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationsOrganizationIdServiceTypesOrganizationServiceTypeIdDelete', 'organizationId', organizationId)
            // verify required parameter 'organizationServiceTypeId' is not null or undefined
            assertParamExists('organizationsOrganizationIdServiceTypesOrganizationServiceTypeIdDelete', 'organizationServiceTypeId', organizationServiceTypeId)
            const localVarPath = `/organizations/{organizationId}/service-types/{organizationServiceTypeId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"organizationServiceTypeId"}}`, encodeURIComponent(String(organizationServiceTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {CreateOrganizationServiceTypeRequest} [createOrganizationServiceTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdServiceTypesPost: async (organizationId: string, createOrganizationServiceTypeRequest?: CreateOrganizationServiceTypeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationsOrganizationIdServiceTypesPost', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/service-types`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrganizationServiceTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdWorkspacesGet: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationsOrganizationIdWorkspacesGet', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/workspaces`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {CreateOrganizationWorkspaceRequest} [createOrganizationWorkspaceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdWorkspacesPost: async (organizationId: string, createOrganizationWorkspaceRequest?: CreateOrganizationWorkspaceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationsOrganizationIdWorkspacesPost', 'organizationId', organizationId)
            const localVarPath = `/organizations/{organizationId}/workspaces`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrganizationWorkspaceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} organizationModuleId 
         * @param {UpdateOrganizationModuleRequest} [updateOrganizationModuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationModuleIdOrganizationModulePut: async (organizationModuleId: string, updateOrganizationModuleRequest?: UpdateOrganizationModuleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationModuleId' is not null or undefined
            assertParamExists('organizationsOrganizationModuleIdOrganizationModulePut', 'organizationModuleId', organizationModuleId)
            const localVarPath = `/organizations/{organizationModuleId}/organization-module`
                .replace(`{${"organizationModuleId"}}`, encodeURIComponent(String(organizationModuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrganizationModuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {OrganizationSearchRequest} [organizationSearchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCreditCheckInfo: async (organizationSearchRequest?: OrganizationSearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations/credit-info-search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationSearchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {OrganizationSearchRequest} [organizationSearchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInfo: async (organizationSearchRequest?: OrganizationSearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations/info-search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationSearchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllOrganizations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllOrganizations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {UserInvitationStatus} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationInvitations(organizationId: string, filter?: UserInvitationStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserInvitationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationInvitations(organizationId, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationModules(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationModuleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationModules(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationUsers(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationUsers(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {OrganizationMessageRequest} [organizationMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsOrganizationIdContactPost(organizationId: string, organizationMessageRequest?: OrganizationMessageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsOrganizationIdContactPost(organizationId, organizationMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {string} organizationModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsOrganizationIdModulesOrganizationModuleIdDelete(organizationId: string, organizationModuleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationModuleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsOrganizationIdModulesOrganizationModuleIdDelete(organizationId, organizationModuleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {Array<CreateOrganizationModuleRequest>} [createOrganizationModuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsOrganizationIdModulesPost(organizationId: string, createOrganizationModuleRequest?: Array<CreateOrganizationModuleRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationModuleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsOrganizationIdModulesPost(organizationId, createOrganizationModuleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} organizationId 
         * @param {UpdateOrganizationRequest} [updateOrganizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsOrganizationIdOrganizationPut(organizationId: string, updateOrganizationRequest?: UpdateOrganizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsOrganizationIdOrganizationPut(organizationId, updateOrganizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsOrganizationIdServiceTypesGet(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationServiceTypeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsOrganizationIdServiceTypesGet(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {string} organizationServiceTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsOrganizationIdServiceTypesOrganizationServiceTypeIdDelete(organizationId: string, organizationServiceTypeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationServiceTypeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsOrganizationIdServiceTypesOrganizationServiceTypeIdDelete(organizationId, organizationServiceTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {CreateOrganizationServiceTypeRequest} [createOrganizationServiceTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsOrganizationIdServiceTypesPost(organizationId: string, createOrganizationServiceTypeRequest?: CreateOrganizationServiceTypeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationServiceTypeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsOrganizationIdServiceTypesPost(organizationId, createOrganizationServiceTypeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsOrganizationIdWorkspacesGet(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationWorkspaceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsOrganizationIdWorkspacesGet(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {CreateOrganizationWorkspaceRequest} [createOrganizationWorkspaceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsOrganizationIdWorkspacesPost(organizationId: string, createOrganizationWorkspaceRequest?: CreateOrganizationWorkspaceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationWorkspaceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsOrganizationIdWorkspacesPost(organizationId, createOrganizationWorkspaceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} organizationModuleId 
         * @param {UpdateOrganizationModuleRequest} [updateOrganizationModuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsOrganizationModuleIdOrganizationModulePut(organizationModuleId: string, updateOrganizationModuleRequest?: UpdateOrganizationModuleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationModuleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsOrganizationModuleIdOrganizationModulePut(organizationModuleId, updateOrganizationModuleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {OrganizationSearchRequest} [organizationSearchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCreditCheckInfo(organizationSearchRequest?: OrganizationSearchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationCreditInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCreditCheckInfo(organizationSearchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {OrganizationSearchRequest} [organizationSearchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInfo(organizationSearchRequest?: OrganizationSearchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInfo(organizationSearchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrganizations(options?: any): AxiosPromise<Array<OrganizationResponse>> {
            return localVarFp.getAllOrganizations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationById(id: string, options?: any): AxiosPromise<OrganizationDetailResponse> {
            return localVarFp.getOrganizationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {UserInvitationStatus} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationInvitations(organizationId: string, filter?: UserInvitationStatus, options?: any): AxiosPromise<Array<UserInvitationResponse>> {
            return localVarFp.getOrganizationInvitations(organizationId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationModules(organizationId: string, options?: any): AxiosPromise<Array<OrganizationModuleResponse>> {
            return localVarFp.getOrganizationModules(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUsers(organizationId: string, options?: any): AxiosPromise<Array<UserResponse>> {
            return localVarFp.getOrganizationUsers(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {OrganizationMessageRequest} [organizationMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdContactPost(organizationId: string, organizationMessageRequest?: OrganizationMessageRequest, options?: any): AxiosPromise<void> {
            return localVarFp.organizationsOrganizationIdContactPost(organizationId, organizationMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {string} organizationModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdModulesOrganizationModuleIdDelete(organizationId: string, organizationModuleId: string, options?: any): AxiosPromise<Array<OrganizationModuleResponse>> {
            return localVarFp.organizationsOrganizationIdModulesOrganizationModuleIdDelete(organizationId, organizationModuleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {Array<CreateOrganizationModuleRequest>} [createOrganizationModuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdModulesPost(organizationId: string, createOrganizationModuleRequest?: Array<CreateOrganizationModuleRequest>, options?: any): AxiosPromise<Array<OrganizationModuleResponse>> {
            return localVarFp.organizationsOrganizationIdModulesPost(organizationId, createOrganizationModuleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} organizationId 
         * @param {UpdateOrganizationRequest} [updateOrganizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdOrganizationPut(organizationId: string, updateOrganizationRequest?: UpdateOrganizationRequest, options?: any): AxiosPromise<OrganizationDetailResponse> {
            return localVarFp.organizationsOrganizationIdOrganizationPut(organizationId, updateOrganizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdServiceTypesGet(organizationId: string, options?: any): AxiosPromise<Array<OrganizationServiceTypeResponse>> {
            return localVarFp.organizationsOrganizationIdServiceTypesGet(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {string} organizationServiceTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdServiceTypesOrganizationServiceTypeIdDelete(organizationId: string, organizationServiceTypeId: string, options?: any): AxiosPromise<Array<OrganizationServiceTypeResponse>> {
            return localVarFp.organizationsOrganizationIdServiceTypesOrganizationServiceTypeIdDelete(organizationId, organizationServiceTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {CreateOrganizationServiceTypeRequest} [createOrganizationServiceTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdServiceTypesPost(organizationId: string, createOrganizationServiceTypeRequest?: CreateOrganizationServiceTypeRequest, options?: any): AxiosPromise<Array<OrganizationServiceTypeResponse>> {
            return localVarFp.organizationsOrganizationIdServiceTypesPost(organizationId, createOrganizationServiceTypeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdWorkspacesGet(organizationId: string, options?: any): AxiosPromise<Array<OrganizationWorkspaceResponse>> {
            return localVarFp.organizationsOrganizationIdWorkspacesGet(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {CreateOrganizationWorkspaceRequest} [createOrganizationWorkspaceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdWorkspacesPost(organizationId: string, createOrganizationWorkspaceRequest?: CreateOrganizationWorkspaceRequest, options?: any): AxiosPromise<Array<OrganizationWorkspaceResponse>> {
            return localVarFp.organizationsOrganizationIdWorkspacesPost(organizationId, createOrganizationWorkspaceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} organizationModuleId 
         * @param {UpdateOrganizationModuleRequest} [updateOrganizationModuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationModuleIdOrganizationModulePut(organizationModuleId: string, updateOrganizationModuleRequest?: UpdateOrganizationModuleRequest, options?: any): AxiosPromise<Array<OrganizationModuleResponse>> {
            return localVarFp.organizationsOrganizationModuleIdOrganizationModulePut(organizationModuleId, updateOrganizationModuleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {OrganizationSearchRequest} [organizationSearchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCreditCheckInfo(organizationSearchRequest?: OrganizationSearchRequest, options?: any): AxiosPromise<OrganizationCreditInfoResponse> {
            return localVarFp.searchCreditCheckInfo(organizationSearchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {OrganizationSearchRequest} [organizationSearchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInfo(organizationSearchRequest?: OrganizationSearchRequest, options?: any): AxiosPromise<OrganizationInfoResponse> {
            return localVarFp.searchInfo(organizationSearchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getAllOrganizations(options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getAllOrganizations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationById(id: string, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} organizationId 
     * @param {UserInvitationStatus} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationInvitations(organizationId: string, filter?: UserInvitationStatus, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationInvitations(organizationId, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationModules(organizationId: string, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationModules(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationUsers(organizationId: string, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationUsers(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} organizationId 
     * @param {OrganizationMessageRequest} [organizationMessageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsOrganizationIdContactPost(organizationId: string, organizationMessageRequest?: OrganizationMessageRequest, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationsOrganizationIdContactPost(organizationId, organizationMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} organizationId 
     * @param {string} organizationModuleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsOrganizationIdModulesOrganizationModuleIdDelete(organizationId: string, organizationModuleId: string, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationsOrganizationIdModulesOrganizationModuleIdDelete(organizationId, organizationModuleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} organizationId 
     * @param {Array<CreateOrganizationModuleRequest>} [createOrganizationModuleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsOrganizationIdModulesPost(organizationId: string, createOrganizationModuleRequest?: Array<CreateOrganizationModuleRequest>, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationsOrganizationIdModulesPost(organizationId, createOrganizationModuleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {string} organizationId 
     * @param {UpdateOrganizationRequest} [updateOrganizationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsOrganizationIdOrganizationPut(organizationId: string, updateOrganizationRequest?: UpdateOrganizationRequest, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationsOrganizationIdOrganizationPut(organizationId, updateOrganizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsOrganizationIdServiceTypesGet(organizationId: string, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationsOrganizationIdServiceTypesGet(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} organizationId 
     * @param {string} organizationServiceTypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsOrganizationIdServiceTypesOrganizationServiceTypeIdDelete(organizationId: string, organizationServiceTypeId: string, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationsOrganizationIdServiceTypesOrganizationServiceTypeIdDelete(organizationId, organizationServiceTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} organizationId 
     * @param {CreateOrganizationServiceTypeRequest} [createOrganizationServiceTypeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsOrganizationIdServiceTypesPost(organizationId: string, createOrganizationServiceTypeRequest?: CreateOrganizationServiceTypeRequest, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationsOrganizationIdServiceTypesPost(organizationId, createOrganizationServiceTypeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsOrganizationIdWorkspacesGet(organizationId: string, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationsOrganizationIdWorkspacesGet(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} organizationId 
     * @param {CreateOrganizationWorkspaceRequest} [createOrganizationWorkspaceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsOrganizationIdWorkspacesPost(organizationId: string, createOrganizationWorkspaceRequest?: CreateOrganizationWorkspaceRequest, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationsOrganizationIdWorkspacesPost(organizationId, createOrganizationWorkspaceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {string} organizationModuleId 
     * @param {UpdateOrganizationModuleRequest} [updateOrganizationModuleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsOrganizationModuleIdOrganizationModulePut(organizationModuleId: string, updateOrganizationModuleRequest?: UpdateOrganizationModuleRequest, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).organizationsOrganizationModuleIdOrganizationModulePut(organizationModuleId, updateOrganizationModuleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {OrganizationSearchRequest} [organizationSearchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public searchCreditCheckInfo(organizationSearchRequest?: OrganizationSearchRequest, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).searchCreditCheckInfo(organizationSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {OrganizationSearchRequest} [organizationSearchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public searchInfo(organizationSearchRequest?: OrganizationSearchRequest, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).searchInfo(organizationSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} organizationId 
         * @param {CreateOrganizationReportRequest} [createOrganizationReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsOrganizationIdOrderReportPost: async (organizationId: string, createOrganizationReportRequest?: CreateOrganizationReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('reportsOrganizationIdOrderReportPost', 'organizationId', organizationId)
            const localVarPath = `/reports/{organizationId}/order-report`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrganizationReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsOrganizationIdOrganizationReportsGet: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('reportsOrganizationIdOrganizationReportsGet', 'organizationId', organizationId)
            const localVarPath = `/reports/{organizationId}/organization-reports`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} organizationId 
         * @param {Array<CreateOrganizationReportRequest>} [createOrganizationReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsOrganizationIdOrganizationReportsPost: async (organizationId: string, createOrganizationReportRequest?: Array<CreateOrganizationReportRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('reportsOrganizationIdOrganizationReportsPost', 'organizationId', organizationId)
            const localVarPath = `/reports/{organizationId}/organization-reports`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrganizationReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} periodId 
         * @param {Array<string>} [mappingObjectIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsPeriodIdPeriodBasisReportGet: async (periodId: string, mappingObjectIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'periodId' is not null or undefined
            assertParamExists('reportsPeriodIdPeriodBasisReportGet', 'periodId', periodId)
            const localVarPath = `/reports/{periodId}/period/basis-report`
                .replace(`{${"periodId"}}`, encodeURIComponent(String(periodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (mappingObjectIds) {
                localVarQueryParameter['mappingObjectIds'] = mappingObjectIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} periodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsPeriodIdPeriodMappingDataExportGet: async (periodId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'periodId' is not null or undefined
            assertParamExists('reportsPeriodIdPeriodMappingDataExportGet', 'periodId', periodId)
            const localVarPath = `/reports/{periodId}/period/mapping-data/export`
                .replace(`{${"periodId"}}`, encodeURIComponent(String(periodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} periodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsPeriodIdPeriodMappingDataGet: async (periodId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'periodId' is not null or undefined
            assertParamExists('reportsPeriodIdPeriodMappingDataGet', 'periodId', periodId)
            const localVarPath = `/reports/{periodId}/period/mapping-data`
                .replace(`{${"periodId"}}`, encodeURIComponent(String(periodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} reportId 
         * @param {CreateReportRequest} [createReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsReportIdSpecialReportsPut: async (reportId: string, createReportRequest?: CreateReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportsReportIdSpecialReportsPut', 'reportId', reportId)
            const localVarPath = `/reports/{reportId}/special-reports`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsSpecialReportsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/special-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {CreateReportRequest} [createReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsSpecialReportsPost: async (createReportRequest?: CreateReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/special-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsWorkspaceIdAllModulesBasisReportGet: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('reportsWorkspaceIdAllModulesBasisReportGet', 'workspaceId', workspaceId)
            const localVarPath = `/reports/{workspaceId}/all-modules/basis-report`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsWorkspaceModuleIdPeriodByYearBasisReportGet: async (workspaceModuleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceModuleId' is not null or undefined
            assertParamExists('reportsWorkspaceModuleIdPeriodByYearBasisReportGet', 'workspaceModuleId', workspaceModuleId)
            const localVarPath = `/reports/{workspaceModuleId}/period-by-year/basis-report`
                .replace(`{${"workspaceModuleId"}}`, encodeURIComponent(String(workspaceModuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsWorkspaceModuleIdTableReportExportGet: async (workspaceModuleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceModuleId' is not null or undefined
            assertParamExists('reportsWorkspaceModuleIdTableReportExportGet', 'workspaceModuleId', workspaceModuleId)
            const localVarPath = `/reports/{workspaceModuleId}/table-report/export`
                .replace(`{${"workspaceModuleId"}}`, encodeURIComponent(String(workspaceModuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} organizationId 
         * @param {CreateOrganizationReportRequest} [createOrganizationReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsOrganizationIdOrderReportPost(organizationId: string, createOrganizationReportRequest?: CreateOrganizationReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsOrganizationIdOrderReportPost(organizationId, createOrganizationReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsOrganizationIdOrganizationReportsGet(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsOrganizationIdOrganizationReportsGet(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} organizationId 
         * @param {Array<CreateOrganizationReportRequest>} [createOrganizationReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsOrganizationIdOrganizationReportsPost(organizationId: string, createOrganizationReportRequest?: Array<CreateOrganizationReportRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsOrganizationIdOrganizationReportsPost(organizationId, createOrganizationReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} periodId 
         * @param {Array<string>} [mappingObjectIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsPeriodIdPeriodBasisReportGet(periodId: string, mappingObjectIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsPeriodIdPeriodBasisReportGet(periodId, mappingObjectIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} periodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsPeriodIdPeriodMappingDataExportGet(periodId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsPeriodIdPeriodMappingDataExportGet(periodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} periodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsPeriodIdPeriodMappingDataGet(periodId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MappingDataReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsPeriodIdPeriodMappingDataGet(periodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} reportId 
         * @param {CreateReportRequest} [createReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsReportIdSpecialReportsPut(reportId: string, createReportRequest?: CreateReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsReportIdSpecialReportsPut(reportId, createReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsSpecialReportsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsSpecialReportsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {CreateReportRequest} [createReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsSpecialReportsPost(createReportRequest?: CreateReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsSpecialReportsPost(createReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsWorkspaceIdAllModulesBasisReportGet(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportOverviewResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsWorkspaceIdAllModulesBasisReportGet(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsWorkspaceModuleIdPeriodByYearBasisReportGet(workspaceModuleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportOverviewResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsWorkspaceModuleIdPeriodByYearBasisReportGet(workspaceModuleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsWorkspaceModuleIdTableReportExportGet(workspaceModuleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsWorkspaceModuleIdTableReportExportGet(workspaceModuleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} organizationId 
         * @param {CreateOrganizationReportRequest} [createOrganizationReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsOrganizationIdOrderReportPost(organizationId: string, createOrganizationReportRequest?: CreateOrganizationReportRequest, options?: any): AxiosPromise<OrganizationReportResponse> {
            return localVarFp.reportsOrganizationIdOrderReportPost(organizationId, createOrganizationReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsOrganizationIdOrganizationReportsGet(organizationId: string, options?: any): AxiosPromise<Array<OrganizationReportResponse>> {
            return localVarFp.reportsOrganizationIdOrganizationReportsGet(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} organizationId 
         * @param {Array<CreateOrganizationReportRequest>} [createOrganizationReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsOrganizationIdOrganizationReportsPost(organizationId: string, createOrganizationReportRequest?: Array<CreateOrganizationReportRequest>, options?: any): AxiosPromise<Array<OrganizationReportResponse>> {
            return localVarFp.reportsOrganizationIdOrganizationReportsPost(organizationId, createOrganizationReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} periodId 
         * @param {Array<string>} [mappingObjectIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsPeriodIdPeriodBasisReportGet(periodId: string, mappingObjectIds?: Array<string>, options?: any): AxiosPromise<ReportOverviewResponse> {
            return localVarFp.reportsPeriodIdPeriodBasisReportGet(periodId, mappingObjectIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} periodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsPeriodIdPeriodMappingDataExportGet(periodId: string, options?: any): AxiosPromise<File> {
            return localVarFp.reportsPeriodIdPeriodMappingDataExportGet(periodId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} periodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsPeriodIdPeriodMappingDataGet(periodId: string, options?: any): AxiosPromise<MappingDataReportResponse> {
            return localVarFp.reportsPeriodIdPeriodMappingDataGet(periodId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} reportId 
         * @param {CreateReportRequest} [createReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsReportIdSpecialReportsPut(reportId: string, createReportRequest?: CreateReportRequest, options?: any): AxiosPromise<ReportResponse> {
            return localVarFp.reportsReportIdSpecialReportsPut(reportId, createReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsSpecialReportsGet(options?: any): AxiosPromise<Array<ReportResponse>> {
            return localVarFp.reportsSpecialReportsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {CreateReportRequest} [createReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsSpecialReportsPost(createReportRequest?: CreateReportRequest, options?: any): AxiosPromise<ReportResponse> {
            return localVarFp.reportsSpecialReportsPost(createReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsWorkspaceIdAllModulesBasisReportGet(workspaceId: string, options?: any): AxiosPromise<Array<ReportOverviewResponse>> {
            return localVarFp.reportsWorkspaceIdAllModulesBasisReportGet(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsWorkspaceModuleIdPeriodByYearBasisReportGet(workspaceModuleId: string, options?: any): AxiosPromise<Array<ReportOverviewResponse>> {
            return localVarFp.reportsWorkspaceModuleIdPeriodByYearBasisReportGet(workspaceModuleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceModuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsWorkspaceModuleIdTableReportExportGet(workspaceModuleId: string, options?: any): AxiosPromise<File> {
            return localVarFp.reportsWorkspaceModuleIdTableReportExportGet(workspaceModuleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: multiOrOrgAdmin)
     * @param {string} organizationId 
     * @param {CreateOrganizationReportRequest} [createOrganizationReportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsOrganizationIdOrderReportPost(organizationId: string, createOrganizationReportRequest?: CreateOrganizationReportRequest, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsOrganizationIdOrderReportPost(organizationId, createOrganizationReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsOrganizationIdOrganizationReportsGet(organizationId: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsOrganizationIdOrganizationReportsGet(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {string} organizationId 
     * @param {Array<CreateOrganizationReportRequest>} [createOrganizationReportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsOrganizationIdOrganizationReportsPost(organizationId: string, createOrganizationReportRequest?: Array<CreateOrganizationReportRequest>, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsOrganizationIdOrganizationReportsPost(organizationId, createOrganizationReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} periodId 
     * @param {Array<string>} [mappingObjectIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsPeriodIdPeriodBasisReportGet(periodId: string, mappingObjectIds?: Array<string>, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsPeriodIdPeriodBasisReportGet(periodId, mappingObjectIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} periodId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsPeriodIdPeriodMappingDataExportGet(periodId: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsPeriodIdPeriodMappingDataExportGet(periodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} periodId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsPeriodIdPeriodMappingDataGet(periodId: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsPeriodIdPeriodMappingDataGet(periodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {string} reportId 
     * @param {CreateReportRequest} [createReportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsReportIdSpecialReportsPut(reportId: string, createReportRequest?: CreateReportRequest, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsReportIdSpecialReportsPut(reportId, createReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsSpecialReportsGet(options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsSpecialReportsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {CreateReportRequest} [createReportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsSpecialReportsPost(createReportRequest?: CreateReportRequest, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsSpecialReportsPost(createReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsWorkspaceIdAllModulesBasisReportGet(workspaceId: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsWorkspaceIdAllModulesBasisReportGet(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} workspaceModuleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsWorkspaceModuleIdPeriodByYearBasisReportGet(workspaceModuleId: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsWorkspaceModuleIdPeriodByYearBasisReportGet(workspaceModuleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} workspaceModuleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsWorkspaceModuleIdTableReportExportGet(workspaceModuleId: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsWorkspaceModuleIdTableReportExportGet(workspaceModuleId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserInfoApi - axios parameter creator
 * @export
 */
export const UserInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {UpdatePreferredLanguageRequest} [updatePreferredLanguageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPreferredLanguage: async (updatePreferredLanguageRequest?: UpdatePreferredLanguageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-info/preferred-language`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePreferredLanguageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserInfoApi - functional programming interface
 * @export
 */
export const UserInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {UpdatePreferredLanguageRequest} [updatePreferredLanguageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPreferredLanguage(updatePreferredLanguageRequest?: UpdatePreferredLanguageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Language>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPreferredLanguage(updatePreferredLanguageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserInfoApi - factory interface
 * @export
 */
export const UserInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserInfoApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(options?: any): AxiosPromise<UserInfoResponse> {
            return localVarFp.getUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {UpdatePreferredLanguageRequest} [updatePreferredLanguageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPreferredLanguage(updatePreferredLanguageRequest?: UpdatePreferredLanguageRequest, options?: any): AxiosPromise<Language> {
            return localVarFp.setPreferredLanguage(updatePreferredLanguageRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserInfoApi - object-oriented interface
 * @export
 * @class UserInfoApi
 * @extends {BaseAPI}
 */
export class UserInfoApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInfoApi
     */
    public getUserInfo(options?: AxiosRequestConfig) {
        return UserInfoApiFp(this.configuration).getUserInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {UpdatePreferredLanguageRequest} [updatePreferredLanguageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInfoApi
     */
    public setPreferredLanguage(updatePreferredLanguageRequest?: UpdatePreferredLanguageRequest, options?: AxiosRequestConfig) {
        return UserInfoApiFp(this.configuration).setPreferredLanguage(updatePreferredLanguageRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserInvitationsApi - axios parameter creator
 * @export
 */
export const UserInvitationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInvitation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelInvitation', 'id', id)
            const localVarPath = `/user-invitations/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {CreateUserInvitationRequest} [createUserInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitation: async (createUserInvitationRequest?: CreateUserInvitationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-invitations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInvitationById', 'id', id)
            const localVarPath = `/user-invitations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemInvitation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('redeemInvitation', 'id', id)
            const localVarPath = `/user-invitations/{id}/redeem`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationsFindInvitationByUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-invitations/find-invitation-by-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationsManualRedeemPut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-invitations/manual-redeem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserInvitationsApi - functional programming interface
 * @export
 */
export const UserInvitationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserInvitationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelInvitation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelInvitation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {CreateUserInvitationRequest} [createUserInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvitation(createUserInvitationRequest?: CreateUserInvitationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInvitationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvitation(createUserInvitationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvitationById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInvitationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redeemInvitation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInvitationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redeemInvitation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInvitationsFindInvitationByUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInvitationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInvitationsFindInvitationByUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInvitationsManualRedeemPut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInvitationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInvitationsManualRedeemPut(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserInvitationsApi - factory interface
 * @export
 */
export const UserInvitationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserInvitationsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInvitation(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelInvitation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {CreateUserInvitationRequest} [createUserInvitationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitation(createUserInvitationRequest?: CreateUserInvitationRequest, options?: any): AxiosPromise<UserInvitationResponse> {
            return localVarFp.createInvitation(createUserInvitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationById(id: string, options?: any): AxiosPromise<UserInvitationResponse> {
            return localVarFp.getInvitationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemInvitation(id: string, options?: any): AxiosPromise<UserInvitationResponse> {
            return localVarFp.redeemInvitation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationsFindInvitationByUserGet(options?: any): AxiosPromise<UserInvitationResponse> {
            return localVarFp.userInvitationsFindInvitationByUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvitationsManualRedeemPut(options?: any): AxiosPromise<UserInvitationResponse> {
            return localVarFp.userInvitationsManualRedeemPut(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserInvitationsApi - object-oriented interface
 * @export
 * @class UserInvitationsApi
 * @extends {BaseAPI}
 */
export class UserInvitationsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: multiOrOrgAdmin)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInvitationsApi
     */
    public cancelInvitation(id: string, options?: AxiosRequestConfig) {
        return UserInvitationsApiFp(this.configuration).cancelInvitation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiOrOrgAdmin)
     * @param {CreateUserInvitationRequest} [createUserInvitationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInvitationsApi
     */
    public createInvitation(createUserInvitationRequest?: CreateUserInvitationRequest, options?: AxiosRequestConfig) {
        return UserInvitationsApiFp(this.configuration).createInvitation(createUserInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiOrOrgAdmin)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInvitationsApi
     */
    public getInvitationById(id: string, options?: AxiosRequestConfig) {
        return UserInvitationsApiFp(this.configuration).getInvitationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInvitationsApi
     */
    public redeemInvitation(id: string, options?: AxiosRequestConfig) {
        return UserInvitationsApiFp(this.configuration).redeemInvitation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInvitationsApi
     */
    public userInvitationsFindInvitationByUserGet(options?: AxiosRequestConfig) {
        return UserInvitationsApiFp(this.configuration).userInvitationsFindInvitationByUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInvitationsApi
     */
    public userInvitationsManualRedeemPut(options?: AxiosRequestConfig) {
        return UserInvitationsApiFp(this.configuration).userInvitationsManualRedeemPut(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserById', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly, multiAdminOnly)
         * @param {string} userId 
         * @param {UpdateUserRequest} [updateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdOrganizationPut: async (userId: string, updateUserRequest?: UpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersUserIdOrganizationPut', 'userId', userId)
            const localVarPath = `/users/{userId}/organization`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly, multiAdminOnly)
         * @param {string} userId 
         * @param {UpdateUserRequest} [updateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIdOrganizationPut(userId: string, updateUserRequest?: UpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIdOrganizationPut(userId, updateUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(id: string, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.getUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet(options?: any): AxiosPromise<Array<UserResponse>> {
            return localVarFp.usersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly, multiAdminOnly)
         * @param {string} userId 
         * @param {UpdateUserRequest} [updateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdOrganizationPut(userId: string, updateUserRequest?: UpdateUserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.usersUserIdOrganizationPut(userId, updateUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserById(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly, multiAdminOnly)
     * @param {string} userId 
     * @param {UpdateUserRequest} [updateUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIdOrganizationPut(userId: string, updateUserRequest?: UpdateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserIdOrganizationPut(userId, updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkspacesApi - axios parameter creator
 * @export
 */
export const WorkspacesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceByIdRoute: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspaceByIdRoute', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/workspaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesOrganizationIdOrganizationGet: async (organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('workspacesOrganizationIdOrganizationGet', 'organizationId', organizationId)
            const localVarPath = `/workspaces/{organizationId}/organization`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {WorkspaceCreateRequest} [workspaceCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesPost: async (workspaceCreateRequest?: WorkspaceCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/workspaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspaceCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesUserIdUserGet: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('workspacesUserIdUserGet', 'userId', userId)
            const localVarPath = `/workspaces/{userId}/user`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesWorkspaceIdExportV1Get: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspacesWorkspaceIdExportV1Get', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/export/v1`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesWorkspaceIdExportV2Get: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspacesWorkspaceIdExportV2Get', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/export/v2`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceId 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesWorkspaceIdImportExecuteV1Post: async (workspaceId: string, files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspacesWorkspaceIdImportExecuteV1Post', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/import-execute/v1`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesWorkspaceIdImportTemplateV1Get: async (workspaceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspacesWorkspaceIdImportTemplateV1Get', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/import-template/v1`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceId 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesWorkspaceIdImportVerifyV1Post: async (workspaceId: string, files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspacesWorkspaceIdImportVerifyV1Post', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/import-verify/v1`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesWorkspaceIdOrganizationOrganizationIdPut: async (workspaceId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspacesWorkspaceIdOrganizationOrganizationIdPut', 'workspaceId', workspaceId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('workspacesWorkspaceIdOrganizationOrganizationIdPut', 'organizationId', organizationId)
            const localVarPath = `/workspaces/{workspaceId}/organization/{organizationId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceId 
         * @param {WorkspaceDetailUpdateRequest} [workspaceDetailUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesWorkspaceIdPut: async (workspaceId: string, workspaceDetailUpdateRequest?: WorkspaceDetailUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('workspacesWorkspaceIdPut', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspaceDetailUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspacesApi - functional programming interface
 * @export
 */
export const WorkspacesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkspacesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaceByIdRoute(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaceByIdRoute(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspacesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkspaceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspacesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspacesOrganizationIdOrganizationGet(organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkspaceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspacesOrganizationIdOrganizationGet(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {WorkspaceCreateRequest} [workspaceCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspacesPost(workspaceCreateRequest?: WorkspaceCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspacesPost(workspaceCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspacesUserIdUserGet(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkspaceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspacesUserIdUserGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspacesWorkspaceIdExportV1Get(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspacesWorkspaceIdExportV1Get(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspacesWorkspaceIdExportV2Get(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspacesWorkspaceIdExportV2Get(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceId 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspacesWorkspaceIdImportExecuteV1Post(workspaceId: string, files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspacesWorkspaceIdImportExecuteV1Post(workspaceId, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspacesWorkspaceIdImportTemplateV1Get(workspaceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspacesWorkspaceIdImportTemplateV1Get(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceId 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspacesWorkspaceIdImportVerifyV1Post(workspaceId: string, files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceImportVerifyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspacesWorkspaceIdImportVerifyV1Post(workspaceId, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspacesWorkspaceIdOrganizationOrganizationIdPut(workspaceId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkspaceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspacesWorkspaceIdOrganizationOrganizationIdPut(workspaceId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceId 
         * @param {WorkspaceDetailUpdateRequest} [workspaceDetailUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workspacesWorkspaceIdPut(workspaceId: string, workspaceDetailUpdateRequest?: WorkspaceDetailUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workspacesWorkspaceIdPut(workspaceId, workspaceDetailUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkspacesApi - factory interface
 * @export
 */
export const WorkspacesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkspacesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceByIdRoute(workspaceId: string, options?: any): AxiosPromise<WorkspaceDetailResponse> {
            return localVarFp.getWorkspaceByIdRoute(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiAdminOnly)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesGet(options?: any): AxiosPromise<Array<WorkspaceResponse>> {
            return localVarFp.workspacesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesOrganizationIdOrganizationGet(organizationId: string, options?: any): AxiosPromise<Array<WorkspaceResponse>> {
            return localVarFp.workspacesOrganizationIdOrganizationGet(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {WorkspaceCreateRequest} [workspaceCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesPost(workspaceCreateRequest?: WorkspaceCreateRequest, options?: any): AxiosPromise<WorkspaceDetailResponse> {
            return localVarFp.workspacesPost(workspaceCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesUserIdUserGet(userId: string, options?: any): AxiosPromise<Array<WorkspaceResponse>> {
            return localVarFp.workspacesUserIdUserGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesWorkspaceIdExportV1Get(workspaceId: string, options?: any): AxiosPromise<File> {
            return localVarFp.workspacesWorkspaceIdExportV1Get(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesWorkspaceIdExportV2Get(workspaceId: string, options?: any): AxiosPromise<File> {
            return localVarFp.workspacesWorkspaceIdExportV2Get(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceId 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesWorkspaceIdImportExecuteV1Post(workspaceId: string, files?: Array<File>, options?: any): AxiosPromise<boolean> {
            return localVarFp.workspacesWorkspaceIdImportExecuteV1Post(workspaceId, files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesWorkspaceIdImportTemplateV1Get(workspaceId: string, options?: any): AxiosPromise<File> {
            return localVarFp.workspacesWorkspaceIdImportTemplateV1Get(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceId 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesWorkspaceIdImportVerifyV1Post(workspaceId: string, files?: Array<File>, options?: any): AxiosPromise<WorkspaceImportVerifyResponse> {
            return localVarFp.workspacesWorkspaceIdImportVerifyV1Post(workspaceId, files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesWorkspaceIdOrganizationOrganizationIdPut(workspaceId: string, organizationId: string, options?: any): AxiosPromise<Array<WorkspaceResponse>> {
            return localVarFp.workspacesWorkspaceIdOrganizationOrganizationIdPut(workspaceId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: multiOrOrgAdmin)
         * @param {string} workspaceId 
         * @param {WorkspaceDetailUpdateRequest} [workspaceDetailUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workspacesWorkspaceIdPut(workspaceId: string, workspaceDetailUpdateRequest?: WorkspaceDetailUpdateRequest, options?: any): AxiosPromise<WorkspaceDetailResponse> {
            return localVarFp.workspacesWorkspaceIdPut(workspaceId, workspaceDetailUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkspacesApi - object-oriented interface
 * @export
 * @class WorkspacesApi
 * @extends {BaseAPI}
 */
export class WorkspacesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getWorkspaceByIdRoute(workspaceId: string, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).getWorkspaceByIdRoute(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiAdminOnly)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public workspacesGet(options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).workspacesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public workspacesOrganizationIdOrganizationGet(organizationId: string, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).workspacesOrganizationIdOrganizationGet(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiOrOrgAdmin)
     * @param {WorkspaceCreateRequest} [workspaceCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public workspacesPost(workspaceCreateRequest?: WorkspaceCreateRequest, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).workspacesPost(workspaceCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public workspacesUserIdUserGet(userId: string, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).workspacesUserIdUserGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public workspacesWorkspaceIdExportV1Get(workspaceId: string, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).workspacesWorkspaceIdExportV1Get(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public workspacesWorkspaceIdExportV2Get(workspaceId: string, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).workspacesWorkspaceIdExportV2Get(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiOrOrgAdmin)
     * @param {string} workspaceId 
     * @param {Array<File>} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public workspacesWorkspaceIdImportExecuteV1Post(workspaceId: string, files?: Array<File>, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).workspacesWorkspaceIdImportExecuteV1Post(workspaceId, files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiOrOrgAdmin)
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public workspacesWorkspaceIdImportTemplateV1Get(workspaceId: string, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).workspacesWorkspaceIdImportTemplateV1Get(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiOrOrgAdmin)
     * @param {string} workspaceId 
     * @param {Array<File>} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public workspacesWorkspaceIdImportVerifyV1Post(workspaceId: string, files?: Array<File>, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).workspacesWorkspaceIdImportVerifyV1Post(workspaceId, files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiOrOrgAdmin)
     * @param {string} workspaceId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public workspacesWorkspaceIdOrganizationOrganizationIdPut(workspaceId: string, organizationId: string, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).workspacesWorkspaceIdOrganizationOrganizationIdPut(workspaceId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: multiOrOrgAdmin)
     * @param {string} workspaceId 
     * @param {WorkspaceDetailUpdateRequest} [workspaceDetailUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public workspacesWorkspaceIdPut(workspaceId: string, workspaceDetailUpdateRequest?: WorkspaceDetailUpdateRequest, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).workspacesWorkspaceIdPut(workspaceId, workspaceDetailUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


